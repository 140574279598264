.gi_marca1 {
    border: 1px tomato dashed;
}

.gi_marca2 {
    border: 1px orange dotted;
}

.demo-item-product-image img {
    width: 100%;
    height: auto;
}

.uk-button {
    border-radius: 1.5em;
}

.uk-modal-footer .uk-button {
    margin-left: 10px;
    margin-right: 10px;
}

.uk-card.uk-card-default.uk-card-body.uk-border-rounded.uk-box-shadow-small,
.uk-border-rounded.uk-box-shadow-small.item-product-image-full-content.uk-sticky {
    box-shadow: none;
}

.item-list-cupon .uk-input {
    border-top-left-radius: 1.5em;
    border-bottom-left-radius: 1.5em;
}

.uk-modal-dialog {
    border-radius: 20px;
}

.uk-modal-header {
    border-radius: 20px 20px 0 0;
}

.uk-modal-footer {
    border-radius: 0 0 20px 20px;
}

.uk-switcher .gmap {
    border: 1px #D4D4D4 solid;
    border-radius: 16px 16px 0 0;
}

.gi_remove_hr {
    border-color: transparent;
    margin-bottom: 1.1em !important;
}

@media ( max-width: 768px ) {
    .btn-view {
        display: none;
    }
}

.box-user-login .uk-input,
.uk-modal-body .uk-input {
    border-radius: 1.25em;
}

.btn-cart-main i {
    position: relative;
    z-index: 0;
    font-size: 1.875em;
    margin: 0 !important;
    color: #ff671d
}

.btn-cart-main span {
    top: -.45em;
    right: -3px;
}

.btn-cart-main small {
    font-size: .82em;
}

.item-product-detail .item-product-info .item-product-title {
    margin-bottom: 2em;
    font-size: 12.0pt;
    line-height: 107%;
}

.uk-alert.uk-alert-warning {
    border-radius: 1.35em;
    background-color: rgba(255, 230, 76, 0.6);
}

/* -------   NEW Delivery button:  ---------------      */
.btn-client-store {
    border-radius: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-right: 9px;
}

.btn-whatsapp {
    color: #fff;
    padding-left: 0.95em;
    padding-right: 0.95em;
    border-radius: 2em;
    margin-right: 10px;
}

/* -------   NEW Product button:  --------------      */
.item-product-detail .item-product-add {
    border-radius: 12px 2.5em 2.5em 12px;
    background: linear-gradient(90deg, rgba(0, 178, 227, 1) 0%, rgba(0, 178, 227, 1) 29%, rgba(3, 132, 167, 1) 47%);
}

.item-product-detail .item-product-add .uk-select {
    border-radius: 0.5em;
    border: none;
    background-color: transparent;
    text-align: center;
}

.item-product-detail .item-product-add .item-product-btn .uk-button {
    border-radius: 2.5em;
    background-color: #0066b3;
}

.item-product-detail .item-product-add .item-product-btn .uk-button:hover {
    color: #000;
    background-color: #ffc844;
}

.item-product-detail .item-product-add .item-product-btn {
    border-left: none;
}

/* -------   Search input  -----------  */
.uk-search-input {
    border-radius: 2.5em;
}

.uk-search .uk-search-icon {
    background-color: transparent;
    border: 0;
    border-radius: 0 2.5em 2.5em 0;
}

/* -------   Radio buttons fix  -----------  */
.uk-modal-body label input[type="radio"] {
    margin-top: 2px;
}

.uk-radio:checked, .uk-radio:checked:focus {
    background-color: #0066b3;
}

/*  Dialogs de notificaciones   */
body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
    top: 2%;
    right: 6%;
    bottom: auto;
    left: auto;
}

/* -------   Delivery checkbox   ------- */
.switch-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 0;
    margin-bottom: 14px;
    padding-bottom: 12px;
    border-bottom: 1px dotted #C2C2C2;
}

input#switch[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.switch-box label {
    cursor: pointer;
    text-indent: -9999px;
    width: 76px;
    height: 30px;
    background: #e85913;
    display: block;
    border-radius: 100px;
    position: relative;
}

.switch-box label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 40px;
    transition: 0.3s;
}

.switch-box input:checked + label {
    background: #999;
}

.switch-box input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.switch-box label:active:after {
    width: 130px;
}

.switch-box p {
    font-size: 0.9em;
    font-weight: 700;
    margin: 0 12px;
}

/* -------   GI new styling   ------- */
.gi_explain1 {
    margin-bottom: 2em;
    color: #0066b3;
    font-weight: 700;
}

.gi_explain1 span {
    margin-bottom: 2em;
    color: #0066b3;
    font-weight: 700;
    -webkit-animation: blinkit-1 2.0s ease-out 0.5s infinite both;
    animation: blinkit-1 2.0s ease-out 0.5s infinite both;
}

.gi_explain2 {
    width: fit-content;
    margin-bottom: 2em;
    color: #0066b3;
    font-size: 1.2em;
    font-weight: 700;
    background-color: #ffe64c;
    border: none;
    border-radius: 1.0em;
    padding: 10px 20px;
    -webkit-animation: blink-bg 1.4s linear 0.5s infinite both;
    animation: blink-bg 1.4s linear 0.5s infinite both;
    animation-delay: 1.5s;
}

.gi_explain3 {
    width: fit-content;
    margin-bottom: 2em;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    background-color: #ffe64c;
    border: none;
    border-radius: 16px;
    padding: 10px 20px;
}

.gi_explain3:hover {
    -webkit-animation: blink-bg 0.5s linear infinite both;
    animation: blink-bg 0.5s linear infinite both;
}

@-webkit-keyframes blinkit-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    5%,
    55% {
        opacity: 0;
    }
}

@keyframes blinkit-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    5%,
    55% {
        opacity: 0;
    }
}

@-webkit-keyframes blink-bg {
    0%,
    50%,
    100% {
        background-color: #ffe64c;
    }
    5%,
    15% {
        background-color: #ffffff;
    }
}

@keyframes blink-bg {
    0%,
    50%,
    100% {
        background-color: #ffe64c;
    }
    5%,
    15% {
        background-color: #ffffff;
    }
}

#pickup-delivery-modal > li.uk-active .uk-list {
    margin-bottom: 2em;
}

.gi_caja_metodoentrega {
    width: fit-content;
    min-height: 80px;
    margin: 0 auto;
    padding: 18px 24px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease;
}

.gi_caja_metodoentrega:hover {
    transform: scale(1.03);
    box-shadow: 0px 3px 18px 1px rgba(0, 0, 0, 0.25);
}

.gi_icon_caja {
    width: 30%;
    text-align: center;
}

.gi_icon_caja img {
    width: 100%;
    max-width: 107px;
    height: auto;
}

.gi_icon_cajatext {
    width: 70%;
    height: auto;
    padding-left: 18px;
}

.gi_icon_cajatext h4 {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.50);
    margin-bottom: 0;
    line-height: normal;
}

.gi_icon_cajatext h5 {
    font-size: 1.4em;
    color: #0066b3;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
}

.gi_icon_cajatext h6 {
    font-size: 0.96em;
    color: #1e2426;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
}

.gi_garantia {
    text-align: center;
    padding-top: 24px;
    margin-bottom: 20px;
}

.gi_boton_garantia .uk-button-primary {
    border-radius: 2.5em;
    background-color: #000000;
}

.gi_boton_garantia .uk-button-primary:hover {
    background-color: #0066b3;
}

.gi_boton_garantia p {
    color: #1e2426;
    opacity: 0.7;
    font-weight: 600;
    font-size: 0.9em;
    margin-top: 10px;
}

.gi_caja_incluye {
    width: 100%;
    min-height: 80px;
    padding: 18px 4px;
    margin-top: 26px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease;
}

.gi_caja_incluye:hover {
    transform: scale(1.03);
    box-shadow: 0px 3px 18px 1px rgba(0, 0, 0, 0.25);
}

.gi_table_caracter {
    margin-bottom: 24px !important;
}

.gi_caja_incluye .gi_icon_caja {
    width: 20%;
    max-width: 96px;
    text-align: center; /* border:1px red dotted;  */
}

.gi_caja_incluye .gi_icon_caja img {
    width: 100%;
    max-width: 48px;
    height: auto;
}

.gi_caja_incluye .gi_icon_cajatext { /*  border:1px orange dotted;  */
    width: 80%;
    height: auto;
    padding-left: 18px;
}

.gi_caja_incluye .gi_icon_cajatext h4 {
    font-size: 1.1em;
    color: #0066b3;
    margin-bottom: 0;
    line-height: normal;
}

.gi_caja_incluye .gi_icon_cajatext p {
    font-size: 0.938em;
    line-height: normal;
    margin-top: 0.5em;
}

.gi_map_title {
    background-color: #0066b3;
    width: 100%;
    height: auto;
    min-height: 24px;
    padding: 4px 12px;
    color: #ffffff;
    font-size: 0.813em;
    font-weight: 600;
    font-style: italic;
}

.gi_pickup_txt .uk-h5 {
    margin-top: 0;
    margin-bottom: 0;
}

.gi_round_button {
    border-radius: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.gi_round_grey_button {
    border-radius: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-bottom: 4em;
    background-color: #848484;
}

.gi_button_row .gi_round_button,
.gi_button_row .gi_round_grey_button {
    font-size: .8em;
    margin-left: 9px;
    margin-right: 9px;
}

.gi_delivery_ilust {
    padding-left: 0;
}

.gi_delivery_ilust img,
.gi_delivery_ilust2 img {
    width: 100%;
    max-width: 480px;
    height: auto;
}

.gi_mb_2 {
    margin-bottom: 2em;
}

.gi_mb_3 {
    margin-bottom: 3em;
}

.gi_mb_4 {
    margin-bottom: 4em;
}

.gi_mb_5 {
    margin-bottom: 5em;
}

.gi_modal_aviso {
    width: 90%;
    max-width: 800px !important;
}

.gi-title1 {
    width: fit-content;
    margin-bottom: 1.75em;
    color: #000000;
    font-size: 1.5em;
    font-weight: 700;
    line-height: normal;
}

.gi_modal_footer {
    background-color: #ffe64c;
    border: 0;
    margin-top: 0;
    padding: .50em 2.3em;
    min-height: 18px;
}

.gi_agregado_ilust,
.gi_anun_portada_ilust,
.gi_confirmado_ilust {
    text-align: center;
}

.gi_agregado_ilust img {
    width: 100%;
    max-width: 214px;
    height: auto;
}

.gi_anun_portada_ilust img {
    width: 100%;
    max-width: 192px;
    height: auto;
}

.gi_confirmado_ilust img {
    width: 100%;
    max-width: 236px;
    height: auto;
}

@media ( max-width: 990px ) {
    .gi_agregado_ilust img {
        max-width: 180px;
    }
}

@media ( max-width: 768px ) {
    .gi_agregado_ilust img {
        max-width: 120px;
    }

    .gi_agregado_txt {
        text-align: center;
    }

    .gi_agregado_txt .gi_round_button {
        margin-bottom: 24px;
    }
}

@media ( max-width: 450px ) {
    .gi_agregado_ilust img {
        max-width: 100px;
    }
}

.gi_anun_portada p.gi-title1 {
    font-size: 2em;
    text-align: center;
    padding: 0 6%;
    margin-bottom: 1.8em !important;
}

.gi_anun_portada_txt {
    margin-bottom: 42px;
}

.gi_anun_portada_txt h3 {
    font-size: 1.75em;
    font-weight: 600;
    color: #000000;
    text-transform: none;
    margin-bottom: 8px;
}

.gi_anun_portada_txt h3 span {
    color: #0066b3;
}

.gi_anun_portada_txt h4 {
    font-size: 1.3em;
    font-weight: 400;
    color: #000000;
    text-transform: none;
    margin-top: 0;
    margin-bottom: 2em;
}

@media ( max-width: 639px ) {
    .gi_anun_portada p.gi-title1 {
        font-size: 1.7em;
    }

    .gi_anun_portada_txt h3 {
        font-size: 1.5em;
    }

    .gi_anun_portada_txt {
        text-align: center;
        margin-top: 20px;
    }
}

@media ( max-width: 420px ) {
    .gi_anun_portada p.gi-title1 {
        font-size: 1.5em;
    }

    .gi_anun_portada_txt h3 {
        font-size: 1.2em;
    }
}

.gi_ulink {
    font-weight: 600;
    color: #000000;
}

.gi_modal_footer .gi_ulink {
    font-size: 0.938em;
}

.gi_mid-align {
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.gi_demo-aviso-portada {
    text-align: center;
    margin: 0 auto 12px auto;
    -webkit-animation: blinkit-1 2.0s ease-out 0.5s infinite both;
    animation: blinkit-1 2.0s ease-out 0.5s infinite both;
}

.gi_demo-aviso-portada a {
    color: red;
}

.gi_hay_avisos_carrito {
    margin-bottom: 48px;
}

.gi_caja_aviso_carrito {
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.2em;
    font-weight: 700;
    background-color: #ffe64c;
    border: none;
    border-radius: 1.0em;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.14);
    padding: 10px 20px;
    display: inline-flex;
}

.gi_caja_aviso_carrito .gi_icon_caja {
    display: inline-block;
    width: 20%;
    max-width: 120px;
    text-align: center;
}

.gi_caja_aviso_carrito .gi_icon_caja img {
    width: 100%;
    max-width: 58px;
    height: auto;
}

.gi_caja_aviso_carrito .gi_icon_cajatext {
    display: inline-block;
    width: 79%;
    height: auto;
    padding-left: 18px;
    text-align: center;
}

.gi_caja_aviso_carrito .gi_icon_cajatext p {
    font-size: 1em;
    font-weight: 600;
    color: #000000;
    line-height: normal;
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.gi_trash_icon {
    padding-left: 0.4em !important;
    padding-right: 0.4em !important;
    padding-top: 1px !important;
    background-color: #ffffff;
    transition: 0.3s ease;
}

.gi_trash_icon:hover {
    background-color: transparent;
    color: #00b2e3;
    transform: scale(1.2);
}

.gi_item_carrito {
    border-bottom: 1px solid rgba(33, 32, 34, 0.2);
    padding-bottom: 5px;
}

.gi_item_carrito select.uk-select {
    border-radius: 1.5em;
}

.gi_item_carrito .uk-h5 {
    margin-bottom: 14px;
}

.gi_card_carrito .uk-list-large.uk-list-divider > :nth-child(n+2) {
    margin-top: 0.2em;
    padding-top: 0.6em;
    border-top: none;
}

.gi_card_carrito {
    box-shadow: none;
    padding: 15px 20px 7px 20px !important;
    margin-bottom: 1em !important;
}

.gi_item_nodisponible .item-list-image {
    filter: grayscale(100%);
    opacity: 0.5;
}

.gi_item_nodisponible .item-list-info .uk-h5 a {
    color: #A8A8A8;
}

.gi_item_nodisponible .gi_aviso_txt {
    font-size: 0.85em;
    font-weight: 600;
    color: red;
}

.gi_item_nodisponible .item-list-detail select.uk-select,
.gi_item_nodisponible .item-list-detail p.uk-text-bold {
    display: none;
}

.gi_alert_icon {
    margin: 3px 0 12px 0;
}

.gi_alert_icon img {
    width: 100%;
    max-width: 26px;
    height: auto;
}

.gi_cart_total {
    margin: 0;
    padding: 0;
    padding-top: 24px;
}

.gi_cart_artic {
    display: block;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
}

.gi_cart_artic::after {
    position: absolute;
    display: block;
    content: '';
    width: 70%;
    max-width: 220px;
    height: 1px;
    border-bottom: 1px rgba(33, 32, 34, 0.3) solid;
    margin-top: 4px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.gi_cart_titletot {
    display: block;
    width: 100%;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 0;
}

.gi_cart_tot {
    display: block;
    width: 100%;
    font-size: 1.8em;
    font-weight: 700;
    color: #0071ba;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 0;
}

.gi_cart_legal {
    padding: 0;
    margin: 24px 0 0 0;
    text-align: center;
}

.gi_pay_button,
.gi_seguir_button {
    text-align: center;
    margin: 30px 0 0 0;
    padding: 0;
}

.gi_pay_button .uk-button {
    border-radius: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 1.1em;
}

.gi_seguir_button .uk-button {
    background-color: transparent;
    font-size: 0.938em;
    font-weight: 600;
}

.gi_seguir_button .uk-button:hover {
    color: #00b2e3;
}

.gi_caja_crear_lista {
    width: 100%;
    max-width: 260px;
    height: auto;
    margin: 75px auto 20px auto;
    background: linear-gradient(0deg, rgba(103, 103, 103, 0.18) 0%, rgba(225, 225, 225, 0.14) 38%, rgba(255, 255, 255, 0.14) 100%);
    border: none;
    border-radius: 1.5em;
    padding: 5px 20px 36px 20px;
    text-align: center;
}

.gi_caja_crear_lista .gi_icon_caja {
    display: inline-block;
    width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 40px;
}

.gi_caja_crear_lista .gi_icon_caja img {
    width: 100%;
    max-width: 73px;
    height: auto;
}

.gi_caja_crear_lista .gi_icon_cajatext {
    display: inline-block;
    width: 100%;
    height: auto;
    padding-left: 18px;
    text-align: center;
}

.gi_caja_crear_lista .gi_icon_cajatext p {
    display: inline-block;
    font-size: 1.05em;
    font-weight: 400;
    color: #000000;
    line-height: normal;
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.gi_crealist_button .uk-button {
    font-size: 0.938em;
    font-weight: 600;
    border-radius: 2em;
    padding-left: 1.8em;
    padding-right: 1.8em;
    line-height: normal;
    padding: 0.8em 1.8em !important;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
}

.gi_crealist_button .uk-button:hover {
    background-color: #ffc844;
}

.gi_item_list_category {
    margin-top: 40px;
    font-size: 1.2em;
    font-weight: 600;
    color: #0066b3;
    border-top: 0 !important;
}

.gi_checkout_bloque1 .uk-h3 {
    font-size: 1.6em;
    font-weight: 600;
    color: #0066b3;
    text-transform: none;
    margin-bottom: 6px;
}

.gi_checkout_bloque2 .uk-h3,
.gi_checkout_bloque3 .uk-h3 {
    font-size: 1.5em;
    font-weight: 600;
    color: #0066b3;
    text-transform: none;
    padding-bottom: 18px;
}

.gi_checkout_bloque1 .tiene {
    font-weight: 600;
    color: #000000;
    text-transform: none;
    margin-top: 6px;
}

.gi_checkout_bloque1 .gi_hours_button {
    margin-top: 26px;
    margin-bottom: 5px;
    padding-left: 1.8em;
    padding-right: 1.8em;
    font-size: .938em;
}

.gi_checkout_bloque1 .gi_leftblock {
    display: inline-block;
    width: 65%;
}

.gi_checkout_bloque1 .gi_rightblock {
    display: inline-block;
    width: 34%;
    text-align: right;
    margin-bottom: 28px;
}

.gi_checkout_bloque1 .gi_rightblock .uk-h3 {
    font-size: 1.2em;
    font-weight: 700;;
    color: #000000;
}

@media ( max-width: 640px ) {
    .gi_checkout_bloque1 .gi_leftblock {
        width: 100%;
    }

    .gi_checkout_bloque1 .gi_rightblock {
        width: 100%;
    }
}

.uk-card.gi_checkout_bloque1,
.uk-card.gi_checkout_bloque2,
.uk-card.gi_checkout_bloque3 {
    border-radius: 18px;
    box-shadow: 0px 2px 18px 1px rgba(0, 0, 0, 0.15);
    padding: 1.7em;
}

.uk-card.gi_checkout_bloque2 .item-list,
.uk-card.gi_checkout_bloque3 .item-list {
    margin-top: 8px;
    background-color: transparent;
}

.uk-card.gi_checkout_bloque2 .item-list .item-list-detail p,
.uk-card.gi_checkout_bloque3 .item-list .item-list-detail p {
    margin-top: 8px;
    font-size: 1.4em;
}

.uk-card.gi_checkout_bloque2 a.item-list-image,
.uk-card.gi_checkout_bloque3 a.item-list-image {
    width: 5em;
    height: 5em;
    background-size: 100%;
}

.gi_bloquedelivery .gi_rightblock {
    margin-bottom: 42px;
}

.gi_bloquedelivery .gi_details_bdelivery .uk-h4 {
    text-transform: none;
    font-size: 1.1em;
    font-weight: 600;
}

.timeslot-delivery-express {
    font-size: 16px;
}

.item-list .item-list-info .uk-h5 {
    margin-bottom: 5px;
}

.item-list .item-list-info .uk-h5 a {
    font-size: .9em;
    text-transform: uppercase;
}

.gi_nosustit {
    font-size: 0.938em;
    font-style: italic;
}

.uk-card.gi_checkout_bloque2 .item-list-block,
.uk-card.gi_checkout_bloque2 .item-list-block {
    overflow: hidden;
}

.gi_icon_bdelivery {
    text-align: center;
}

.gi_icon_bdelivery img {
    width: 100%;
    max-width: 106px;
    height: auto;
}

.gi_details_bdelivery {
    text-align: left;
}

.gi_details_bdelivery .gi_direcc_button {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 13px
}

.gi_details_bdelivery .gi_addr {
    margin-top: 10px;

}

.gi_addr {
    font-size: 14px;
}

.gi_checkout_leftcol {
    /*border:1px teal dashed;*/
}

.gi_checkout_rightcol {
    /*border:1px teal dashed;*/
}

.gi_checkout_creditc .uk-input {
    border-radius: 1.5em;
    text-align: center;
}

.gi_checkout_bottom {
    text-align: right;
}

.gi_checkout_bottom .gi_round_button {
    font-size: .8em;
    background-color: #dedede;
    color: #000000;
}

.gi_checkout_bottom .gi_round_button:hover {
    background-color: #ffc844;
}

.gi_checkout_comment {
    margin-top: 20px;
    padding-left: 12px;
}

.gi_confirmado_txt .uk-text-right .gi_round_button {
    margin-bottom: 30px;
}

.gi_confirma_bloque1,
.gi_confirma_bloque2,
.gi_confirma_bloque3 {
    margin-bottom: 24px;
}

.gi_confirma_bloque1 .uk-h5,
.gi_confirma_bloque2 .uk-h5,
.gi_confirma_bloque3 .uk-h5 {
    color: #0066b3;
    margin-bottom: 4px;
}

.gi_confirma_bloque1 .uk-h6,
.gi_confirma_bloque2 .uk-h6,
.gi_confirma_bloque3 .uk-h6 {
    font-size: .88em;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 7px;
}

.gi_confirma_bloque1 .uk-link,
.gi_confirma_bloque2 .uk-link,
.gi_confirma_bloque3 .uk-link {
    font-size: 0.88em;
    font-weight: 600;
    text-decoration: underline;
    color: #5C5C5C;
    margin-bottom: 24px;
}

.no_products {
    text-align: center;
}

.gi_aplic_puntos {
    display: inline;
}

.switch {
    position: relative;
    display: inline-block;
    width: 68px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;

}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    height: 26;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}


input:checked + .slider {
    background-color: #A6E4F5;
}

input:focus + .slider {
    box-shadow: 0 0 1px #A6E4F5;
}

input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(38px);
    transform: translateX(42px);
    background-color: #0B67B2;
}


.slider.round {
    border-radius: 34px;

}

.slider.round:before {
    border-radius: 50%;
}

.toggle-legend {
    display: inline-block;
    margin-left: 15px;
    margin-top: 8px;
    font-size: 16px;
}

.gi_inputpuntos {
    text-align: center;
}

.gi_inputpuntos .gi-title1 {
    width: 100%;
    text-align: center;
}

.gi_inputpuntos .gi-title1 span {
    display: block;
    font-size: 1.5em;
    color: #0B67B2;
}

.gi_input_aplicar_ptos_preg {
    font-size: 1.2em;
    color: #0B67B2;
    text-align: center;
}

.gi_input_aplicar_ptos {
    margin-bottom: 3em;
    text-align: center;
}

.gi_input_aplicar_ptos input {
    border: none;
    outline: none;
    border-bottom: 2px #00B2E3 solid;
    font-size: 1.4em;
    max-width: 200px;
    text-align: center;
}

.gi_btn_aplicar_ptos {
    background-color: #FFE816;
    color: #000000;
    margin-bottom: 36px;
}

.gi_btn_aplicar_ptos:hover {
    background-color: #717171;
    color: #ffffff;
}

.title-ptos-disp {
    font-size: 1.2em;
    margin-top: 1.2em;
    margin-bottom: 0;
}

.ptos-disp {
    font-size: 1.2em;
    font-weight: 700;
    margin-top: 0;
    color: #0066b3;
}

/* PRODUCT DETAILS CAROUSEL START */
.productCarousel.carousel-root {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
}

.productCarousel.carousel-root .carousel {
    width: auto;
}

.productCarousel.carousel-root .carousel.carousel-slider {
    width: 100%;
}

.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
    border-radius: 10px;
}

.productCarousel.carousel-root .carousel .thumbs-wrapper {
    margin: 0;
}

.productCarousel.carousel-root .carousel .thumb {
    height: 60px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    
}

.productCarousel.carousel-root .carousel .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.productCarousel.carousel-root .carousel .thumb:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0;
}

.productCarousel.carousel-root .carousel .thumb.selected,
.productCarousel.carousel-root .carousel .thumb:hover {
    border: 0;
}

.productCarousel.carousel-root .carousel .thumb:hover:after,
.productCarousel.carousel-root .carousel .thumb.selected:after {
    opacity: 0.2;
}

.productCarousel .control-arrow {
    display: none;
}

@media screen and (max-width: 767px) {
    .productCarousel.carousel-root {
        flex-direction: column;
    }

    .productCarousel.carousel-root .carousel .thumb {
        border: 0;
        padding: 0;
    }
}

@media screen and (min-width: 768px) {
    .productCarousel.carousel-root .carousel .thumbs {
        transform: none !important;
        flex-direction: column;
        display: flex;
        gap: 10px;
    }

    .productCarousel.carousel-root .carousel .thumb {
        border: 0;
        padding: 0;
        margin: 0;
    }
}

.carousel-container {
    max-height: 500px;
}

.carousel-video-container {
    width: 70%;
}

.carousel-video-container video {
    width: 100%;
    border-radius: 10px;
}
.carousel-video-container iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.carousel-list-video-container {
    width: 25%;
    overflow: auto;
}

.carousel-list-video-container p { 
    cursor: pointer;
}
.carousel-list-video-container p:hover {
   opacity:0.6;
}

/* PRODUCT DETAILS CAROUSEL END */

.markdown ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    margin: 20px 0;
}

.markdown ul li {
    margin: 10px 0;
}

.search-history-container {
    border: 0.5px solid gray;
    box-shadow: #00B2E3;
}

.search-history-ul {
    padding-left: 0;
}

.search-history-list {
    list-style: none;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 5px;
    transition: 300ms;
}

.search-history-list:hover {
    background-color: #00b2e3;
    color: white
}

.search-history-list i {
    margin-right: 1em;
}

.search-history-list i::before {
    margin-left: 1em;
    margin-right: 0px;
}

.search-history-list .text-history::before {
    width: 10%;
}

.search-history-list .text-history {
    width: 85%;
}

.search-history-list .text-history::after {
    width: 5%;
}
