@font-face {
    font-family: 'SirenaScript';
    src: url("../fonts/sirenascript-regular.eot");
    src: local("Sirena Script Regular"), url("../fonts/sirenascript-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/sirenascript-regular.woff") format("woff"), url("../fonts/sirenascript-regular.ttf") format("truetype"), url("../fonts/sirenascript-regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap
}

p:last-child, .uk-margin:last-child { 
    margin-bottom: 0 !important
}

.uk-light .uk-tooltip {
    background-color: #0066b3 !important
}

html {
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4;
    -webkit-text-size-adjust: 100%;
    background: #fff;
    color: #fff;
    background-color: #ffe64c;
}

body {
    margin: 0
}

a:active, a:hover {
    outline: none
}

a, .uk-link {
    color: #0066b3;
    text-decoration: none;
    cursor: pointer;
    outline: none
}

a:hover, .uk-link:hover, .uk-link-toggle:hover .uk-link, .uk-link-toggle:focus .uk-link {
    color: #00b2e3;
    text-decoration: underline;
    text-decoration: none
}

abbr[title] {
    text-decoration: underline dotted;
    -webkit-text-decoration-style: dotted
}

b, strong {
    font-weight: 600
}

:not(pre) > code, :not(pre) > kbd, :not(pre) > samp {
    font-family: Consolas, monaco, monospace;
    font-size: .75em;
    color: #e1251b;
    white-space: nowrap
}

em {
    color: #212022
}

ins {
    background: #ffd;
    color: #212022;
    text-decoration: none
}

mark {
    background: #ffd;
    color: #212022
}

q {
    font-style: italic
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle
}

canvas, img, video {
    max-width: 100%;
    height: auto;
    box-sizing: border-box
}

@supports (display: block) {
    svg {
        max-width: 100%;
        height: auto;
        box-sizing: border-box
    }
}

svg:not(:root) {
    overflow: hidden
}

img:not([src]) {
    min-width: 1px;
    visibility: hidden
}

iframe {
    border: 0
}

p, ul, ol, dl, pre, address, fieldset, figure {
    margin: 0 0 1em 0
}

* + p, * + ul, * + ol, * + dl, * + pre, * + address, * + fieldset, * + figure {
    margin-top: 1em
}

h1, .uk-h1, h2, .uk-h2, h3, .uk-h3, h4, .uk-h4, h5, .uk-h5, h6, .uk-h6, .uk-heading-small, .uk-heading-medium, .uk-heading-large, .uk-heading-xlarge, .uk-heading-2xlarge {
    margin: 0 0 1em 0;
    font-family: "Montserrat", Arial, sans-serif;
    font-weight: 300;
    color: #212022;
    text-transform: none;
    font-weight: 800
}

* + h1, * + .uk-h1, * + h2, * + .uk-h2, * + h3, * + .uk-h3, * + h4, * + .uk-h4, * + h5, * + .uk-h5, * + h6, * + .uk-h6, * + .uk-heading-small, * + .uk-heading-medium, * + .uk-heading-large, * + .uk-heading-xlarge, * + .uk-heading-2xlarge {
    margin-top: 1em
}

h1, .uk-h1 {
    font-size: 1.92em;
    line-height: 1;
    text-transform: uppercase
}

h2, .uk-h2 {
    font-size: 1.44em;
    line-height: 1;
    text-transform: uppercase
}

h3, .uk-h3 {
    font-size: 1.44em;
    line-height: 1;
    text-transform: uppercase
}

h4, .uk-h4 {
    font-size: 1.25em;
    line-height: 1.2;
    text-transform: uppercase
}

h5, .uk-h5 {
    font-size: 1em;
    line-height: 1.2
}

h6, .uk-h6 {
    font-size: .75em;
    line-height: 1.2
}

@media (min-width: 960px) {
    h1, .uk-h1 {
        font-size: 2.4em
    }

    h2, .uk-h2 {
        font-size: 1.8em
    }
}

ul, ol {
    padding-left: 1.875em
}

ul > li > ul, ul > li > ol, ol > li > ol, ol > li > ul {
    margin: 0
}

dt {
    font-weight: bold
}

dd {
    margin-left: 0
}

hr, .uk-hr {
    overflow: visible;
    text-align: inherit;
    margin: 0 0 1em 0;
    border: 0;
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

* + hr, * + .uk-hr {
    margin-top: 1em
}

address {
    font-style: normal
}

blockquote {
    margin: 0 0 1em 0;
    font-size: 1em;
    line-height: 1.4;
    font-style: italic
}

* + blockquote {
    margin-top: 1em
}

blockquote p:last-of-type {
    margin-bottom: 0
}

blockquote footer {
    margin-top: .625em;
    font-size: .75em;
    line-height: 1.4
}

pre {
    font: .75em / 1.4 Consolas, monaco, monospace;
    color: #212022;
    -moz-tab-size: 4;
    tab-size: 4;
    overflow: auto
}

pre code {
    font-family: Consolas, monaco, monospace
}

::selection {
    background: #212022;
    color: #fff;
    text-shadow: none
}

details, main {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

.uk-breakpoint-s::before {
    content: "640px"
}

.uk-breakpoint-m::before {
    content: "960px"
}

.uk-breakpoint-l::before {
    content: "1200px"
}

.uk-breakpoint-xl::before {
    content: "1600px"
}

:root {
    --uk-breakpoint-s: 640px;
    --uk-breakpoint-m: 960px;
    --uk-breakpoint-l: 1200px;
    --uk-breakpoint-xl: 1600px
}

a.uk-link-muted, .uk-link-muted a {
    color: rgba(33, 32, 34, 0.6)
}

a.uk-link-muted:hover, .uk-link-muted a:hover, .uk-link-toggle:hover .uk-link-muted, .uk-link-toggle:focus .uk-link-muted {
    color: #212022
}

a.uk-link-text, .uk-link-text a {
    color: inherit;
    font-weight: 600;
    color: #00b2e3
}

a.uk-link-text:hover, .uk-link-text a:hover, .uk-link-toggle:hover .uk-link-text, .uk-link-toggle:focus .uk-link-text {
    color: #00b2e3;
    color: #0066b3
}

a.uk-link-heading, .uk-link-heading a {
    color: inherit
}

a.uk-link-heading:hover, .uk-link-heading a:hover, .uk-link-toggle:hover .uk-link-heading, .uk-link-toggle:focus .uk-link-heading {
    color: #00b2e3;
    text-decoration: none
}

a.uk-link-reset, .uk-link-reset a {
    color: inherit !important;
    text-decoration: none !important
}

.uk-link-toggle {
    color: inherit !important;
    text-decoration: none !important
}

.uk-link-toggle:focus {
    outline: none
}

.uk-heading-small {
    font-size: 2.6em;
    line-height: 1.2
}

.uk-heading-medium {
    font-size: 2.8875em;
    line-height: 1.1
}

.uk-heading-large {
    font-size: 3.4em;
    line-height: 1.1
}

.uk-heading-xlarge {
    font-size: 4em;
    line-height: 1
}

.uk-heading-2xlarge {
    font-size: 6em;
    line-height: 1
}

@media (min-width: 960px) {
    .uk-heading-small {
        font-size: 3.25em
    }

    .uk-heading-medium {
        font-size: 3.5em
    }

    .uk-heading-large {
        font-size: 4em
    }

    .uk-heading-xlarge {
        font-size: 6em
    }

    .uk-heading-2xlarge {
        font-size: 8em
    }
}

@media (min-width: 1200px) {
    .uk-heading-medium {
        font-size: 4em
    }

    .uk-heading-large {
        font-size: 6em
    }

    .uk-heading-xlarge {
        font-size: 8em
    }

    .uk-heading-2xlarge {
        font-size: 11em
    }
}

.uk-heading-divider {
    padding-bottom: .625em;
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-heading-bullet {
    position: relative
}

.uk-heading-bullet::before {
    content: "";
    display: inline-block;
    position: relative;
    top: calc(-0.1 * 1em);
    vertical-align: middle;
    height: calc(4px + 0.7em);
    margin-right: calc(5px + 0.2em);
    border-left: calc(5px + 0.1em) solid rgba(33, 32, 34, 0.2)
}

.uk-heading-line {
    overflow: hidden
}

.uk-heading-line > * {
    display: inline-block;
    position: relative
}

.uk-heading-line > ::before, .uk-heading-line > ::after {
    content: "";
    position: absolute;
    top: calc(50% - (1px / 2));
    width: 2000px;
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-heading-line > ::before {
    right: 100%;
    margin-right: calc(5px + 0.3em)
}

.uk-heading-line > ::after {
    left: 100%;
    margin-left: calc(5px + 0.3em)
}

[class*='uk-divider'] {
    border: none;
    margin-bottom: 1em
}

* + [class*='uk-divider'] {
    margin-top: 1em
}

.uk-divider-icon {
    position: relative;
    height: 1.25em;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(33,32,34,0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 50% 50%
}

.uk-divider-icon::before, .uk-divider-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    max-width: calc(50% - (3.125em / 2));
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-divider-icon::before {
    right: calc(50% + (3.125em / 2));
    width: 100%
}

.uk-divider-icon::after {
    left: calc(50% + (3.125em / 2));
    width: 100%
}

.uk-divider-small {
    line-height: 0
}

.uk-divider-small::after {
    content: "";
    display: inline-block;
    width: 6.25em;
    max-width: 100%;
    border-top: 1px solid rgba(33, 32, 34, 0.2);
    vertical-align: top
}

.uk-divider-vertical {
    width: 1px;
    height: 6.25em;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-list {
    padding: 0;
    list-style: none
}

.uk-list > * > :last-child {
    margin-bottom: 0
}

.uk-list > :nth-child(n+2), .uk-list > * > ul {
    margin-top: .625em
}

.uk-list-disc > *, .uk-list-circle > *, .uk-list-square > *, .uk-list-decimal > *, .uk-list-hyphen > * {
    padding-left: 30px
}

.uk-list-decimal {
    counter-reset: decimal
}

.uk-list-decimal > * {
    counter-increment: decimal
}

[class*='uk-list'] > ::before {
    content: '';
    position: relative;
    left: -30px;
    width: 30px;
    height: 1.4em;
    margin-bottom: -1.4em;
    display: list-item;
    list-style-position: inside;
    text-align: right
}

.uk-list-disc > ::before {
    list-style-type: disc
}

.uk-list-circle > ::before {
    list-style-type: circle
}

.uk-list-square > ::before {
    list-style-type: square
}

.uk-list-decimal > ::before {
    content: counter(decimal, decimal) " . "
}

.uk-list-hyphen > ::before {
    content: '–\00A0\00A0'
}

.uk-list-muted > ::before {
    color: rgba(33, 32, 34, 0.6) !important
}

.uk-list-emphasis > ::before {
    color: #000 !important
}

.uk-list-primary > ::before {
    color: #ffe64c !important
}

.uk-list-secondary > ::before {
    color: #00b2e3 !important
}

.uk-list-bullet > * {
    padding-left: 30px
}

.uk-list-bullet > ::before {
    content: "";
    position: relative;
    left: -30px;
    width: 30px;
    height: 1.4em;
    margin-bottom: -1.4em;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23212022%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%
}

.uk-list-divider > :nth-child(n+2) {
    margin-top: .625em;
    padding-top: .625em;
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-list-striped > * {
    padding: .625em .625em
}

.uk-list-striped > :nth-of-type(odd) {
    background: rgba(33, 32, 34, 0.1)
}

.uk-list-striped > :nth-child(n+2) {
    margin-top: 0
}

.uk-list-large > :nth-child(n+2), .uk-list-large > * > ul {
    margin-top: 1em
}

.uk-list-collapse > :nth-child(n+2), .uk-list-collapse > * > ul {
    margin-top: 0
}

.uk-list-large.uk-list-divider > :nth-child(n+2) {
    margin-top: 1em;
    padding-top: 1em
}

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
    margin-top: 0;
    padding-top: 0
}

.uk-list-large.uk-list-striped > * {
    padding: 1em .625em
}

.uk-list-collapse.uk-list-striped > * {
    padding-top: 0;
    padding-bottom: 0
}

.uk-list-large.uk-list-striped > :nth-child(n+2), .uk-list-collapse.uk-list-striped > :nth-child(n+2) {
    margin-top: 0
}

.uk-description-list > dt {
    color: #000
}

.uk-description-list > dt:nth-child(n+2) {
    margin-top: 1em
}

.uk-description-list-divider > dt:nth-child(n+2) {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 1em
}

* + .uk-table {
    margin-top: 1em
}

.uk-table th {
    padding: 1em .75em;
    text-align: left;
    vertical-align: bottom;
    font-size: .75em;
    font-weight: bold;
    color: #212022
}

.uk-table td {
    padding: 1em .75em;
    vertical-align: top
}

.uk-table td > :last-child {
    margin-bottom: 0
}

.uk-table tfoot {
    font-size: .75em
}

.uk-table caption {
    font-size: .75em;
    text-align: left;
    color: #000
}

.uk-table-middle, .uk-table-middle td {
    vertical-align: middle !important
}

.uk-table-divider > tr:not(:first-child), .uk-table-divider > :not(:first-child) > tr, .uk-table-divider > :first-child > tr:not(:first-child) {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-table-striped > tr:nth-of-type(odd), .uk-table-striped tbody tr:nth-of-type(odd) {
    background: rgba(33, 32, 34, 0.1)
}

.uk-table-hover > tr:hover, .uk-table-hover tbody tr:hover {
    background: rgba(46, 44, 47, 0.1)
}

.uk-table > tr.uk-active, .uk-table tbody tr.uk-active {
    background: rgba(46, 44, 47, 0.1)
}

.uk-table-small th, .uk-table-small td {
    padding: .625em .75em
}

.uk-table-large th, .uk-table-large td {
    padding: 1.25em .75em
}

.uk-table-justify th:first-child, .uk-table-justify td:first-child {
    padding-left: 0
}

.uk-table-justify th:last-child, .uk-table-justify td:last-child {
    padding-right: 0
}

.uk-table-shrink {
    width: 1px
}

.uk-table-expand {
    min-width: 8.75em
}

.uk-table-link {
    padding: 0 !important
}

.uk-table-link > a {
    display: block;
    padding: 1em .75em
}

.uk-table-small .uk-table-link > a {
    padding: .625em .75em
}

@media (max-width: 959px) {
    .uk-table-responsive, .uk-table-responsive tbody, .uk-table-responsive th, .uk-table-responsive td, .uk-table-responsive tr {
        display: block
    }

    .uk-table-responsive thead {
        display: none
    }

    .uk-table-responsive th, .uk-table-responsive td {
        width: auto !important;
        max-width: none !important;
        min-width: 0 !important;
        overflow: visible !important;
        white-space: normal !important
    }

    .uk-table-responsive th:not(:first-child):not(.uk-table-link), .uk-table-responsive td:not(:first-child):not(.uk-table-link), .uk-table-responsive .uk-table-link:not(:first-child) > a {
        padding-top: 0em !important
    }

    .uk-table-responsive th:not(:last-child):not(.uk-table-link), .uk-table-responsive td:not(:last-child):not(.uk-table-link), .uk-table-responsive .uk-table-link:not(:last-child) > a {
        padding-bottom: 0em !important
    }

    .uk-table-justify.uk-table-responsive th, .uk-table-justify.uk-table-responsive td {
        padding-left: 0;
        padding-right: 0
    }
}

.uk-icon {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0
}

button.uk-icon:not(:disabled) {
    cursor: pointer
}

.uk-icon::-moz-focus-inner {
    border: 0;
    padding: 0
}

.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
    fill: currentcolor
}

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
    stroke: currentcolor
}

.uk-icon > * {
    transform: translate(0, 0)
}

.uk-icon-image {
    width: 1.25em;
    height: 1.25em;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle
}

.uk-icon-link {
    color: #0066b3
}

.uk-icon-link:hover, .uk-icon-link:focus {
    color: #00b2e3;
    outline: none
}

.uk-icon-link:active, .uk-active > .uk-icon-link {
    color: #00b2e3
}

.uk-icon-button {
    box-sizing: border-box;
    width: 2.5em;
    height: 2.5em;
    border-radius: 31.25em;
    background: #fff;
    color: #212022;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center
}

.uk-icon-button:hover, .uk-icon-button:focus {
    background-color: #fff;
    color: rgba(33, 32, 34, 0.6);
    outline: none
}

.uk-icon-button:active, .uk-active > .uk-icon-button {
    background-color: #fff;
    color: #212022
}

.uk-range {
    box-sizing: border-box;
    margin: 0;
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    padding: 0
}

.uk-range:focus {
    outline: none
}

.uk-range::-moz-focus-outer {
    border: none
}

.uk-range::-ms-track {
    height: 15px;
    background: transparent;
    border-color: transparent;
    color: transparent
}

.uk-range:not(:disabled)::-webkit-slider-thumb {
    cursor: pointer
}

.uk-range:not(:disabled)::-moz-range-thumb {
    cursor: pointer
}

.uk-range:not(:disabled)::-ms-thumb {
    cursor: pointer
}

.uk-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -7px;
    height: 15px;
    width: 15px;
    border-radius: 31.25em;
    background: #212022
}

.uk-range::-moz-range-thumb {
    border: none;
    height: 15px;
    width: 15px;
    border-radius: 31.25em;
    background: #212022
}

.uk-range::-ms-thumb {
    margin-top: 0
}

.uk-range::-ms-thumb {
    border: none;
    height: 15px;
    width: 15px;
    border-radius: 31.25em;
    background: #212022
}

.uk-range::-ms-tooltip {
    display: none
}

.uk-range::-webkit-slider-runnable-track {
    height: 3px;
    background: rgba(33, 32, 34, 0.2)
}

.uk-range:focus::-webkit-slider-runnable-track, .uk-range:active::-webkit-slider-runnable-track {
    background: rgba(33, 32, 34, 0.4)
}

.uk-range::-moz-range-track {
    height: 3px;
    background: rgba(33, 32, 34, 0.2)
}

.uk-range:focus::-moz-range-track {
    background: rgba(33, 32, 34, 0.4)
}

.uk-range::-ms-fill-lower, .uk-range::-ms-fill-upper {
    height: 3px;
    background: rgba(33, 32, 34, 0.2)
}

.uk-range:focus::-ms-fill-lower, .uk-range:focus::-ms-fill-upper {
    background: rgba(33, 32, 34, 0.4)
}

.uk-input, .uk-select, .uk-textarea, .uk-radio, .uk-checkbox {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit
}

.uk-input {
    overflow: visible
}

.uk-select {
    text-transform: none
}

.uk-select optgroup {
    font: inherit;
    font-weight: bold
}

.uk-textarea {
    overflow: auto
}

.uk-input[type="search"]::-webkit-search-cancel-button, .uk-input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

.uk-input[type="number"]::-webkit-inner-spin-button, .uk-input[type="number"]::-webkit-outer-spin-button {
    height: auto
}

.uk-input::-moz-placeholder, .uk-textarea::-moz-placeholder {
    opacity: 1
}

.uk-radio:not(:disabled), .uk-checkbox:not(:disabled) {
    cursor: pointer
}

.uk-fieldset {
    border: none;
    margin: 0;
    padding: 0
}

.uk-input, .uk-textarea {
    -webkit-appearance: none
}

.uk-input, .uk-select, .uk-textarea {
    max-width: 100%;
    width: 100%;
    border: 0 none;
    padding: 0 1em;
    background: rgba(255, 255, 255, 0.9);
    color: #212022;
    padding-top: .5em;
    padding-bottom: .5em;
    border: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-input, .uk-select:not([multiple]):not([size]) {
    height: auto;
    vertical-align: middle;
    display: inline-block
}

.uk-input:not(input), .uk-select:not(select) {
    line-height: inherit
}

.uk-select[multiple], .uk-select[size], .uk-textarea {
    padding-top: 1em;
    padding-bottom: 1em;
    vertical-align: top
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.9);
    color: #212022;
    border: 1px solid rgba(33, 32, 34, 0.5)
}

.uk-input:disabled, .uk-select:disabled, .uk-textarea:disabled {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(33, 32, 34, 0.6)
}

.uk-input::-ms-input-placeholder {
    color: #212022 !important
}

.uk-input::placeholder {
    color: #212022
}

.uk-textarea::-ms-input-placeholder {
    color: #212022 !important
}

.uk-textarea::placeholder {
    color: #212022
}

.uk-form-small {
    font-size: .75em
}

.uk-form-small:not(textarea):not([multiple]):not([size]) {
    height: 2.5em;
    padding-left: .5em;
    padding-right: .5em
}

.uk-form-small:not(select):not(input):not(textarea) {
    line-height: auto
}

.uk-form-large {
    font-size: 1.25em
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
    height: 3.75em;
    padding-left: .75em;
    padding-right: .75em
}

.uk-form-large:not(select):not(input):not(textarea) {
    line-height: 3.75em
}

.uk-form-danger, .uk-form-danger:focus {
    color: #e1251b
}

.uk-form-success, .uk-form-success:focus {
    color: #00b2e3
}

.uk-form-blank {
    background: none
}

input.uk-form-width-xsmall {
    width: 50px
}

select.uk-form-width-xsmall {
    width: 75px
}

.uk-form-width-small {
    width: 6.25em
}

.uk-form-width-medium {
    width: 12.5em
}

.uk-form-width-large {
    width: 31.25em
}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 1.875em;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23212022%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23212022%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 100% 50%
}

.uk-select:not([multiple]):not([size])::-ms-expand {
    display: none
}

.uk-select:not([multiple]):not([size]) option {
    color: #212022
}

.uk-select:not([multiple]):not([size]):disabled {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(33,32,34,0.6)%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(33,32,34,0.6)%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-input[list] {
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: 100% 50%
}

.uk-input[list]:hover, .uk-input[list]:focus {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23212022%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-input[list]::-webkit-calendar-picker-indicator {
    display: none
}

.uk-radio, .uk-checkbox {
    display: inline-block;
    height: 1.25em;
    width: 1.25em;
    overflow: hidden;
    margin-top: -0.25em;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid rgba(33, 32, 34, 0.2);
    margin-right: .5em
}

.uk-radio {
    border-radius: 50%
}

.uk-radio:focus, .uk-checkbox:focus {
    outline: none;
    border: 1px solid rgba(33, 32, 34, 0.5)
}

.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {
    background-color: #212022
}

.uk-radio:checked:focus, .uk-checkbox:checked:focus, .uk-checkbox:indeterminate:focus {
    background-color: #212022
}

.uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-radio:disabled, .uk-checkbox:disabled {
    background-color: #edecee
}

.uk-radio:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23545157%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-checkbox:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23545157%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-checkbox:disabled:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23545157%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-legend {
    width: 100%;
    color: inherit;
    padding: 0;
    font-size: 1.25em;
    line-height: 1.4
}

.uk-form-custom {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle
}

.uk-form-custom select, .uk-form-custom input[type="file"] {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-appearance: none;
    opacity: 0;
    cursor: pointer
}

.uk-form-custom input[type="file"] {
    font-size: 500px;
    overflow: hidden
}

.uk-form-label {
    font-weight: 600
}

.uk-form-stacked .uk-form-label {
    display: block;
    margin-bottom: .625em
}

@media (max-width: 959px) {
    .uk-form-horizontal .uk-form-label {
        display: block;
        margin-bottom: .625em
    }
}

@media (min-width: 960px) {
    .uk-form-horizontal .uk-form-label {
        width: 12.5em;
        margin-top: .5em;
        float: left
    }

    .uk-form-horizontal .uk-form-controls {
        margin-left: 13.45em
    }

    .uk-form-horizontal .uk-form-controls-text {
        padding-top: .5em
    }
}

.uk-form-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #212022
}

.uk-form-icon:hover {
    color: rgba(33, 32, 34, 0.6)
}

.uk-form-icon:not(a):not(button):not(input) {
    pointer-events: none
}

.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
    padding-left: auto !important
}

.uk-form-icon-flip {
    right: 0;
    left: auto
}

.uk-form-icon-flip ~ .uk-input {
    padding-right: auto !important
}

.uk-input::-ms-input-placeholder {
    color: rgba(33, 32, 34, 0.5) !important
}

.uk-input::placeholder {
    color: rgba(33, 32, 34, 0.5) !important
}

.uk-textarea::-ms-input-placeholder {
    color: rgba(33, 32, 34, 0.5) !important
}

.uk-textarea::placeholder {
    color: rgba(33, 32, 34, 0.5) !important
}

.uk-button {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 1.875em;
    vertical-align: middle;
    font-size: .875em;
    line-height: inherit;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: .65em !important;
    padding-bottom: .65em !important;
    background-color: rgba(33, 32, 34, 0.1)
}

.uk-button i:first-child {
    margin-right: .5em;
    margin-left: 0 !important
}

.uk-button i:last-child {
    margin-left: .5em;
    margin-right: 0 !important
}

.uk-button:not(:disabled) {
    cursor: pointer
}

.uk-button::-moz-focus-inner {
    border: 0;
    padding: 0
}

.uk-button:hover {
    text-decoration: none;
    color: #212022;
    background-color: rgba(33, 32, 34, 0.2)
}

.uk-button:focus {
    outline: none
}

.uk-button-default {
    background-color: #ffc844;
    color: #212022
}

.uk-button-default:hover, .uk-button-default:focus {
    background-color: #00b2e3;
    color: #fff
}

.uk-button-default:active, .uk-button-default.uk-active {
    background-color: #00b2e3;
    color: #fff
}

.uk-button-primary {
    background-color: #00b2e3;
    color: #fff
}

.uk-button-primary:hover, .uk-button-primary:focus {
    background-color: #0066b3;
    color: #fff
}

.uk-button-primary:active, .uk-button-primary.uk-active {
    background-color: #0066b3;
    color: #fff
}

.uk-button-secondary {
    background-color: #0066b3;
    color: #fff
}

.uk-button-secondary:hover, .uk-button-secondary:focus {
    background-color: #00b2e3;
    color: #fff
}

.uk-button-secondary:active, .uk-button-secondary.uk-active {
    background-color: #00b2e3;
    color: #fff
}

.uk-button-danger {
    background-color: #e1251b;
    color: #fff
}

.uk-button-danger:hover, .uk-button-danger:focus {
    background-color: #ca2118;
    color: #fff
}

.uk-button-danger:active, .uk-button-danger.uk-active {
    background-color: #b31e16;
    color: #fff
}

.uk-button-default:disabled, .uk-button-primary:disabled, .uk-button-secondary:disabled, .uk-button-danger:disabled {
    background-color: rgba(33, 32, 34, 0.1);
    color: rgba(33, 32, 34, 0.6)
}

.uk-button-small {
    padding: 0 1em;
    line-height: inherit;
    font-size: .75em
}

.uk-button-large {
    padding: 0 2em;
    line-height: inherit;
    font-size: 1em
}

.uk-button-text {
    padding: 0;
    line-height: 1.4;
    background: none;
    color: #212022
}

.uk-button-text:hover, .uk-button-text:focus {
    color: #00b2e3;
    background: none !important
}

.uk-button-text:disabled {
    color: rgba(33, 32, 34, 0.6)
}

.uk-button-link {
    padding: 0;
    line-height: 1.4;
    background: none;
    color: #0066b3
}

.uk-button-link:hover {
    background: none !important
}

.uk-button-link:hover, .uk-button-link:focus {
    color: #00b2e3;
    text-decoration: underline
}

.uk-button-link:disabled {
    color: rgba(33, 32, 34, 0.6);
    text-decoration: none
}

.uk-button-group {
    display: inline-flex;
    vertical-align: middle;
    position: relative
}

.uk-section {
    display: flow-root;
    box-sizing: border-box;
    padding-top: 1.875em;
    padding-bottom: 1.875em
}

@media (min-width: 960px) {
    .uk-section {
        padding-top: 4.375em;
        padding-bottom: 4.375em
    }
}

.uk-section > :last-child {
    margin-bottom: 0
}

.uk-section-xsmall {
    padding-top: 1em;
    padding-bottom: 1em
}

.uk-section-small {
    padding-top: 1.875em;
    padding-bottom: 1.875em
}

.uk-section-large {
    padding-top: 4.375em;
    padding-bottom: 4.375em
}

@media (min-width: 960px) {
    .uk-section-large {
        padding-top: 8.75em;
        padding-bottom: 8.75em
    }
}

.uk-section-xlarge {
    padding-top: 8.75em;
    padding-bottom: 8.75em
}

@media (min-width: 960px) {
    .uk-section-xlarge {
        padding-top: 13.125em;
        padding-bottom: 13.125em
    }
}

.uk-section-default {
    background: #fff
}

.uk-section-muted {
    background: rgba(33, 32, 34, 0.1)
}

.uk-section-primary {
    background: #ffe64c
}

.uk-section-secondary {
    background: #00b2e3
}

.uk-container {
    display: flow-root;
    box-sizing: content-box;
    max-width: 75em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em
}

@media (min-width: 640px) {
    .uk-container {
        padding-left: 1.875em;
        padding-right: 1.875em
    }
}

@media (min-width: 960px) {
    .uk-container {
        padding-left: 2.5em;
        padding-right: 2.5em
    }
}

.uk-container > :last-child {
    margin-bottom: 0
}

.uk-container .uk-container {
    padding-left: 0;
    padding-right: 0
}

.uk-container-xsmall {
    max-width: 40em
}

.uk-container-small {
    max-width: 50em
}

.uk-container-large {
    max-width: 90em
}

.uk-container-xlarge {
    max-width: 1600px
}

.uk-container-expand {
    max-width: none
}

.uk-container-expand-left {
    margin-left: 0
}

.uk-container-expand-right {
    margin-right: 0
}

@media (min-width: 640px) {
    .uk-container-expand-left.uk-container-xsmall, .uk-container-expand-right.uk-container-xsmall {
        max-width: calc(50% + (40em / 2) - 1.875em)
    }

    .uk-container-expand-left.uk-container-small, .uk-container-expand-right.uk-container-small {
        max-width: calc(50% + (50em / 2) - 1.875em)
    }
}

@media (min-width: 960px) {
    .uk-container-expand-left, .uk-container-expand-right {
        max-width: calc(50% + (75em / 2) - 2.5em)
    }

    .uk-container-expand-left.uk-container-xsmall, .uk-container-expand-right.uk-container-xsmall {
        max-width: calc(50% + (40em / 2) - 2.5em)
    }

    .uk-container-expand-left.uk-container-small, .uk-container-expand-right.uk-container-small {
        max-width: calc(50% + (50em / 2) - 2.5em)
    }

    .uk-container-expand-left.uk-container-large, .uk-container-expand-right.uk-container-large {
        max-width: calc(50% + (90em / 2) - 2.5em)
    }

    .uk-container-expand-left.uk-container-xlarge, .uk-container-expand-right.uk-container-xlarge {
        max-width: calc(50% + (1600px / 2) - 2.5em)
    }
}

.uk-container-item-padding-remove-left, .uk-container-item-padding-remove-right {
    width: calc(100% + 1em)
}

.uk-container-item-padding-remove-left {
    margin-left: -1em
}

.uk-container-item-padding-remove-right {
    margin-right: -1em
}

@media (min-width: 640px) {
    .uk-container-item-padding-remove-left, .uk-container-item-padding-remove-right {
        width: calc(100% + 1.875em)
    }

    .uk-container-item-padding-remove-left {
        margin-left: -1.875em
    }

    .uk-container-item-padding-remove-right {
        margin-right: -1.875em
    }
}

@media (min-width: 960px) {
    .uk-container-item-padding-remove-left, .uk-container-item-padding-remove-right {
        width: calc(100% + 2.5em)
    }

    .uk-container-item-padding-remove-left {
        margin-left: -2.5em
    }

    .uk-container-item-padding-remove-right {
        margin-right: -2.5em
    }
}

.uk-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-grid > * {
    margin: 0
}

.uk-grid > * > :last-child {
    margin-bottom: 0
}

.uk-grid {
    margin-left: -1.875em
}

.uk-grid > * {
    padding-left: 1.875em
}

.uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
    margin-top: 1.875em
}

@media (min-width: 1200px) {
    .uk-grid {
        margin-left: -2.5em
    }

    .uk-grid > * {
        padding-left: 2.5em
    }

    .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
        margin-top: 2.5em
    }
}

.uk-grid-small, .uk-grid-column-small {
    margin-left: -1em
}

.uk-grid-small > *, .uk-grid-column-small > * {
    padding-left: 1em
}

.uk-grid + .uk-grid-small, .uk-grid + .uk-grid-row-small, .uk-grid-small > .uk-grid-margin, .uk-grid-row-small > .uk-grid-margin, * + .uk-grid-margin-small {
    margin-top: 1em
}

.uk-grid-medium, .uk-grid-column-medium {
    margin-left: -1.875em
}

.uk-grid-medium > *, .uk-grid-column-medium > * {
    padding-left: 1.875em
}

.uk-grid + .uk-grid-medium, .uk-grid + .uk-grid-row-medium, .uk-grid-medium > .uk-grid-margin, .uk-grid-row-medium > .uk-grid-margin, * + .uk-grid-margin-medium {
    margin-top: 1.875em
}

.uk-grid-large, .uk-grid-column-large {
    margin-left: -2.5em
}

.uk-grid-large > *, .uk-grid-column-large > * {
    padding-left: 2.5em
}

.uk-grid + .uk-grid-large, .uk-grid + .uk-grid-row-large, .uk-grid-large > .uk-grid-margin, .uk-grid-row-large > .uk-grid-margin, * + .uk-grid-margin-large {
    margin-top: 2.5em
}

@media (min-width: 1200px) {
    .uk-grid-large, .uk-grid-column-large {
        margin-left: -4.375em
    }

    .uk-grid-large > *, .uk-grid-column-large > * {
        padding-left: 4.375em
    }

    .uk-grid + .uk-grid-large, .uk-grid + .uk-grid-row-large, .uk-grid-large > .uk-grid-margin, .uk-grid-row-large > .uk-grid-margin, * + .uk-grid-margin-large {
        margin-top: 4.375em
    }
}

.uk-grid-collapse, .uk-grid-column-collapse {
    margin-left: 0
}

.uk-grid-collapse > *, .uk-grid-column-collapse > * {
    padding-left: 0
}

.uk-grid + .uk-grid-collapse, .uk-grid + .uk-grid-row-collapse, .uk-grid-collapse > .uk-grid-margin, .uk-grid-row-collapse > .uk-grid-margin {
    margin-top: 0
}

.uk-grid-divider > * {
    position: relative
}

.uk-grid-divider > :not(.uk-first-column)::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-grid-divider {
    margin-left: -3.75em
}

.uk-grid-divider > * {
    padding-left: 3.75em
}

.uk-grid-divider > :not(.uk-first-column)::before {
    left: 1.875em
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 3.75em
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -1.875em;
    left: 3.75em
}

@media (min-width: 1200px) {
    .uk-grid-divider {
        margin-left: -5em
    }

    .uk-grid-divider > * {
        padding-left: 5em
    }

    .uk-grid-divider > :not(.uk-first-column)::before {
        left: 2.5em
    }

    .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
        margin-top: 5em
    }

    .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
        top: -2.5em;
        left: 5em
    }
}

.uk-grid-divider.uk-grid-small, .uk-grid-divider.uk-grid-column-small {
    margin-left: -2em
}

.uk-grid-divider.uk-grid-small > *, .uk-grid-divider.uk-grid-column-small > * {
    padding-left: 2em
}

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before, .uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
    left: 1em
}

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin, .uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
    margin-top: 2em
}

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
    top: -1em;
    left: 2em
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
    top: -1em
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
    left: 2em
}

.uk-grid-divider.uk-grid-medium, .uk-grid-divider.uk-grid-column-medium {
    margin-left: -3.75em
}

.uk-grid-divider.uk-grid-medium > *, .uk-grid-divider.uk-grid-column-medium > * {
    padding-left: 3.75em
}

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before, .uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
    left: 1.875em
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin, .uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
    margin-top: 3.75em
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
    top: -1.875em;
    left: 3.75em
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
    top: -1.875em
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
    left: 3.75em
}

.uk-grid-divider.uk-grid-large, .uk-grid-divider.uk-grid-column-large {
    margin-left: -5em
}

.uk-grid-divider.uk-grid-large > *, .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 5em
}

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before, .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 2.5em
}

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin, .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 5em
}

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -2.5em;
    left: 5em
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -2.5em
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 5em
}

@media (min-width: 1200px) {
    .uk-grid-divider.uk-grid-large, .uk-grid-divider.uk-grid-column-large {
        margin-left: -8.75em
    }

    .uk-grid-divider.uk-grid-large > *, .uk-grid-divider.uk-grid-column-large > * {
        padding-left: 8.75em
    }

    .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before, .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
        left: 4.375em
    }

    .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin, .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
        margin-top: 8.75em
    }

    .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
        top: -4.375em;
        left: 8.75em
    }

    .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
        top: -4.375em
    }

    .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
        left: 8.75em
    }
}

.uk-grid-match > *, .uk-grid-item-match {
    display: flex;
    flex-wrap: wrap
}

.uk-grid-match > * > :not([class*='uk-width']), .uk-grid-item-match > :not([class*='uk-width']) {
    box-sizing: border-box;
    width: 100%;
    flex: auto
}

.uk-tile {
    display: flow-root;
    position: relative;
    box-sizing: border-box;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1.875em;
    padding-bottom: 1.875em
}

@media (min-width: 640px) {
    .uk-tile {
        padding-left: 1.875em;
        padding-right: 1.875em
    }
}

@media (min-width: 960px) {
    .uk-tile {
        padding-left: 2.5em;
        padding-right: 2.5em;
        padding-top: 4.375em;
        padding-bottom: 4.375em
    }
}

.uk-tile > :last-child {
    margin-bottom: 0
}

.uk-tile-xsmall {
    padding-top: 1em;
    padding-bottom: 1em
}

.uk-tile-small {
    padding-top: 1.875em;
    padding-bottom: 1.875em
}

.uk-tile-large {
    padding-top: 4.375em;
    padding-bottom: 4.375em
}

@media (min-width: 960px) {
    .uk-tile-large {
        padding-top: 8.75em;
        padding-bottom: 8.75em
    }
}

.uk-tile-xlarge {
    padding-top: 8.75em;
    padding-bottom: 8.75em
}

@media (min-width: 960px) {
    .uk-tile-xlarge {
        padding-top: 13.125em;
        padding-bottom: 13.125em
    }
}

.uk-tile-default {
    background: #fff
}

.uk-tile-muted {
    background: rgba(33, 32, 34, 0.1)
}

.uk-tile-primary {
    background: #ffe64c
}

.uk-tile-secondary {
    background: #00b2e3
}

.uk-card {
    position: relative;
    box-sizing: border-box
}

.uk-card table td {
    font-size: .8em
}

.uk-card table td .uk-select {
    font-size: .9em;
    min-width: 8em
}

.uk-card-body {
    display: flow-root;
    padding: 1.875em 1.875em
}

.uk-card-header {
    display: flow-root;
    padding: 1em 1.875em
}

.uk-card-footer {
    display: flow-root;
    padding: .9375em 1.875em
}

@media (min-width: 1200px) {
    .uk-card-body {
        padding: 2.5em 2.5em
    }

    .uk-card-header {
        padding: 1em 2.5em
    }

    .uk-card-footer {
        padding: 1em 2.5em
    }
}

.uk-card-body > :last-child, .uk-card-header > :last-child, .uk-card-footer > :last-child {
    margin-bottom: 0
}

.uk-card-title {
    font-size: 1.8em;
    line-height: 1.4
}

.uk-card-badge {
    position: absolute;
    top: 1.875em;
    right: 1.875em;
    z-index: 1
}

.uk-card-badge:first-child + * {
    margin-top: 0
}

.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
    background: rgba(33, 32, 34, 0.1)
}

.uk-card-default {
    background: #fff;
    color: #212022
}

.uk-card-default .uk-card-title {
    color: #212022
}

.uk-card-default.uk-card-hover:hover {
    background-color: #f2f2f2
}

.uk-card-primary {
    background: #ffe64c;
    color: #212022;
    background-color: #ffc844
}

.uk-card-primary a {
    color: #212022
}

.uk-card-primary a:hover {
    color: #0066b3
}

.uk-card-primary .uk-card-title {
    color: #212022
}

.uk-card-primary.uk-card-hover:hover {
    background-color: #ffd639
}

.uk-card-secondary {
    background: #00b2e3;
    color: #fff
}

.uk-card-secondary .uk-card-title {
    color: #fff
}

.uk-card-secondary.uk-card-hover:hover {
    background-color: #009eca
}

.uk-card-small.uk-card-body, .uk-card-small .uk-card-body {
    padding: 1em 1em
}

.uk-card-small .uk-card-header {
    padding: 1em 1em
}

.uk-card-small .uk-card-footer {
    padding: 1em 1em
}

@media (min-width: 1200px) {
    .uk-card-large.uk-card-body, .uk-card-large .uk-card-body {
        padding: 4.375em 4.375em
    }

    .uk-card-large .uk-card-header {
        padding: 2em 4.375em
    }

    .uk-card-large .uk-card-footer {
        padding: 2em 4.375em
    }
}

.uk-close {
    color: #212022
}

.uk-close:hover, .uk-close:focus {
    color: rgba(33, 32, 34, 0.6);
    outline: none
}

.uk-spinner svg circle {
    stroke: #00b2e3 !important;
    stroke-width: 2
}

.uk-spinner > * {
    animation: uk-spinner-rotate 1.4s linear infinite
}

@keyframes uk-spinner-rotate {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(270deg)
    }
}

.uk-spinner > * > * {
    stroke-dasharray: 88px;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: uk-spinner-dash 1.4s ease-in-out infinite;
    stroke-width: 1;
    stroke-linecap: round
}

@keyframes uk-spinner-dash {
    0% {
        stroke-dashoffset: 88px
    }
    50% {
        stroke-dashoffset: 22px;
        transform: rotate(135deg)
    }
    100% {
        stroke-dashoffset: 88px;
        transform: rotate(450deg)
    }
}

.uk-totop {
    padding: .3125em;
    color: #212022
}

.uk-totop:hover, .uk-totop:focus {
    color: rgba(33, 32, 34, 0.6);
    outline: none
}

.uk-totop:active {
    color: #000
}

.uk-marker {
    padding: 5px;
    background: #ffe64c;
    color: #212022
}

.uk-marker:hover, .uk-marker:focus {
    color: #212022;
    outline: none
}

.uk-alert {
    position: relative;
    margin-bottom: 1em;
    padding: 1em 2.25em 1em 1em;
    background: rgba(33, 32, 34, 0.1);
    color: #000;
    font-weight: 500;
    line-height: 1;
    padding-left: 3.25em
}

.uk-alert i {
    position: absolute;
    top: 1.15em;
    left: 1em
}

.uk-alert p {
    margin: 0
}

* + .uk-alert {
    margin-top: 1em
}

.uk-alert > :last-child {
    margin-bottom: 0
}

.uk-alert-close {
    position: absolute;
    top: 1em;
    right: 1em
}

.uk-alert-close:first-child + * {
    margin-top: 0
}

.uk-alert-primary {
    background: rgba(255, 230, 76, 0.25);
    color: #212022
}

.uk-alert-success {
    background: rgba(0, 178, 227, 0.25);
    color: #212022
}

.uk-alert-warning {
    background: rgba(255, 103, 29, 0.25);
    color: #212022
}

.uk-alert-danger {
    background: rgba(225, 37, 27, 0.25);
    color: #212022
}

.uk-badge {
    box-sizing: border-box;
    min-width: 1.875em;
    height: 1.875em;
    padding: .5em .75em;
    border-radius: 31.25em;
    vertical-align: middle;
    background: #00b2e3;
    color: #fff;
    font-size: .75em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500
}

.uk-badge:hover, .uk-badge:focus {
    color: #fff;
    text-decoration: none;
    outline: none
}

.uk-label {
    display: inline-block;
    padding: 0 .625em;
    background: #fff;
    line-height: 1.54;
    font-size: .825em;
    color: #212022;
    vertical-align: middle;
    white-space: nowrap
}

.uk-label-success {
    background-color: #00b2e3;
    color: #fff
}

.uk-label-warning {
    background-color: #ff671d;
    color: #fff
}

.uk-label-danger {
    background-color: #e1251b;
    color: #fff
}

.uk-overlay {
    padding: 1.875em 1.875em
}

.uk-overlay > :last-child {
    margin-bottom: 0
}

.uk-overlay-default {
    background: rgba(255, 255, 255, 0.8)
}

.uk-overlay-primary {
    background: rgba(33, 32, 34, 0.8)
}

.uk-article {
    display: flow-root
}

.uk-article > :last-child {
    margin-bottom: 0
}

.uk-article + .uk-article {
    margin-top: 4.375em
}

.uk-article-title {
    font-size: 2.72em;
    line-height: 1.2
}

@media (min-width: 960px) {
    .uk-article-title {
        font-size: 3.2em
    }
}

.uk-article-meta {
    font-size: .75em;
    line-height: 1.4;
    color: rgba(33, 32, 34, 0.6)
}

.uk-comment-body {
    display: flow-root;
    overflow-wrap: break-word;
    word-wrap: break-word
}

.uk-comment-header {
    display: flow-root;
    margin-bottom: 1em
}

.uk-comment-body > :last-child, .uk-comment-header > :last-child {
    margin-bottom: 0
}

.uk-comment-title {
    font-size: 1.25em;
    line-height: 1.4
}

.uk-comment-meta {
    font-size: .75em;
    line-height: 1.4;
    color: rgba(33, 32, 34, 0.6)
}

.uk-comment-list {
    padding: 0;
    list-style: none
}

.uk-comment-list > :nth-child(n+2) {
    margin-top: 4.375em
}

.uk-comment-list .uk-comment ~ ul {
    margin: 4.375em 0 0 0;
    padding-left: 1.875em;
    list-style: none
}

@media (min-width: 960px) {
    .uk-comment-list .uk-comment ~ ul {
        padding-left: 4.375em
    }
}

.uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
    margin-top: 4.375em
}

.uk-search {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin: 0
}

.uk-search-input::-webkit-search-cancel-button, .uk-search-input::-webkit-search-decoration {
    -webkit-appearance: none
}

.uk-search-input::-moz-placeholder {
    opacity: 1
}

.uk-search-input {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit;
    overflow: visible;
    -webkit-appearance: none;
    vertical-align: middle;
    width: 100%;
    border: none;
    color: #212022;
    border: 1px solid transparent
}

.uk-search-input:focus {
    outline: none
}

.uk-search-input:-ms-input-placeholder {
    color: rgba(33, 32, 34, 0.6) !important
}

.uk-search-input::placeholder {
    color: rgba(33, 32, 34, 0.6)
}

.uk-search-icon:focus {
    outline: none
}

.uk-search .uk-search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #212022
}

.uk-search .uk-search-icon:hover {
    color: #212022
}

.uk-search .uk-search-icon:not(a):not(button):not(input) {
    pointer-events: none
}

.uk-search .uk-search-icon-flip {
    right: 0;
    left: auto
}

.uk-search-default {
    width: 12.5em
}

.uk-search-default .uk-search-input {
    height: 2.5em;
    padding-left: .5em;
    padding-right: .5em;
    background: transparent
}

.uk-search-default .uk-search-input:focus {
    background-color: transparent
}

.uk-search-default .uk-search-icon {
    width: 2.5em
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
    padding-left: 2.5em
}

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
    padding-right: 2.5em
}

.uk-search-navbar {
    width: 21.875em
}

.uk-search-navbar .uk-search-input {
    height: 2.5em;
    background: transparent;
    font-size: 1.8em;
    background: rgba(255, 255, 255, 0.9);
    font-size: 1em
}

.uk-search-navbar .uk-search-icon {
    width: 2.5em
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
    padding-left: 2.5em
}

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
    padding-right: 2.5em
}

.uk-search-large {
    width: 31.25em
}

.uk-search-large .uk-search-input {
    height: 2.5em;
    background: transparent;
    font-size: 2.4em
}

.uk-search-large .uk-search-icon {
    width: 3.125em
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
    padding-left: 3.125em
}

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
    padding-right: 3.125em
}

.uk-search-toggle {
    color: #212022
}

.uk-search-toggle:hover, .uk-search-toggle:focus {
    color: rgba(33, 32, 34, 0.6)
}

.uk-nav, .uk-nav ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-nav li > a {
    display: block;
    text-decoration: none
}

.uk-nav li > a:focus {
    outline: none
}

.uk-nav > li > a {
    padding: .3125em 0
}

ul.uk-nav-sub {
    padding: .3125em 0 .3125em 1em
}

.uk-nav-sub ul {
    padding-left: 1em
}

.uk-nav-sub a {
    padding: .125em 0
}

.uk-nav-parent-icon > .uk-parent > a::after {
    content: "";
    width: 1.4em;
    height: 1.4em;
    float: right;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23212022%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%
}

.uk-nav-parent-icon > .uk-parent.uk-open > a::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23212022%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-nav-header {
    padding: .3125em 0;
    text-transform: uppercase;
    font-size: .75em
}

.uk-nav-header:not(:first-child) {
    margin-top: 1em
}

.uk-nav-divider {
    margin: .3125em 0
}

.uk-nav-default > li > a {
    color: #212022
}

.uk-nav-default > li > a:hover, .uk-nav-default > li > a:focus {
    color: #00b2e3
}

.uk-nav-default > li.uk-active > a {
    color: #000
}

.uk-nav-default .uk-nav-header {
    color: #212022
}

.uk-nav-default .uk-nav-divider {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-nav-default .uk-nav-sub a {
    color: #212022
}

.uk-nav-default .uk-nav-sub a:hover, .uk-nav-default .uk-nav-sub a:focus {
    color: #00b2e3
}

.uk-nav-default .uk-nav-sub li.uk-active > a {
    color: #000
}

.uk-nav-primary > li > a {
    font-size: 1.8em;
    line-height: 1.4;
    color: #212022
}

.uk-nav-primary > li > a:hover, .uk-nav-primary > li > a:focus {
    color: #00b2e3
}

.uk-nav-primary > li.uk-active > a {
    color: #000
}

.uk-nav-primary .uk-nav-header {
    color: #212022
}

.uk-nav-primary .uk-nav-divider {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-nav-primary .uk-nav-sub a {
    color: #212022
}

.uk-nav-primary .uk-nav-sub a:hover, .uk-nav-primary .uk-nav-sub a:focus {
    color: #00b2e3
}

.uk-nav-primary .uk-nav-sub li.uk-active > a {
    color: #000
}

.uk-nav-center {
    text-align: center
}

.uk-nav-center .uk-nav-sub, .uk-nav-center .uk-nav-sub ul {
    padding-left: 0
}

.uk-nav-center.uk-nav-parent-icon > .uk-parent > a::after {
    position: absolute
}

.uk-navbar {
    display: flex;
    position: relative
}

.uk-navbar-container:not(.uk-navbar-transparent) {
    background: #fff
}

.uk-navbar-container > ::before, .uk-navbar-container > ::after {
    display: none !important
}

.uk-navbar-left, .uk-navbar-right, .uk-navbar-center, .uk-navbar-center-left > *, .uk-navbar-center-right > * {
    display: flex;
    align-items: center
}

.uk-navbar-right {
    margin-left: auto
}

.uk-navbar-center:only-child {
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.uk-navbar-center:not(:only-child) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    box-sizing: border-box;
    z-index: 990
}

.uk-navbar-center-left, .uk-navbar-center-right {
    position: absolute;
    top: 0
}

.uk-navbar-center-left {
    right: 100%
}

.uk-navbar-center-right {
    left: 100%
}

[class*='uk-navbar-center-'] {
    width: max-content;
    box-sizing: border-box
}

.uk-navbar-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-navbar-left, .uk-navbar-right, .uk-navbar-center:only-child {
    flex-wrap: wrap
}

.uk-navbar-nav > li > a, .uk-navbar-item, .uk-navbar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 2.5em;
    padding: 0 1em;
    font-size: .875em;
    font-family: "Montserrat", Arial, sans-serif;
    text-decoration: none
}

.uk-navbar-nav > li > a {
    color: #212022
}

.uk-navbar-nav > li:hover > a, .uk-navbar-nav > li > a:focus, .uk-navbar-nav > li > a.uk-open {
    color: #00b2e3;
    outline: none
}

.uk-navbar-nav > li > a:active {
    color: #000
}

.uk-navbar-nav > li.uk-active > a {
    color: #000
}

.uk-navbar-item {
    color: #212022
}

.uk-navbar-toggle {
    color: #212022
}

.uk-navbar-toggle:hover, .uk-navbar-toggle:focus, .uk-navbar-toggle.uk-open {
    color: #00b2e3;
    outline: none;
    text-decoration: none
}

.uk-navbar-subtitle {
    font-size: .75em
}

.uk-navbar-dropdown {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    width: 12.5em;
    padding: 1em;
    background: #fff;
    color: #212022;
    background-color: white
}

.uk-navbar-dropdown.uk-open {
    display: block
}

[class*='uk-navbar-dropdown-top'] {
    margin-top: 0
}

[class*='uk-navbar-dropdown-bottom'] {
    margin-top: 0
}

[class*='uk-navbar-dropdown-left'] {
    margin-left: 0
}

[class*='uk-navbar-dropdown-right'] {
    margin-left: 0
}

.uk-navbar-dropdown-grid {
    margin-left: -1.875em
}

.uk-navbar-dropdown-grid > * {
    padding-left: 1.875em
}

.uk-navbar-dropdown-grid > .uk-grid-margin {
    margin-top: 1.875em
}

.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
    width: 100% !important
}

.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
    width: 25em
}

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
    width: 37.5em
}

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
    width: 50em
}

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
    width: 62.5em
}

.uk-navbar-dropdown-dropbar {
    margin-top: 0;
    margin-bottom: 0
}

.uk-navbar-dropdown-nav > li > a {
    color: #212022;
    font-size: .9em
}

.uk-navbar-dropdown-nav > li > a:hover, .uk-navbar-dropdown-nav > li > a:focus {
    color: #00b2e3
}

.uk-navbar-dropdown-nav > li.uk-active > a {
    color: #000
}

.uk-navbar-dropdown-nav .uk-nav-header {
    color: #000;
    font-size: 1em;
    text-transform: none;
    font-weight: 700;
    padding-bottom: .75em
}

.uk-navbar-dropdown-nav .uk-nav-divider {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-navbar-dropdown-nav .uk-nav-sub a {
    color: #212022
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover, .uk-navbar-dropdown-nav .uk-nav-sub a:focus {
    color: #00b2e3
}

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
    color: #000
}

.uk-navbar-dropbar {
    background: #fff
}

.uk-navbar-dropbar-slide {
    position: absolute;
    z-index: 980;
    left: 0;
    right: 0
}

.uk-subnav {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    padding: 0;
    list-style: none;
    margin-left: 0;
    margin-bottom: 1.5em
}

.uk-subnav > * {
    padding-left: 0 !important;
    padding-right: .5em
}

.uk-subnav > * {
    flex: none;
    padding-left: 20px;
    position: relative
}

.uk-subnav > * > :first-child {
    display: block;
    color: #212022;
    padding: .5em .75em !important
}

.uk-subnav > * > a:hover, .uk-subnav > * > a:focus {
    color: #00b2e3;
    text-decoration: none;
    outline: none
}

.uk-subnav > .uk-active > a {
    color: #000
}

.uk-subnav-divider {
    margin-left: -41px
}

.uk-subnav-divider > * {
    display: flex;
    align-items: center
}

.uk-subnav-divider > ::before {
    content: "";
    height: 1.875em;
    margin-left: 0px;
    margin-right: 20px;
    border-left: 1px solid transparent
}

.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
    border-left-color: rgba(33, 32, 34, 0.2)
}

.uk-subnav-pill > * > :first-child {
    padding: .3125em .625em;
    background: transparent;
    color: #212022
}

.uk-subnav-pill > * > a:hover, .uk-subnav-pill > * > a:focus {
    background-color: #00b2e3;
    color: #fff;
    color: #212022;
    background-color: #ffc844
}

.uk-subnav-pill > * > a:active {
    background-color: #00b2e3;
    color: #fff
}

.uk-subnav-pill > .uk-active > a {
    background-color: #00b2e3;
    color: #fff
}

.uk-subnav > .uk-disabled > a {
    color: rgba(33, 32, 34, 0.6)
}

.uk-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none
}

.uk-breadcrumb > * {
    flex: none
}

.uk-breadcrumb > * > * {
    display: inline-block;
    font-size: .75em;
    color: rgba(33, 32, 34, 0.6)
}

.uk-breadcrumb > * > :hover, .uk-breadcrumb > * > :focus {
    color: #212022;
    text-decoration: none
}

.uk-breadcrumb > :last-child > span, .uk-breadcrumb > :last-child > a:not([href]) {
    color: #212022
}

.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    content: "/";
    display: inline-block;
    margin: 0 .75em;
    font-size: .75em;
    color: rgba(33, 32, 34, 0.6)
}

.uk-pagination {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.25em;
    padding: 0;
    list-style: none
}

.uk-pagination > * {
    padding-left: .5em !important
}

.uk-pagination > * {
    flex: none;
    padding-left: 1.25em;
    position: relative
}

.uk-pagination > * > * {
    display: block;
    padding: 5px 10px;
    color: rgba(33, 32, 34, 0.6);
    width: 2em;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.4)
}

.uk-pagination > * > :hover, .uk-pagination > * > :focus {
    color: #212022;
    text-decoration: none;
    background-color: white
}

.uk-pagination > .uk-active > * {
    color: #212022;
    color: white;
    background-color: #00b2e3
}

.uk-pagination > .uk-disabled > * {
    color: rgba(33, 32, 34, 0.6)
}

.uk-tab {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.25em;
    padding: 0;
    list-style: none
}

.uk-tab > * {
    flex: none;
    padding-left: 1.25em;
    position: relative
}

.uk-tab > * > a {
    display: block;
    text-align: center;
    padding: .3125em .625em;
    color: #212022
}

.uk-tab > * > a:hover, .uk-tab > * > a:focus {
    color: #00b2e3;
    text-decoration: none
}

.uk-tab > .uk-active > a {
    color: #000
}

.uk-tab > .uk-disabled > a {
    color: rgba(33, 32, 34, 0.6)
}

.uk-tab-left, .uk-tab-right {
    flex-direction: column;
    margin-left: 0
}

.uk-tab-left > *, .uk-tab-right > * {
    padding-left: 0
}

.uk-tab-left > * > a {
    text-align: left
}

.uk-tab-right > * > a {
    text-align: left
}

.uk-slidenav {
    padding: .3125em .625em;
    color: rgba(33, 32, 34, 0.4)
}

.uk-slidenav:hover, .uk-slidenav:focus {
    color: #212022;
    outline: none
}

.uk-slidenav:active {
    color: rgba(33, 32, 34, 0.6)
}

.uk-slidenav-large {
    padding: .625em .625em
}

.uk-slidenav-container {
    display: flex
}

.uk-dotnav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -0.75em
}

.uk-dotnav > * {
    flex: none;
    padding-left: .75em
}

.uk-dotnav > * > * {
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap
}

.uk-dotnav > * > :hover, .uk-dotnav > * > :focus {
    background-color: #fff;
    outline: none
}

.uk-dotnav > * > :active {
    background-color: rgba(33, 32, 34, 0.5)
}

.uk-dotnav > .uk-active > * {
    background-color: #fff
}

.uk-dotnav-vertical {
    flex-direction: column;
    margin-left: 0;
    margin-top: -0.75em
}

.uk-dotnav-vertical > * {
    padding-left: 0;
    padding-top: .75em
}

.uk-thumbnav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -1em
}

.uk-thumbnav > * {
    padding-left: 1em
}

.uk-thumbnav > * > * {
    display: inline-block
}

.uk-thumbnav > * > :hover, .uk-thumbnav > * > :focus {
    outline: none
}

.uk-thumbnav-vertical {
    flex-direction: column;
    margin-left: 0;
    margin-top: -1em
}

.uk-thumbnav-vertical > * {
    padding-left: 0;
    padding-top: 1em
}

.uk-accordion {
    padding: 0;
    list-style: none;
    margin: 0
}

.uk-accordion > li {
    padding: 1em 0
}

.uk-accordion > li:first-child {
    padding-top: 0
}

.uk-accordion > li:last-child {
    padding-bottom: 0
}

.uk-accordion > li:not(:last-child) {
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-accordion .uk-accordion .uk-accordion-title {
    font-size: .9em
}

.uk-accordion .uk-accordion .uk-accordion-content {
    border: 0;
    padding-top: 0
}

.uk-accordion .uk-accordion .uk-open .uk-accordion-title {
    font-weight: 600
}

.uk-accordion > :nth-child(n+2) {
    margin-top: 1em;
    margin-top: 0
}

.uk-accordion-title {
    display: block;
    font-size: 1em;
    line-height: 1.4;
    color: #212022;
    position: relative;
    margin-bottom: 0;
    padding-right: 1.5em
}

.uk-accordion-title:before {
    content: '\f107';
    position: absolute;
    top: .1em;
    right: 0
}

.uk-open > .uk-accordion-title:before {
    content: '\f106'
}

.uk-accordion-title:hover, .uk-accordion-title:focus {
    color: #000;
    text-decoration: none;
    outline: none
}

.uk-accordion-content {
    display: flow-root;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-accordion-content > :last-child {
    margin-bottom: 0
}

.uk-drop {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    width: 18.75em
}

.uk-drop.uk-open {
    display: block
}

[class*='uk-drop-top'] {
    margin-top: -1em
}

[class*='uk-drop-bottom'] {
    margin-top: 1em
}

[class*='uk-drop-left'] {
    margin-left: -1em
}

[class*='uk-drop-right'] {
    margin-left: 1em
}

.uk-drop-stack .uk-drop-grid > * {
    width: 100% !important
}

.uk-dropdown {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    min-width: 8.75em;
    padding: 1em;
    background: #fff;
    color: #212022
}

.uk-dropdown.uk-open {
    display: block
}

.uk-dropdown-nav {
    white-space: nowrap
}

.uk-dropdown-nav > li > a {
    color: #212022
}

.uk-dropdown-nav > li > a:hover, .uk-dropdown-nav > li > a:focus, .uk-dropdown-nav > li.uk-active > a {
    color: #00b2e3
}

.uk-dropdown-nav .uk-nav-header {
    color: #000
}

.uk-dropdown-nav .uk-nav-divider {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-dropdown-nav .uk-nav-sub a {
    color: #212022
}

.uk-dropdown-nav .uk-nav-sub a:hover, .uk-dropdown-nav .uk-nav-sub a:focus, .uk-dropdown-nav .uk-nav-sub li.uk-active > a {
    color: #00b2e3
}

[class*='uk-dropdown-top'] {
    margin-top: -0.625em
}

[class*='uk-dropdown-bottom'] {
    margin-top: .625em
}

[class*='uk-dropdown-left'] {
    margin-left: -0.625em
}

[class*='uk-dropdown-right'] {
    margin-left: .625em
}

.uk-dropdown-stack .uk-dropdown-grid > * {
    width: 100% !important
}

.uk-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: .9375em 1.875em;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.15s linear
}

@media (min-width: 640px) {
    .uk-modal {
        padding: 3.125em 1.875em
    }
}

@media (min-width: 960px) {
    .uk-modal {
        padding-left: 2.5em;
        padding-right: 2.5em
    }
}

.uk-modal.uk-open {
    opacity: 1
}

.uk-modal-page {
    overflow: hidden
}

.uk-modal-dialog {
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;
    width: 28.125em;
    max-width: calc(100% - 0.01px) !important;
    background: #fff;
    opacity: 0;
    transform: translateY(-100px);
    transition: 0.3s linear;
    transition-property: opacity, transform
}

.uk-open > .uk-modal-dialog {
    opacity: 1;
    transform: translateY(0)
}

.uk-modal-container .uk-modal-dialog {
    width: 75rem
}

.uk-modal-full {
    padding: 0;
    background: none
}

.uk-modal-full .uk-modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%;
    transform: translateY(0)
}

.uk-modal-body {
    display: flow-root;
    padding: 1em 1.875em
}

.uk-modal-body label {
    display: flex
}

.uk-modal-body label input[type="radio"], .uk-modal-body label input[type="checkbox"] {
    margin-right: .5em
}

.uk-modal-header {
    display: flow-root;
    padding: .9375em 1.875em;
    background: #fff;
    /*border-bottom: 1px solid rgba(33, 32, 34, 0.2)*/
}

.uk-modal-header .uk-tab {
    border-bottom: 0
}

.uk-modal-header .uk-tab > * > a {
    padding: 0 1em;
    border-bottom: 0
}

.uk-modal-footer {
    display: flow-root;
    padding: .9375em 1.875em;
    background: #fff;
    border-top: 1px solid rgba(33, 32, 34, 0.2);
    font-size: .9em
}

.uk-modal-body > :last-child, .uk-modal-header > :last-child, .uk-modal-footer > :last-child {
    margin-bottom: 0
}

.uk-modal-title {
    font-size: 2.4em;
    line-height: 1.2
}

[class*='uk-modal-close-'] {
    position: absolute;
    z-index: 1010;
    top: .625em;
    right: .625em;
    padding: .3125em
}

[class*='uk-modal-close-']:first-child + * {
    margin-top: 0
}

.uk-modal-close-outside {
    top: 0;
    right: -0.3125em;
    transform: translate(0, -100%);
    color: #212022
}

.uk-modal-close-outside:hover {
    color: rgba(33, 32, 34, 0.6)
}

@media (min-width: 960px) {
    .uk-modal-close-outside {
        right: 0;
        transform: translate(100%, -100%)
    }
}

.uk-lightbox {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.15s linear;
    touch-action: pinch-zoom
}

.uk-lightbox.uk-open {
    display: block;
    opacity: 1
}

.uk-lightbox-page {
    overflow: hidden
}

.uk-lightbox-items > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    will-change: transform, opacity
}

.uk-lightbox-items > * > * {
    max-width: 100vw;
    max-height: 100vh
}

.uk-lightbox-items > :focus {
    outline: none
}

.uk-lightbox-items > * > :not(iframe) {
    width: auto;
    height: auto
}

.uk-lightbox-items > .uk-active {
    display: flex
}

.uk-lightbox-toolbar {
    padding: .625em .625em;
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.7)
}

.uk-lightbox-toolbar > * {
    color: rgba(255, 255, 255, 0.7)
}

.uk-lightbox-toolbar-icon {
    padding: .3125em;
    color: rgba(255, 255, 255, 0.7)
}

.uk-lightbox-toolbar-icon:hover {
    color: #fff
}

.uk-lightbox-button {
    box-sizing: border-box;
    width: 3.125em;
    height: 3.125em;
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.7);
    display: inline-flex;
    justify-content: center;
    align-items: center
}

.uk-lightbox-button:hover, .uk-lightbox-button:focus {
    color: #fff
}

.uk-lightbox-caption:empty {
    display: none
}

.uk-lightbox-iframe {
    width: 80%;
    height: 80%
}

.uk-slideshow {
    -webkit-tap-highlight-color: transparent
}

.uk-slideshow-items {
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    -webkit-touch-callout: none
}

.uk-slideshow-items > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    will-change: transform, opacity;
    touch-action: pan-y
}

.uk-slideshow-items > :focus {
    outline: none
}

.uk-slideshow-items > :not(.uk-active) {
    display: none
}

.uk-slider {
    -webkit-tap-highlight-color: transparent
}

.uk-slider-container {
    overflow: hidden
}

.uk-slider-items {
    will-change: transform;
    position: relative
}

.uk-slider-items:not(.uk-grid) {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-touch-callout: none
}

.uk-slider-items.uk-grid {
    flex-wrap: nowrap
}

.uk-slider-items > * {
    flex: none;
    position: relative;
    touch-action: pan-y
}

.uk-slider-items > :focus {
    outline: none
}

.uk-sticky-fixed {
    z-index: 980;
    box-sizing: border-box;
    margin: 0 !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.uk-sticky[class*='uk-animation-'] {
    animation-duration: .2s
}

.uk-sticky.uk-animation-reverse {
    animation-duration: .2s
}

.uk-offcanvas {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000
}

.uk-offcanvas-flip .uk-offcanvas {
    right: 0;
    left: auto
}

.uk-offcanvas-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -16.875em;
    box-sizing: border-box;
    width: 16.875em;
    padding: 1em 1em;
    background: #ffe64c;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 !important
}

@media (min-width: 960px) {
    .uk-offcanvas-bar {
        left: -21.875em;
        width: 21.875em;
        padding: 2.5em 2.5em
    }
}

.uk-offcanvas-flip .uk-offcanvas-bar {
    left: auto;
    right: -16.875em
}

@media (min-width: 960px) {
    .uk-offcanvas-flip .uk-offcanvas-bar {
        right: -21.875em
    }
}

.uk-open > .uk-offcanvas-bar {
    left: 0
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
    left: auto;
    right: 0
}

.uk-offcanvas-bar-animation {
    transition: left 0.3s ease-out
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
    transition-property: right
}

.uk-offcanvas-reveal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease-out
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
    left: 0
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
    left: auto;
    right: 0
}

.uk-open > .uk-offcanvas-reveal {
    width: 16.875em
}

@media (min-width: 960px) {
    .uk-open > .uk-offcanvas-reveal {
        width: 21.875em
    }
}

.uk-offcanvas-flip .uk-offcanvas-reveal {
    right: 0;
    left: auto
}

.uk-offcanvas-close {
    position: absolute;
    z-index: 1000;
    top: 1.25em;
    right: 1.25em;
    padding: .3125em
}

.uk-offcanvas-overlay {
    width: 100vw;
    touch-action: none
}

.uk-offcanvas-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.15s linear
}

.uk-offcanvas-overlay.uk-open::before {
    opacity: 1
}

.uk-offcanvas-page, .uk-offcanvas-container {
    overflow-x: hidden
}

.uk-offcanvas-container {
    position: relative;
    left: 0;
    transition: left 0.3s ease-out;
    box-sizing: border-box;
    width: 100%
}

:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 16.875em
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -16.875em
}

@media (min-width: 960px) {
    :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
        left: 21.875em
    }

    .uk-offcanvas-flip.uk-offcanvas-container-animation {
        left: -21.875em
    }
}

.uk-switcher {
    margin: 0;
    padding: 0;
    list-style: none
}

.uk-switcher > :not(.uk-active) {
    display: none
}

.uk-switcher > * > :last-child {
    margin-bottom: 0
}

.uk-leader {
    overflow: hidden
}

.uk-leader-fill::after {
    display: inline-block;
    margin-left: 1em;
    width: 0;
    content: attr(data-fill);
    white-space: nowrap
}

.uk-leader-fill.uk-leader-hide::after {
    display: none
}

.uk-leader-fill-content::before {
    content: "."
}

:root {
    --uk-leader-fill-content: .
}

.uk-iconnav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -0.625em
}

.uk-iconnav > * {
    padding-left: .625em
}

.uk-iconnav > * > a {
    display: block;
    color: #212022
}

.uk-iconnav > * > a:hover, .uk-iconnav > * > a:focus {
    color: rgba(33, 32, 34, 0.6);
    outline: none
}

.uk-iconnav > .uk-active > a {
    color: #212022
}

.uk-iconnav-vertical {
    flex-direction: column;
    margin-left: 0;
    margin-top: -0.625em
}

.uk-iconnav-vertical > * {
    padding-left: 0;
    padding-top: .625em
}

.uk-notification {
    position: fixed;
    top: .625em;
    left: .625em;
    z-index: 1040;
    box-sizing: border-box;
    width: 21.875em
}

.uk-notification-top-right, .uk-notification-bottom-right {
    left: auto;
    right: .625em
}

.uk-notification-top-center, .uk-notification-bottom-center {
    left: 50%;
    margin-left: -10.9375em
}

.uk-notification-bottom-left, .uk-notification-bottom-right, .uk-notification-bottom-center {
    top: auto;
    bottom: .625em
}

@media (max-width: 639px) {
    .uk-notification {
        left: .625em;
        right: .625em;
        width: auto;
        margin: 0
    }
}

.uk-notification-message {
    position: relative;
    padding: 1em;
    background: #fff;
    color: #212022;
    font-size: 1em;
    line-height: 1.4;
    cursor: pointer
}

* + .uk-notification-message {
    margin-top: .625em
}

.uk-notification-close {
    display: none;
    position: absolute;
    top: 1.3125em;
    right: 1em
}

.uk-notification-message:hover .uk-notification-close {
    display: block
}

.uk-notification-message-primary {
    color: #ffe64c
}

.uk-notification-message-success {
    color: #00b2e3
}

.uk-notification-message-warning {
    color: #ff671d
}

.uk-notification-message-danger {
    color: #e1251b
}

.uk-tooltip {
    display: none;
    position: absolute;
    z-index: 1030;
    top: 0;
    box-sizing: border-box;
    max-width: 12.5em;
    padding: .5em 1em;
    background: #00b2e3;
    border-radius: .125em;
    color: #fff;
    font-size: .75em;
    font-weight: 500
}

.uk-tooltip p {
    margin: .5em 0
}

.uk-tooltip .uk-list {
    font-size: .95em;
    margin: .5em 0
}

.uk-tooltip .uk-list li {
    padding-left: 1.25em
}

.uk-tooltip.uk-active {
    display: block
}

[class*='uk-tooltip-top'] {
    margin-top: -0.625em
}

[class*='uk-tooltip-bottom'] {
    margin-top: .625em
}

[class*='uk-tooltip-left'] {
    margin-left: -0.625em
}

[class*='uk-tooltip-right'] {
    margin-left: .625em
}

.uk-placeholder {
    margin-bottom: 1em;
    padding: 1.875em 1.875em;
    background: transparent
}

* + .uk-placeholder {
    margin-top: 1em
}

.uk-placeholder > :last-child {
    margin-bottom: 0
}

.uk-progress {
    vertical-align: baseline;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 100%;
    border: 0;
    background-color: rgba(33, 32, 34, 0.1);
    margin-bottom: 1em;
    height: 1.875em
}

* + .uk-progress {
    margin-top: 1em
}

.uk-progress:indeterminate {
    color: transparent
}

.uk-progress::-webkit-progress-bar {
    background-color: rgba(33, 32, 34, 0.1)
}

.uk-progress:indeterminate::-moz-progress-bar {
    width: 0
}

.uk-progress::-webkit-progress-value {
    background-color: #00b2e3;
    transition: width 0.6s ease
}

.uk-progress::-moz-progress-bar {
    background-color: #00b2e3
}

.uk-progress::-ms-fill {
    background-color: #00b2e3;
    transition: width 0.6s ease;
    border: 0
}

.uk-sortable {
    position: relative
}

.uk-sortable > :last-child {
    margin-bottom: 0
}

.uk-sortable-drag {
    position: fixed !important;
    z-index: 1050 !important;
    pointer-events: none
}

.uk-sortable-placeholder {
    opacity: 0;
    pointer-events: none
}

.uk-sortable-empty {
    min-height: 3.125em
}

.uk-sortable-handle:hover {
    cursor: move
}

.uk-countdown-number {
    font-variant-numeric: tabular-nums;
    font-size: 2em;
    line-height: .8
}

@media (min-width: 640px) {
    .uk-countdown-number {
        font-size: 4em
    }
}

@media (min-width: 960px) {
    .uk-countdown-number {
        font-size: 6em
    }
}

.uk-countdown-separator {
    font-size: 1.5em;
    line-height: 1.6
}

@media (min-width: 640px) {
    .uk-countdown-separator {
        font-size: 3em
    }
}

@media (min-width: 960px) {
    .uk-countdown-separator {
        font-size: 4.5em
    }
}

[class*='uk-animation-'] {
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both
}

.uk-animation-fade {
    animation-name: uk-fade;
    animation-duration: .8s;
    animation-timing-function: linear
}

.uk-animation-scale-up {
    animation-name: uk-fade-scale-02
}

.uk-animation-scale-down {
    animation-name: uk-fade-scale-18
}

.uk-animation-slide-top {
    animation-name: uk-fade-top
}

.uk-animation-slide-bottom {
    animation-name: uk-fade-bottom
}

.uk-animation-slide-left {
    animation-name: uk-fade-left
}

.uk-animation-slide-right {
    animation-name: uk-fade-right
}

.uk-animation-slide-top-small {
    animation-name: uk-fade-top-small
}

.uk-animation-slide-bottom-small {
    animation-name: uk-fade-bottom-small
}

.uk-animation-slide-left-small {
    animation-name: uk-fade-left-small
}

.uk-animation-slide-right-small {
    animation-name: uk-fade-right-small
}

.uk-animation-slide-top-medium {
    animation-name: uk-fade-top-medium
}

.uk-animation-slide-bottom-medium {
    animation-name: uk-fade-bottom-medium
}

.uk-animation-slide-left-medium {
    animation-name: uk-fade-left-medium
}

.uk-animation-slide-right-medium {
    animation-name: uk-fade-right-medium
}

.uk-animation-kenburns {
    animation-name: uk-scale-kenburns;
    animation-duration: 15s
}

.uk-animation-shake {
    animation-name: uk-shake
}

.uk-animation-stroke {
    animation-name: uk-stroke;
    stroke-dasharray: var(--uk-animation-stroke);
    animation-duration: 2s
}

.uk-animation-reverse {
    animation-direction: reverse;
    animation-timing-function: ease-in
}

.uk-animation-fast {
    animation-duration: .1s
}

.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
    animation-name: none
}

.uk-animation-toggle {
    -webkit-tap-highlight-color: transparent
}

.uk-animation-toggle:focus {
    outline: none
}

@keyframes uk-fade {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes uk-fade-top {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes uk-fade-bottom {
    0% {
        opacity: 0;
        transform: translateY(100%)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes uk-fade-left {
    0% {
        opacity: 0;
        transform: translateX(-100%)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes uk-fade-right {
    0% {
        opacity: 0;
        transform: translateX(100%)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes uk-fade-top-small {
    0% {
        opacity: 0;
        transform: translateY(-0.625em)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes uk-fade-bottom-small {
    0% {
        opacity: 0;
        transform: translateY(.625em)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes uk-fade-left-small {
    0% {
        opacity: 0;
        transform: translateX(-0.625em)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes uk-fade-right-small {
    0% {
        opacity: 0;
        transform: translateX(.625em)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes uk-fade-top-medium {
    0% {
        opacity: 0;
        transform: translateY(-3.125em)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes uk-fade-bottom-medium {
    0% {
        opacity: 0;
        transform: translateY(3.125em)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes uk-fade-left-medium {
    0% {
        opacity: 0;
        transform: translateX(-3.125em)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes uk-fade-right-medium {
    0% {
        opacity: 0;
        transform: translateX(3.125em)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes uk-fade-scale-02 {
    0% {
        opacity: 0;
        transform: scale(0.2)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes uk-fade-scale-18 {
    0% {
        opacity: 0;
        transform: scale(1.8)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes uk-scale-kenburns {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(1.2)
    }
}

@keyframes uk-shake {
    0%, 100% {
        transform: translateX(0)
    }
    10% {
        transform: translateX(-9px)
    }
    20% {
        transform: translateX(8px)
    }
    30% {
        transform: translateX(-7px)
    }
    40% {
        transform: translateX(6px)
    }
    50% {
        transform: translateX(-5px)
    }
    60% {
        transform: translateX(4px)
    }
    70% {
        transform: translateX(-3px)
    }
    80% {
        transform: translateX(2px)
    }
    90% {
        transform: translateX(-1px)
    }
}

@keyframes uk-stroke {
    0% {
        stroke-dashoffset: var(--uk-animation-stroke)
    }
    100% {
        stroke-dashoffset: 0
    }
}

[class*='uk-child-width'] > * {
    box-sizing: border-box;
    width: 100%
}

.uk-child-width-1-2 > * {
    width: 50%
}

.uk-child-width-1-3 > * {
    width: calc(100% * 1 / 3.001)
}

.uk-child-width-1-4 > * {
    width: 25%
}

.uk-child-width-1-5 > * {
    width: 20%
}

.uk-child-width-1-6 > * {
    width: calc(100% * 1 / 6.001)
}

.uk-child-width-auto > * {
    width: auto
}

.uk-child-width-expand > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px
}

@media (min-width: 640px) {
    .uk-child-width-1-1\@s > * {
        width: 100%
    }

    .uk-child-width-1-2\@s > * {
        width: 50%
    }

    .uk-child-width-1-3\@s > * {
        width: calc(100% * 1 / 3.001)
    }

    .uk-child-width-1-4\@s > * {
        width: 25%
    }

    .uk-child-width-1-5\@s > * {
        width: 20%
    }

    .uk-child-width-1-6\@s > * {
        width: calc(100% * 1 / 6.001)
    }

    .uk-child-width-auto\@s > * {
        width: auto
    }

    .uk-child-width-expand\@s > :not([class*='uk-width']) {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width: 960px) {
    .uk-child-width-1-1\@m > * {
        width: 100%
    }

    .uk-child-width-1-2\@m > * {
        width: 50%
    }

    .uk-child-width-1-3\@m > * {
        width: calc(100% * 1 / 3.001)
    }

    .uk-child-width-1-4\@m > * {
        width: 25%
    }

    .uk-child-width-1-5\@m > * {
        width: 20%
    }

    .uk-child-width-1-6\@m > * {
        width: calc(100% * 1 / 6.001)
    }

    .uk-child-width-auto\@m > * {
        width: auto
    }

    .uk-child-width-expand\@m > :not([class*='uk-width']) {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width: 1200px) {
    .uk-child-width-1-1\@l > * {
        width: 100%
    }

    .uk-child-width-1-2\@l > * {
        width: 50%
    }

    .uk-child-width-1-3\@l > * {
        width: calc(100% * 1 / 3.001)
    }

    .uk-child-width-1-4\@l > * {
        width: 25%
    }

    .uk-child-width-1-5\@l > * {
        width: 20%
    }

    .uk-child-width-1-6\@l > * {
        width: calc(100% * 1 / 6.001)
    }

    .uk-child-width-auto\@l > * {
        width: auto
    }

    .uk-child-width-expand\@l > :not([class*='uk-width']) {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width: 1600px) {
    .uk-child-width-1-1\@xl > * {
        width: 100%
    }

    .uk-child-width-1-2\@xl > * {
        width: 50%
    }

    .uk-child-width-1-3\@xl > * {
        width: calc(100% * 1 / 3.001)
    }

    .uk-child-width-1-4\@xl > * {
        width: 25%
    }

    .uk-child-width-1-5\@xl > * {
        width: 20%
    }

    .uk-child-width-1-6\@xl > * {
        width: calc(100% * 1 / 6.001)
    }

    .uk-child-width-auto\@xl > * {
        width: auto
    }

    .uk-child-width-expand\@xl > :not([class*='uk-width']) {
        flex: 1;
        min-width: 1px
    }
}

[class*='uk-width'] {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%
}

.uk-width-1-2 {
    width: 50%
}

.uk-width-1-3 {
    width: calc(100% * 1 / 3.001)
}

.uk-width-2-3 {
    width: calc(100% * 2 / 3.001)
}

.uk-width-1-4 {
    width: 25%
}

.uk-width-3-4 {
    width: 75%
}

.uk-width-1-5 {
    width: 20%
}

.uk-width-2-5 {
    width: 40%
}

.uk-width-3-5 {
    width: 60%
}

.uk-width-4-5 {
    width: 80%
}

.uk-width-1-6 {
    width: calc(100% * 1 / 6.001)
}

.uk-width-5-6 {
    width: calc(100% * 5 / 6.001)
}

.uk-width-small {
    width: 9.375em
}

.uk-width-medium {
    width: 18.75em
}

.uk-width-large {
    width: 28.125em
}

.uk-width-xlarge {
    width: 37.5em
}

.uk-width-2xlarge {
    width: 750px
}

.uk-width-auto {
    width: auto
}

.uk-width-expand {
    flex: 1;
    min-width: 1px
}

@media (min-width: 640px) {
    .uk-width-1-1\@s {
        width: 100%
    }

    .uk-width-1-2\@s {
        width: 50%
    }

    .uk-width-1-3\@s {
        width: calc(100% * 1 / 3.001)
    }

    .uk-width-2-3\@s {
        width: calc(100% * 2 / 3.001)
    }

    .uk-width-1-4\@s {
        width: 25%
    }

    .uk-width-3-4\@s {
        width: 75%
    }

    .uk-width-1-5\@s {
        width: 20%
    }

    .uk-width-2-5\@s {
        width: 40%
    }

    .uk-width-3-5\@s {
        width: 60%
    }

    .uk-width-4-5\@s {
        width: 80%
    }

    .uk-width-1-6\@s {
        width: calc(100% * 1 / 6.001)
    }

    .uk-width-5-6\@s {
        width: calc(100% * 5 / 6.001)
    }

    .uk-width-small\@s {
        width: 9.375em
    }

    .uk-width-medium\@s {
        width: 18.75em
    }

    .uk-width-large\@s {
        width: 28.125em
    }

    .uk-width-xlarge\@s {
        width: 37.5em
    }

    .uk-width-2xlarge\@s {
        width: 750px
    }

    .uk-width-auto\@s {
        width: auto
    }

    .uk-width-expand\@s {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width: 960px) {
    .uk-width-1-1\@m {
        width: 100%
    }

    .uk-width-1-2\@m {
        width: 50%
    }

    .uk-width-1-3\@m {
        width: calc(100% * 1 / 3.001)
    }

    .uk-width-2-3\@m {
        width: calc(100% * 2 / 3.001)
    }

    .uk-width-1-4\@m {
        width: 25%
    }

    .uk-width-3-4\@m {
        width: 75%
    }

    .uk-width-1-5\@m {
        width: 20%
    }

    .uk-width-2-5\@m {
        width: 40%
    }

    .uk-width-3-5\@m {
        width: 60%
    }

    .uk-width-4-5\@m {
        width: 80%
    }

    .uk-width-1-6\@m {
        width: calc(100% * 1 / 6.001)
    }

    .uk-width-5-6\@m {
        width: calc(100% * 5 / 6.001)
    }

    .uk-width-small\@m {
        width: 9.375em
    }

    .uk-width-medium\@m {
        width: 18.75em
    }

    .uk-width-large\@m {
        width: 28.125em
    }

    .uk-width-xlarge\@m {
        width: 37.5em
    }

    .uk-width-2xlarge\@m {
        width: 750px
    }

    .uk-width-auto\@m {
        width: auto
    }

    .uk-width-expand\@m {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width: 1200px) {
    .uk-width-1-1\@l {
        width: 100%
    }

    .uk-width-1-2\@l {
        width: 50%
    }

    .uk-width-1-3\@l {
        width: calc(100% * 1 / 3.001)
    }

    .uk-width-2-3\@l {
        width: calc(100% * 2 / 3.001)
    }

    .uk-width-1-4\@l {
        width: 25%
    }

    .uk-width-3-4\@l {
        width: 75%
    }

    .uk-width-1-5\@l {
        width: 20%
    }

    .uk-width-2-5\@l {
        width: 40%
    }

    .uk-width-3-5\@l {
        width: 60%
    }

    .uk-width-4-5\@l {
        width: 80%
    }

    .uk-width-1-6\@l {
        width: calc(100% * 1 / 6.001)
    }

    .uk-width-5-6\@l {
        width: calc(100% * 5 / 6.001)
    }

    .uk-width-small\@l {
        width: 9.375em
    }

    .uk-width-medium\@l {
        width: 18.75em
    }

    .uk-width-large\@l {
        width: 28.125em
    }

    .uk-width-xlarge\@l {
        width: 37.5em
    }

    .uk-width-2xlarge\@l {
        width: 750px
    }

    .uk-width-auto\@l {
        width: auto
    }

    .uk-width-expand\@l {
        flex: 1;
        min-width: 1px
    }
}

@media (min-width: 1600px) {
    .uk-width-1-1\@xl {
        width: 100%
    }

    .uk-width-1-2\@xl {
        width: 50%
    }

    .uk-width-1-3\@xl {
        width: calc(100% * 1 / 3.001)
    }

    .uk-width-2-3\@xl {
        width: calc(100% * 2 / 3.001)
    }

    .uk-width-1-4\@xl {
        width: 25%
    }

    .uk-width-3-4\@xl {
        width: 75%
    }

    .uk-width-1-5\@xl {
        width: 20%
    }

    .uk-width-2-5\@xl {
        width: 40%
    }

    .uk-width-3-5\@xl {
        width: 60%
    }

    .uk-width-4-5\@xl {
        width: 80%
    }

    .uk-width-1-6\@xl {
        width: calc(100% * 1 / 6.001)
    }

    .uk-width-5-6\@xl {
        width: calc(100% * 5 / 6.001)
    }

    .uk-width-small\@xl {
        width: 9.375em
    }

    .uk-width-medium\@xl {
        width: 18.75em
    }

    .uk-width-large\@xl {
        width: 28.125em
    }

    .uk-width-xlarge\@xl {
        width: 37.5em
    }

    .uk-width-2xlarge\@xl {
        width: 750px
    }

    .uk-width-auto\@xl {
        width: auto
    }

    .uk-width-expand\@xl {
        flex: 1;
        min-width: 1px
    }
}

[class*='uk-height'] {
    box-sizing: border-box
}

.uk-height-1-1 {
    height: 100%
}

.uk-height-viewport {
    min-height: 100vh
}

.uk-height-small {
    height: 12.5em
}

.uk-height-medium {
    height: 18.75em
}

.uk-height-large {
    height: 28.125em
}

.uk-height-max-small {
    max-height: 12.5em
}

.uk-height-max-medium {
    max-height: 18.75em
}

.uk-height-max-large {
    max-height: 28.125em
}

.uk-text-lead {
    font-size: 1.25em;
    line-height: 1.4;
    color: #000
}

.uk-text-meta {
    font-size: .75em;
    line-height: 1.4;
    color: rgba(33, 32, 34, 0.6)
}

.uk-text-small {
    font-size: .75em;
    line-height: 1.4
}

.uk-text-large {
    font-size: 1.15em;
    line-height: 1.4
}

.uk-text-default {
    font-size: 1em;
    line-height: 1.4
}

.uk-text-light {
    font-weight: 300
}

.uk-text-normal {
    font-weight: 400
}

.uk-text-bold {
    font-weight: 700
}

.uk-text-lighter {
    font-weight: lighter
}

.uk-text-bolder {
    font-weight: bolder
}

.uk-text-italic {
    font-style: italic
}

.uk-text-capitalize {
    text-transform: capitalize !important
}

.uk-text-uppercase {
    text-transform: uppercase !important
}

.uk-text-lowercase {
    text-transform: lowercase !important
}

.uk-text-muted {
    color: rgba(33, 32, 34, 0.6) !important
}

.uk-text-emphasis {
    color: #000 !important
}

.uk-text-primary {
    color: #ffe64c !important
}

.uk-text-secondary {
    color: #00b2e3 !important
}

.uk-text-success {
    color: #00b2e3 !important
}

.uk-text-warning {
    color: #ff671d !important
}

.uk-text-danger {
    color: #e1251b !important
}

.uk-text-background {
    -webkit-background-clip: text;
    display: inline-block;
    color: #fff !important
}

@supports (-webkit-background-clip: text) {
    .uk-text-background {
        background-color: #fff;
        color: transparent !important
    }
}

.uk-text-left {
    text-align: left !important
}

.uk-text-right {
    text-align: right !important
}

.uk-text-center {
    text-align: center !important
}

.uk-text-justify {
    text-align: justify !important
}

@media (min-width: 640px) {
    .uk-text-left\@s {
        text-align: left !important
    }

    .uk-text-right\@s {
        text-align: right !important
    }

    .uk-text-center\@s {
        text-align: center !important
    }
}

@media (min-width: 960px) {
    .uk-text-left\@m {
        text-align: left !important
    }

    .uk-text-right\@m {
        text-align: right !important
    }

    .uk-text-center\@m {
        text-align: center !important
    }
}

@media (min-width: 1200px) {
    .uk-text-left\@l {
        text-align: left !important
    }

    .uk-text-right\@l {
        text-align: right !important
    }

    .uk-text-center\@l {
        text-align: center !important
    }
}

@media (min-width: 1600px) {
    .uk-text-left\@xl {
        text-align: left !important
    }

    .uk-text-right\@xl {
        text-align: right !important
    }

    .uk-text-center\@xl {
        text-align: center !important
    }
}

.uk-text-top {
    vertical-align: top !important
}

.uk-text-middle {
    vertical-align: middle !important
}

.uk-text-bottom {
    vertical-align: bottom !important
}

.uk-text-baseline {
    vertical-align: baseline !important
}

.uk-text-nowrap {
    white-space: nowrap
}

.uk-text-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

th.uk-text-truncate, td.uk-text-truncate {
    max-width: 0
}

.uk-text-break {
    overflow-wrap: break-word;
    word-wrap: break-word
}

th.uk-text-break, td.uk-text-break {
    word-break: break-all
}

[class*='uk-column-'] {
    column-gap: 1.875em
}

@media (min-width: 1200px) {
    [class*='uk-column-'] {
        column-gap: 2.5em
    }
}

[class*='uk-column-'] img {
    transform: translate3d(0, 0, 0)
}

.uk-column-divider {
    column-rule: 1px solid rgba(33, 32, 34, 0.6);
    column-gap: 3.75em
}

@media (min-width: 1200px) {
    .uk-column-divider {
        column-gap: 5em
    }
}

.uk-column-1-2 {
    column-count: 2
}

.uk-column-1-3 {
    column-count: 3
}

.uk-column-1-4 {
    column-count: 4
}

.uk-column-1-5 {
    column-count: 5
}

.uk-column-1-6 {
    column-count: 6
}

@media (min-width: 640px) {
    .uk-column-1-2\@s {
        column-count: 2
    }

    .uk-column-1-3\@s {
        column-count: 3
    }

    .uk-column-1-4\@s {
        column-count: 4
    }

    .uk-column-1-5\@s {
        column-count: 5
    }

    .uk-column-1-6\@s {
        column-count: 6
    }
}

@media (min-width: 960px) {
    .uk-column-1-2\@m {
        column-count: 2
    }

    .uk-column-1-3\@m {
        column-count: 3
    }

    .uk-column-1-4\@m {
        column-count: 4
    }

    .uk-column-1-5\@m {
        column-count: 5
    }

    .uk-column-1-6\@m {
        column-count: 6
    }
}

@media (min-width: 1200px) {
    .uk-column-1-2\@l {
        column-count: 2
    }

    .uk-column-1-3\@l {
        column-count: 3
    }

    .uk-column-1-4\@l {
        column-count: 4
    }

    .uk-column-1-5\@l {
        column-count: 5
    }

    .uk-column-1-6\@l {
        column-count: 6
    }
}

@media (min-width: 1600px) {
    .uk-column-1-2\@xl {
        column-count: 2
    }

    .uk-column-1-3\@xl {
        column-count: 3
    }

    .uk-column-1-4\@xl {
        column-count: 4
    }

    .uk-column-1-5\@xl {
        column-count: 5
    }

    .uk-column-1-6\@xl {
        column-count: 6
    }
}

.uk-column-span {
    column-span: all
}

.uk-cover {
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

iframe.uk-cover {
    pointer-events: none
}

.uk-cover-container {
    overflow: hidden;
    position: relative
}

.uk-background-default {
    background-color: #fff
}

.uk-background-muted {
    background-color: rgba(33, 32, 34, 0.1)
}

.uk-background-primary {
    background-color: #ffe64c
}

.uk-background-secondary {
    background-color: #00b2e3
}

.uk-background-cover, .uk-background-contain {
    background-position: 50% 50%;
    background-repeat: no-repeat
}

.uk-background-cover {
    background-size: cover
}

.uk-background-contain {
    background-size: contain
}

.uk-background-top-left {
    background-position: 0 0
}

.uk-background-top-center {
    background-position: 50% 0
}

.uk-background-top-right {
    background-position: 100% 0
}

.uk-background-center-left {
    background-position: 0 50%
}

.uk-background-center-center {
    background-position: 50% 50%
}

.uk-background-center-right {
    background-position: 100% 50%
}

.uk-background-bottom-left {
    background-position: 0 100%
}

.uk-background-bottom-center {
    background-position: 50% 100%
}

.uk-background-bottom-right {
    background-position: 100% 100%
}

.uk-background-norepeat {
    background-repeat: no-repeat
}

.uk-background-fixed {
    background-attachment: fixed;
    backface-visibility: hidden
}

@media (pointer: coarse) {
    .uk-background-fixed {
        background-attachment: scroll
    }
}

@media (max-width: 639px) {
    .uk-background-image\@s {
        background-image: none !important
    }
}

@media (max-width: 959px) {
    .uk-background-image\@m {
        background-image: none !important
    }
}

@media (max-width: 1199px) {
    .uk-background-image\@l {
        background-image: none !important
    }
}

@media (max-width: 1599px) {
    .uk-background-image\@xl {
        background-image: none !important
    }
}

.uk-background-blend-multiply {
    background-blend-mode: multiply
}

.uk-background-blend-screen {
    background-blend-mode: screen
}

.uk-background-blend-overlay {
    background-blend-mode: overlay
}

.uk-background-blend-darken {
    background-blend-mode: darken
}

.uk-background-blend-lighten {
    background-blend-mode: lighten
}

.uk-background-blend-color-dodge {
    background-blend-mode: color-dodge
}

.uk-background-blend-color-burn {
    background-blend-mode: color-burn
}

.uk-background-blend-hard-light {
    background-blend-mode: hard-light
}

.uk-background-blend-soft-light {
    background-blend-mode: soft-light
}

.uk-background-blend-difference {
    background-blend-mode: difference
}

.uk-background-blend-exclusion {
    background-blend-mode: exclusion
}

.uk-background-blend-hue {
    background-blend-mode: hue
}

.uk-background-blend-saturation {
    background-blend-mode: saturation
}

.uk-background-blend-color {
    background-blend-mode: color
}

.uk-background-blend-luminosity {
    background-blend-mode: luminosity
}

[class*='uk-align'] {
    display: block;
    margin-bottom: 1.875em
}

* + [class*='uk-align'] {
    margin-top: 1.875em
}

.uk-align-center {
    margin-left: auto;
    margin-right: auto
}

.uk-align-left {
    margin-top: 0;
    margin-right: 1.875em;
    float: left
}

.uk-align-right {
    margin-top: 0;
    margin-left: 1.875em;
    float: right
}

@media (min-width: 640px) {
    .uk-align-left\@s {
        margin-top: 0;
        margin-right: 1.875em;
        float: left
    }

    .uk-align-right\@s {
        margin-top: 0;
        margin-left: 1.875em;
        float: right
    }
}

@media (min-width: 960px) {
    .uk-align-left\@m {
        margin-top: 0;
        margin-right: 1.875em;
        float: left
    }

    .uk-align-right\@m {
        margin-top: 0;
        margin-left: 1.875em;
        float: right
    }
}

@media (min-width: 1200px) {
    .uk-align-left\@l {
        margin-top: 0;
        float: left
    }

    .uk-align-right\@l {
        margin-top: 0;
        float: right
    }

    .uk-align-left, .uk-align-left\@s, .uk-align-left\@m, .uk-align-left\@l {
        margin-right: 2.5em
    }

    .uk-align-right, .uk-align-right\@s, .uk-align-right\@m, .uk-align-right\@l {
        margin-left: 2.5em
    }
}

@media (min-width: 1600px) {
    .uk-align-left\@xl {
        margin-top: 0;
        margin-right: 2.5em;
        float: left
    }

    .uk-align-right\@xl {
        margin-top: 0;
        margin-left: 2.5em;
        float: right
    }
}

.uk-svg, .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
    fill: currentcolor
}

.uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
    stroke: currentcolor
}

.uk-svg {
    transform: translate(0, 0)
}

.uk-panel {
    display: flow-root;
    position: relative;
    box-sizing: border-box
}

.uk-panel > :last-child {
    margin-bottom: 0
}

.uk-panel-scrollable {
    height: 12.5em;
    padding: .625em;
    border: 1px solid rgba(33, 32, 34, 0.2);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    resize: both
}

.uk-clearfix::before {
    content: "";
    display: table-cell
}

.uk-clearfix::after {
    content: "";
    display: table;
    clear: both
}

.uk-float-left {
    float: left
}

.uk-float-right {
    float: right
}

[class*='uk-float-'] {
    max-width: 100%
}

.uk-overflow-hidden {
    overflow: hidden
}

.uk-overflow-auto {
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.uk-overflow-auto > :last-child {
    margin-bottom: 0
}

.uk-resize {
    resize: both
}

.uk-resize-vertical {
    resize: vertical
}

.uk-display-block {
    display: block !important
}

.uk-display-inline {
    display: inline !important
}

.uk-display-inline-block {
    display: inline-block !important
}

[class*='uk-inline'] {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    -webkit-backface-visibility: hidden
}

.uk-inline-clip {
    overflow: hidden
}

.uk-preserve-width, .uk-preserve-width canvas, .uk-preserve-width img, .uk-preserve-width svg, .uk-preserve-width video {
    max-width: none
}

.uk-responsive-width, .uk-responsive-height {
    box-sizing: border-box
}

.uk-responsive-width {
    max-width: 100% !important;
    height: auto
}

.uk-responsive-height {
    max-height: 100%;
    width: auto;
    max-width: none
}

.uk-border-circle {
    border-radius: 50%
}

.uk-border-pill {
    border-radius: 500px
}

.uk-border-rounded {
    border-radius: .3125em
}

.uk-inline-clip[class*='uk-border-'] {
    -webkit-transform: translateZ(0)
}

.uk-box-shadow-small {
    box-shadow: 0 .125em .5em rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-medium {
    box-shadow: 0 .3125em 1em rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-large {
    box-shadow: 0 .875em 1.25em rgba(0, 0, 0, 0.16)
}

.uk-box-shadow-xlarge {
    box-shadow: 0 1.875em 3.125em rgba(0, 0, 0, 0.16)
}

[class*='uk-box-shadow-hover'] {
    transition: box-shadow .1s ease-in-out
}

.uk-box-shadow-hover-small:hover {
    box-shadow: 0 .125em .5em rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-hover-medium:hover {
    box-shadow: 0 .3125em 1em rgba(0, 0, 0, 0.08)
}

.uk-box-shadow-hover-large:hover {
    box-shadow: 0 .875em 1.25em rgba(0, 0, 0, 0.16)
}

.uk-box-shadow-hover-xlarge:hover {
    box-shadow: 0 1.875em 3.125em rgba(0, 0, 0, 0.16)
}

@supports (filter: blur(0)) {
    .uk-box-shadow-bottom {
        display: inline-block;
        position: relative;
        max-width: 100%;
        vertical-align: middle
    }

    .uk-box-shadow-bottom::before {
        content: '';
        position: absolute;
        bottom: -1.875em;
        left: 0;
        right: 0;
        height: 1.875em;
        border-radius: 100%;
        background: rgba(50, 50, 50, 0.3);
        filter: blur(1.25em)
    }

    .uk-box-shadow-bottom > * {
        position: relative
    }
}

.uk-dropcap::first-letter, .uk-dropcap > p:first-of-type::first-letter {
    display: block;
    margin-right: .25em;
    float: left;
    font-size: 4.2em;
    line-height: 1
}

@-moz-document url-prefix() {
    .uk-dropcap::first-letter, .uk-dropcap > p:first-of-type::first-letter {
        margin-top: 1.1%
    }
}

@supports (-ms-ime-align: auto) {
    .uk-dropcap > p:first-of-type::first-letter {
        font-size: 1em
    }
}

.uk-logo {
    font-size: 1.8em;
    font-family: "Montserrat", Arial, sans-serif;
    color: #212022;
    text-decoration: none
}

.uk-logo:hover, .uk-logo:focus {
    color: #212022;
    outline: none;
    text-decoration: none
}

.uk-logo-inverse {
    display: none
}

.uk-disabled {
    pointer-events: none
}

.uk-drag, .uk-drag * {
    cursor: move
}

.uk-drag iframe {
    pointer-events: none
}

.uk-dragover {
    box-shadow: 0 0 1.25em rgba(100, 100, 100, 0.3)
}

.uk-blend-multiply {
    mix-blend-mode: multiply
}

.uk-blend-screen {
    mix-blend-mode: screen
}

.uk-blend-overlay {
    mix-blend-mode: overlay
}

.uk-blend-darken {
    mix-blend-mode: darken
}

.uk-blend-lighten {
    mix-blend-mode: lighten
}

.uk-blend-color-dodge {
    mix-blend-mode: color-dodge
}

.uk-blend-color-burn {
    mix-blend-mode: color-burn
}

.uk-blend-hard-light {
    mix-blend-mode: hard-light
}

.uk-blend-soft-light {
    mix-blend-mode: soft-light
}

.uk-blend-difference {
    mix-blend-mode: difference
}

.uk-blend-exclusion {
    mix-blend-mode: exclusion
}

.uk-blend-hue {
    mix-blend-mode: hue
}

.uk-blend-saturation {
    mix-blend-mode: saturation
}

.uk-blend-color {
    mix-blend-mode: color
}

.uk-blend-luminosity {
    mix-blend-mode: luminosity
}

.uk-transform-center {
    transform: translate(-50%, -50%)
}

.uk-transform-origin-top-left {
    transform-origin: 0 0
}

.uk-transform-origin-top-center {
    transform-origin: 50% 0
}

.uk-transform-origin-top-right {
    transform-origin: 100% 0
}

.uk-transform-origin-center-left {
    transform-origin: 0 50%
}

.uk-transform-origin-center-right {
    transform-origin: 100% 50%
}

.uk-transform-origin-bottom-left {
    transform-origin: 0 100%
}

.uk-transform-origin-bottom-center {
    transform-origin: 50% 100%
}

.uk-transform-origin-bottom-right {
    transform-origin: 100% 100%
}

.uk-flex {
    display: flex
}

.uk-flex-inline {
    display: inline-flex
}

.uk-flex::before, .uk-flex::after, .uk-flex-inline::before, .uk-flex-inline::after {
    display: none
}

.uk-flex-left {
    justify-content: flex-start
}

.uk-flex-center {
    justify-content: center
}

.uk-flex-right {
    justify-content: flex-end
}

.uk-flex-between {
    justify-content: space-between
}

.uk-flex-around {
    justify-content: space-around
}

@media (min-width: 640px) {
    .uk-flex-left\@s {
        justify-content: flex-start
    }

    .uk-flex-center\@s {
        justify-content: center
    }

    .uk-flex-right\@s {
        justify-content: flex-end
    }

    .uk-flex-between\@s {
        justify-content: space-between
    }

    .uk-flex-around\@s {
        justify-content: space-around
    }
}

@media (min-width: 960px) {
    .uk-flex-left\@m {
        justify-content: flex-start
    }

    .uk-flex-center\@m {
        justify-content: center
    }

    .uk-flex-right\@m {
        justify-content: flex-end
    }

    .uk-flex-between\@m {
        justify-content: space-between
    }

    .uk-flex-around\@m {
        justify-content: space-around
    }
}

@media (min-width: 1200px) {
    .uk-flex-left\@l {
        justify-content: flex-start
    }

    .uk-flex-center\@l {
        justify-content: center
    }

    .uk-flex-right\@l {
        justify-content: flex-end
    }

    .uk-flex-between\@l {
        justify-content: space-between
    }

    .uk-flex-around\@l {
        justify-content: space-around
    }
}

@media (min-width: 1600px) {
    .uk-flex-left\@xl {
        justify-content: flex-start
    }

    .uk-flex-center\@xl {
        justify-content: center
    }

    .uk-flex-right\@xl {
        justify-content: flex-end
    }

    .uk-flex-between\@xl {
        justify-content: space-between
    }

    .uk-flex-around\@xl {
        justify-content: space-around
    }
}

.uk-flex-stretch {
    align-items: stretch
}

.uk-flex-top {
    align-items: flex-start
}

.uk-flex-middle {
    align-items: center
}

.uk-flex-bottom {
    align-items: flex-end
}

.uk-flex-row {
    flex-direction: row
}

.uk-flex-row-reverse {
    flex-direction: row-reverse
}

.uk-flex-column {
    flex-direction: column
}

.uk-flex-column-reverse {
    flex-direction: column-reverse
}

.uk-flex-nowrap {
    flex-wrap: nowrap
}

.uk-flex-wrap {
    flex-wrap: wrap
}

.uk-flex-wrap-reverse {
    flex-wrap: wrap-reverse
}

.uk-flex-wrap-stretch {
    align-content: stretch
}

.uk-flex-wrap-top {
    align-content: flex-start
}

.uk-flex-wrap-middle {
    align-content: center
}

.uk-flex-wrap-bottom {
    align-content: flex-end
}

.uk-flex-wrap-between {
    align-content: space-between
}

.uk-flex-wrap-around {
    align-content: space-around
}

.uk-flex-first {
    order: -1
}

.uk-flex-last {
    order: 99
}

@media (min-width: 640px) {
    .uk-flex-first\@s {
        order: -1
    }

    .uk-flex-last\@s {
        order: 99
    }
}

@media (min-width: 960px) {
    .uk-flex-first\@m {
        order: -1
    }

    .uk-flex-last\@m {
        order: 99
    }
}

@media (min-width: 1200px) {
    .uk-flex-first\@l {
        order: -1
    }

    .uk-flex-last\@l {
        order: 99
    }
}

@media (min-width: 1600px) {
    .uk-flex-first\@xl {
        order: -1
    }

    .uk-flex-last\@xl {
        order: 99
    }
}

.uk-flex-none {
    flex: none
}

.uk-flex-auto {
    flex: auto
}

.uk-flex-1 {
    flex: 1
}

.uk-margin {
    margin-bottom: 1em
}

* + .uk-margin {
    margin-top: 1em !important
}

.uk-margin-top {
    margin-top: 1em !important
}

.uk-margin-bottom {
    margin-bottom: 1em !important
}

.uk-margin-left {
    margin-left: 1em !important
}

.uk-margin-right {
    margin-right: 1em !important
}

.uk-margin-small {
    margin-bottom: .625em
}

* + .uk-margin-small {
    margin-top: .625em !important
}

.uk-margin-small-top {
    margin-top: .625em !important
}

.uk-margin-small-bottom {
    margin-bottom: .625em !important
}

.uk-margin-small-left {
    margin-left: .625em !important
}

.uk-margin-small-right {
    margin-right: .625em !important
}

.uk-margin-medium {
    margin-bottom: 1.875em
}

* + .uk-margin-medium {
    margin-top: 1.875em !important
}

.uk-margin-medium-top {
    margin-top: 1.875em !important
}

.uk-margin-medium-bottom {
    margin-bottom: 1.875em !important
}

.uk-margin-medium-left {
    margin-left: 1.875em !important
}

.uk-margin-medium-right {
    margin-right: 1.875em !important
}

.uk-margin-large {
    margin-bottom: 1.875em
}

* + .uk-margin-large {
    margin-top: 1.875em !important
}

.uk-margin-large-top {
    margin-top: 1.875em !important
}

.uk-margin-large-bottom {
    margin-bottom: 1.875em !important
}

.uk-margin-large-left {
    margin-left: 1.875em !important
}

.uk-margin-large-right {
    margin-right: 1.875em !important
}

@media (min-width: 1200px) {
    .uk-margin-large {
        margin-bottom: 4.375em
    }

    * + .uk-margin-large {
        margin-top: 4.375em !important
    }

    .uk-margin-large-top {
        margin-top: 4.375em !important
    }

    .uk-margin-large-bottom {
        margin-bottom: 4.375em !important
    }

    .uk-margin-large-left {
        margin-left: 4.375em !important
    }

    .uk-margin-large-right {
        margin-right: 4.375em !important
    }
}

.uk-margin-xlarge {
    margin-bottom: 4.375em
}

* + .uk-margin-xlarge {
    margin-top: 4.375em !important
}

.uk-margin-xlarge-top {
    margin-top: 4.375em !important
}

.uk-margin-xlarge-bottom {
    margin-bottom: 4.375em !important
}

.uk-margin-xlarge-left {
    margin-left: 4.375em !important
}

.uk-margin-xlarge-right {
    margin-right: 4.375em !important
}

@media (min-width: 1200px) {
    .uk-margin-xlarge {
        margin-bottom: 8.75em
    }

    * + .uk-margin-xlarge {
        margin-top: 8.75em !important
    }

    .uk-margin-xlarge-top {
        margin-top: 8.75em !important
    }

    .uk-margin-xlarge-bottom {
        margin-bottom: 8.75em !important
    }

    .uk-margin-xlarge-left {
        margin-left: 8.75em !important
    }

    .uk-margin-xlarge-right {
        margin-right: 8.75em !important
    }
}

.uk-margin-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.uk-margin-auto-top {
    margin-top: auto !important
}

.uk-margin-auto-bottom {
    margin-bottom: auto !important
}

.uk-margin-auto-left {
    margin-left: auto !important
}

.uk-margin-auto-right {
    margin-right: auto !important
}

.uk-margin-auto-vertical {
    margin-top: auto !important;
    margin-bottom: auto !important
}

@media (min-width: 640px) {
    .uk-margin-auto\@s {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .uk-margin-auto-left\@s {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@s {
        margin-right: auto !important
    }
}

@media (min-width: 960px) {
    .uk-margin-auto\@m {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .uk-margin-auto-left\@m {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@m {
        margin-right: auto !important
    }
}

@media (min-width: 1200px) {
    .uk-margin-auto\@l {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .uk-margin-auto-left\@l {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@l {
        margin-right: auto !important
    }
}

@media (min-width: 1600px) {
    .uk-margin-auto\@xl {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .uk-margin-auto-left\@xl {
        margin-left: auto !important
    }

    .uk-margin-auto-right\@xl {
        margin-right: auto !important
    }
}

.uk-margin-remove {
    margin: 0 !important
}

.uk-margin-remove-top {
    margin-top: 0 !important
}

.uk-margin-remove-bottom {
    margin-bottom: 0 !important
}

.uk-margin-remove-left {
    margin-left: 0 !important
}

.uk-margin-remove-right {
    margin-right: 0 !important
}

.uk-margin-remove-vertical {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.uk-margin-remove-adjacent + *, .uk-margin-remove-first-child > :first-child {
    margin-top: 0 !important
}

.uk-margin-remove-last-child > :last-child {
    margin-bottom: 0 !important
}

@media (min-width: 640px) {
    .uk-margin-remove-left\@s {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@s {
        margin-right: 0 !important
    }
}

@media (min-width: 960px) {
    .uk-margin-remove-left\@m {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@m {
        margin-right: 0 !important
    }
}

@media (min-width: 1200px) {
    .uk-margin-remove-left\@l {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@l {
        margin-right: 0 !important
    }
}

@media (min-width: 1600px) {
    .uk-margin-remove-left\@xl {
        margin-left: 0 !important
    }

    .uk-margin-remove-right\@xl {
        margin-right: 0 !important
    }
}

.uk-padding {
    padding: 1.875em
}

@media (min-width: 1200px) {
    .uk-padding {
        padding: 2.5em
    }
}

.uk-padding-small {
    padding: 1em
}

.uk-padding-large {
    padding: 2.5em
}

@media (min-width: 1200px) {
    .uk-padding-large {
        padding: 4.375em
    }
}

.uk-padding-remove {
    padding: 0 !important
}

.uk-padding-remove-top {
    padding-top: 0 !important
}

.uk-padding-remove-bottom {
    padding-bottom: 0 !important
}

.uk-padding-remove-left {
    padding-left: 0 !important
}

.uk-padding-remove-right {
    padding-right: 0 !important
}

.uk-padding-remove-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.uk-padding-remove-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important
}

[class*='uk-position-top'], [class*='uk-position-bottom'], [class*='uk-position-left'], [class*='uk-position-right'], [class*='uk-position-center'] {
    position: absolute !important;
    max-width: 100%
}

.uk-position-top {
    top: 0;
    left: 0;
    right: 0
}

.uk-position-bottom {
    bottom: 0;
    left: 0;
    right: 0
}

.uk-position-left {
    top: 0;
    bottom: 0;
    left: 0
}

.uk-position-right {
    top: 0;
    bottom: 0;
    right: 0
}

.uk-position-top-left {
    top: 0;
    left: 0
}

.uk-position-top-right {
    top: 0;
    right: 0
}

.uk-position-bottom-left {
    bottom: 0;
    left: 0
}

.uk-position-bottom-right {
    bottom: 0;
    right: 0
}

.uk-position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: 100%;
    box-sizing: border-box
}

[class*='uk-position-center-left'], [class*='uk-position-center-right'] {
    top: 50%;
    transform: translateY(-50%)
}

.uk-position-center-left {
    left: 0
}

.uk-position-center-right {
    right: 0
}

.uk-position-center-left-out {
    right: 100%;
    width: max-content
}

.uk-position-center-right-out {
    left: 100%;
    width: max-content
}

.uk-position-top-center, .uk-position-bottom-center {
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    max-width: 100%;
    box-sizing: border-box
}

.uk-position-top-center {
    top: 0
}

.uk-position-bottom-center {
    bottom: 0
}

.uk-position-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.uk-position-relative {
    position: relative !important
}

.uk-position-absolute {
    position: absolute !important
}

.uk-position-fixed {
    position: fixed !important
}

.uk-position-z-index {
    z-index: 1
}

.uk-position-small {
    max-width: calc(100% - (1em * 2));
    margin: 1em
}

.uk-position-small.uk-position-center {
    transform: translate(-50%, -50%) translate(-1em, -1em)
}

.uk-position-small[class*='uk-position-center-left'], .uk-position-small[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-1em)
}

.uk-position-small.uk-position-top-center, .uk-position-small.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-1em)
}

.uk-position-medium {
    max-width: calc(100% - (1.875em * 2));
    margin: 1.875em
}

.uk-position-medium.uk-position-center {
    transform: translate(-50%, -50%) translate(-1.875em, -1.875em)
}

.uk-position-medium[class*='uk-position-center-left'], .uk-position-medium[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-1.875em)
}

.uk-position-medium.uk-position-top-center, .uk-position-medium.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-1.875em)
}

.uk-position-large {
    max-width: calc(100% - (1.875em * 2));
    margin: 1.875em
}

.uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-1.875em, -1.875em)
}

.uk-position-large[class*='uk-position-center-left'], .uk-position-large[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-1.875em)
}

.uk-position-large.uk-position-top-center, .uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-1.875em)
}

@media (min-width: 1200px) {
    .uk-position-large {
        max-width: calc(100% - (3.125em * 2));
        margin: 3.125em
    }

    .uk-position-large.uk-position-center {
        transform: translate(-50%, -50%) translate(-3.125em, -3.125em)
    }

    .uk-position-large[class*='uk-position-center-left'], .uk-position-large[class*='uk-position-center-right'] {
        transform: translateY(-50%) translateY(-3.125em)
    }

    .uk-position-large.uk-position-top-center, .uk-position-large.uk-position-bottom-center {
        transform: translateX(-50%) translateX(-3.125em)
    }
}

.uk-transition-toggle {
    -webkit-tap-highlight-color: transparent
}

.uk-transition-toggle:focus {
    outline: none
}

.uk-transition-fade, [class*='uk-transition-scale'], [class*='uk-transition-slide'] {
    transition: .3s ease-out;
    transition-property: opacity, transform, filter;
    opacity: 0
}

.uk-transition-toggle:hover .uk-transition-fade, .uk-transition-toggle:focus .uk-transition-fade, .uk-transition-active.uk-active .uk-transition-fade {
    opacity: 1
}

.uk-transition-scale-up {
    transform: scale(1, 1)
}

.uk-transition-scale-down {
    transform: scale(1.1, 1.1)
}

.uk-transition-toggle:hover .uk-transition-scale-up, .uk-transition-toggle:focus .uk-transition-scale-up, .uk-transition-active.uk-active .uk-transition-scale-up {
    opacity: 1;
    transform: scale(1.1, 1.1)
}

.uk-transition-toggle:hover .uk-transition-scale-down, .uk-transition-toggle:focus .uk-transition-scale-down, .uk-transition-active.uk-active .uk-transition-scale-down {
    opacity: 1;
    transform: scale(1, 1)
}

.uk-transition-slide-top {
    transform: translateY(-100%)
}

.uk-transition-slide-bottom {
    transform: translateY(100%)
}

.uk-transition-slide-left {
    transform: translateX(-100%)
}

.uk-transition-slide-right {
    transform: translateX(100%)
}

.uk-transition-slide-top-small {
    transform: translateY(-0.625em)
}

.uk-transition-slide-bottom-small {
    transform: translateY(.625em)
}

.uk-transition-slide-left-small {
    transform: translateX(-0.625em)
}

.uk-transition-slide-right-small {
    transform: translateX(.625em)
}

.uk-transition-slide-top-medium {
    transform: translateY(-3.125em)
}

.uk-transition-slide-bottom-medium {
    transform: translateY(3.125em)
}

.uk-transition-slide-left-medium {
    transform: translateX(-3.125em)
}

.uk-transition-slide-right-medium {
    transform: translateX(3.125em)
}

.uk-transition-toggle:hover [class*='uk-transition-slide'], .uk-transition-toggle:focus [class*='uk-transition-slide'], .uk-transition-active.uk-active [class*='uk-transition-slide'] {
    opacity: 1;
    transform: translate(0, 0)
}

.uk-transition-opaque {
    opacity: 1
}

.uk-transition-slow {
    transition-duration: .7s
}

[hidden], .uk-hidden {
    display: none !important
}

@media (min-width: 640px) {
    .uk-hidden\@s {
        display: none !important
    }
}

@media (min-width: 960px) {
    .uk-hidden\@m {
        display: none !important
    }
}

@media (min-width: 1200px) {
    .uk-hidden\@l {
        display: none !important
    }
}

@media (min-width: 1600px) {
    .uk-hidden\@xl {
        display: none !important
    }
}

@media (max-width: 639px) {
    .uk-visible\@s {
        display: none !important
    }
}

@media (max-width: 959px) {
    .uk-visible\@m {
        display: none !important
    }
}

@media (max-width: 1199px) {
    .uk-visible\@l {
        display: none !important
    }
}

@media (max-width: 1599px) {
    .uk-visible\@xl {
        display: none !important
    }
}

.uk-invisible {
    visibility: hidden !important
}

.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important
}

.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
    opacity: 0 !important
}

.uk-visible-toggle {
    -webkit-tap-highlight-color: transparent
}

.uk-visible-toggle:focus {
    outline: none
}

@media (pointer: coarse) {
    .uk-hidden-touch {
        display: none !important
    }
}

.uk-hidden-notouch {
    display: none !important
}

@media (pointer: coarse) {
    .uk-hidden-notouch {
        display: block !important
    }
}

.uk-light {
    color: #fff
}

.uk-light a, .uk-light .uk-link {
    color: #fff
}

.uk-light a:hover, .uk-light .uk-link:hover {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light :not(pre) > code, .uk-light :not(pre) > kbd, .uk-light :not(pre) > samp {
    color: #fff
}

.uk-light em {
    color: #fff
}

.uk-light h1, .uk-light .uk-h1, .uk-light h2, .uk-light .uk-h2, .uk-light h3, .uk-light .uk-h3, .uk-light h4, .uk-light .uk-h4, .uk-light h5, .uk-light .uk-h5, .uk-light h6, .uk-light .uk-h6 {
    color: #fff
}

.uk-light hr, .uk-light .uk-hr {
    border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light a.uk-link-muted, .uk-light .uk-link-muted a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light a.uk-link-muted:hover, .uk-light .uk-link-muted a:hover {
    color: #fff
}

.uk-light a.uk-link-text:hover, .uk-light .uk-link-text a:hover {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light a.uk-link-heading:hover, .uk-light .uk-link-heading a:hover {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-heading-divider {
    border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-heading-bullet::before {
    border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-heading-line > ::before, .uk-light .uk-heading-line > ::after {
    border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-divider-icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(255,255,255,0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-divider-icon::before, .uk-light .uk-divider-icon::after {
    border-bottom-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-divider-small::after {
    border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-divider-vertical::after {
    border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-list-divider > li:nth-child(n+2) {
    border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-list-striped > li:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.1)
}

.uk-light .uk-list-bullet > li::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-icon-link {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-icon-link:hover, .uk-light .uk-icon-link:focus {
    color: #fff
}

.uk-light .uk-icon-link:active, .uk-light .uk-active > .uk-icon-link {
    color: #fff
}

.uk-light .uk-icon-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-icon-button:hover, .uk-light .uk-icon-button:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff
}

.uk-light .uk-icon-button:active {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff
}

.uk-light .uk-input, .uk-light .uk-select, .uk-light .uk-textarea {
    background-color: rgba(255, 255, 255, 0.9);
    color: #212022;
    background-clip: padding-box
}

.uk-light .uk-input:focus, .uk-light .uk-select:focus, .uk-light .uk-textarea:focus {
    background-color: rgba(255, 255, 255, 0.9);
    color: #212022
}

.uk-light .uk-input::-ms-input-placeholder {
    color: rgba(33, 32, 34, 0.6) !important
}

.uk-light .uk-input::placeholder {
    color: rgba(33, 32, 34, 0.6)
}

.uk-light .uk-textarea::-ms-input-placeholder {
    color: rgba(33, 32, 34, 0.6) !important
}

.uk-light .uk-textarea::placeholder {
    color: rgba(33, 32, 34, 0.6)
}

.uk-light .uk-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23212022%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23212022%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-radio, .uk-light .uk-checkbox {
    background-color: #fff
}

.uk-light .uk-radio:checked, .uk-light .uk-checkbox:checked, .uk-light .uk-checkbox:indeterminate {
    background-color: #ffe64c
}

.uk-light .uk-radio:checked:focus, .uk-light .uk-checkbox:checked:focus, .uk-light .uk-checkbox:indeterminate:focus {
    background-color: #ffdf19
}

.uk-light .uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23212022%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23212022%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}

.uk-light .uk-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23212022%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-form-icon {
    color: #212022
}

.uk-light .uk-form-icon:hover {
    color: #212022
}

.uk-light .uk-button-default {
    background-color: #ffc844;
    color: #212022
}

.uk-light .uk-button-default:hover, .uk-light .uk-button-default:focus {
    background-color: #fff;
    color: #00b2e3
}

.uk-light .uk-button-default:active, .uk-light .uk-button-default.uk-active {
    background-color: #fff;
    color: #00b2e3
}

.uk-light .uk-button-primary {
    background-color: #fff;
    color: #00b2e3
}

.uk-light .uk-button-primary:hover, .uk-light .uk-button-primary:focus {
    background-color: #fff;
    color: #0066b3
}

.uk-light .uk-button-primary:active, .uk-light .uk-button-primary.uk-active {
    background-color: #fff;
    color: #0066b3
}

.uk-light .uk-button-secondary {
    background-color: #fff;
    color: #0066b3
}

.uk-light .uk-button-secondary:hover, .uk-light .uk-button-secondary:focus {
    background-color: #fff;
    color: #00b2e3
}

.uk-light .uk-button-secondary:active, .uk-light .uk-button-secondary.uk-active {
    background-color: #fff;
    color: #00b2e3
}

.uk-light .uk-button-text {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-button-text:hover, .uk-light .uk-button-text:focus {
    color: #fff
}

.uk-light .uk-button-text:disabled {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-button-link {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-button-link:hover, .uk-light .uk-button-link:focus {
    color: #fff
}

.uk-light .uk-grid-divider > :not(.uk-first-column)::before {
    border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-close {
    color: #212022
}

.uk-light .uk-close:hover, .uk-light .uk-close:focus {
    color: rgba(33, 32, 34, 0.6)
}

.uk-light .uk-totop {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-totop:hover, .uk-light .uk-totop:focus {
    color: #fff
}

.uk-light .uk-totop:active {
    color: #fff
}

.uk-light .uk-badge {
    background-color: #fff;
    color: #212022
}

.uk-light .uk-badge:hover, .uk-light .uk-badge:focus {
    color: #212022
}

.uk-light .uk-label {
    background-color: #fff;
    color: #212022
}

.uk-light .uk-article-meta {
    color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-search-input {
    color: #fff
}

.uk-light .uk-search-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8) !important
}

.uk-light .uk-search-input::placeholder {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-search .uk-search-icon {
    color: #fff
}

.uk-light .uk-search .uk-search-icon:hover {
    color: #fff
}

.uk-light .uk-search-default .uk-search-input {
    background-color: transparent
}

.uk-light .uk-search-default .uk-search-input:focus {
    background-color: transparent
}

.uk-light .uk-search-navbar .uk-search-input {
    background-color: transparent
}

.uk-light .uk-search-large .uk-search-input {
    background-color: transparent
}

.uk-light .uk-search-toggle {
    color: #fff
}

.uk-light .uk-search-toggle:hover, .uk-light .uk-search-toggle:focus {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-nav-parent-icon > .uk-parent > a::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-nav-parent-icon > .uk-parent.uk-open > a::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E")
}

.uk-light .uk-nav-default > li > a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-nav-default > li > a:hover, .uk-light .uk-nav-default > li > a:focus {
    color: #fff
}

.uk-light .uk-nav-default > li.uk-active > a {
    color: #fff
}

.uk-light .uk-nav-default .uk-nav-header {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-nav-default .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-nav-default .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-nav-default .uk-nav-sub a:hover, .uk-light .uk-nav-default .uk-nav-sub a:focus {
    color: #fff
}

.uk-light .uk-nav-primary > li > a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-nav-primary > li > a:hover, .uk-light .uk-nav-primary > li > a:focus {
    color: #fff
}

.uk-light .uk-nav-primary > li.uk-active > a {
    color: #fff
}

.uk-light .uk-nav-primary .uk-nav-header {
    color: #fff
}

.uk-light .uk-nav-primary .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-nav-primary .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-nav-primary .uk-nav-sub a:hover, .uk-light .uk-nav-primary .uk-nav-sub a:focus {
    color: #fff
}

.uk-light .uk-navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-navbar-nav > li:hover > a, .uk-light .uk-navbar-nav > li > a:focus, .uk-light .uk-navbar-nav > li > a.uk-open {
    color: #fff
}

.uk-light .uk-navbar-nav > li > a:active {
    color: #fff
}

.uk-light .uk-navbar-nav > li.uk-active > a {
    color: #fff
}

.uk-light .uk-navbar-item {
    color: #fff
}

.uk-light .uk-navbar-toggle {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-navbar-toggle:hover, .uk-light .uk-navbar-toggle:focus, .uk-light .uk-navbar-toggle.uk-open {
    color: #fff
}

.uk-light .uk-subnav > * > :first-child {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-subnav > * > a:hover, .uk-light .uk-subnav > * > a:focus {
    color: #fff
}

.uk-light .uk-subnav > .uk-active > a {
    color: #fff
}

.uk-light .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
    border-left-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-subnav-pill > * > :first-child {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-subnav-pill > * > a:hover, .uk-light .uk-subnav-pill > * > a:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff
}

.uk-light .uk-subnav-pill > * > a:active {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff
}

.uk-light .uk-subnav-pill > .uk-active > a {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff
}

.uk-light .uk-subnav > .uk-disabled > a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-breadcrumb > * > * {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-breadcrumb > * > :hover, .uk-light .uk-breadcrumb > * > :focus {
    color: #fff
}

.uk-light .uk-breadcrumb > :last-child > * {
    color: #fff
}

.uk-light .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-pagination > * > * {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-pagination > * > :hover, .uk-light .uk-pagination > * > :focus {
    color: #fff
}

.uk-light .uk-pagination > .uk-active > * {
    color: #fff
}

.uk-light .uk-pagination > .uk-disabled > * {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-tab > * > a {
    color: #fff
}

.uk-light .uk-tab > * > a:hover, .uk-light .uk-tab > * > a:focus {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-tab > .uk-active > a {
    color: #fff
}

.uk-light .uk-tab > .uk-disabled > a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-slidenav {
    color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-slidenav:hover, .uk-light .uk-slidenav:focus {
    color: rgba(255, 255, 255, 0.95)
}

.uk-light .uk-slidenav:active {
    color: rgba(255, 255, 255, 0.7)
}

.uk-light .uk-dotnav > * > * {
    background-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-dotnav > * > :hover, .uk-light .uk-dotnav > * > :focus {
    background-color: rgba(255, 255, 255, 0.9)
}

.uk-light .uk-dotnav > * > :active {
    background-color: rgba(255, 255, 255, 0.5)
}

.uk-light .uk-dotnav > .uk-active > * {
    background-color: rgba(255, 255, 255, 0.9)
}

.uk-light .uk-accordion-title {
    color: #fff
}

.uk-light .uk-accordion-title:hover, .uk-light .uk-accordion-title:focus {
    color: #fff
}

.uk-light .uk-iconnav > * > a {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-iconnav > * > a:hover, .uk-light .uk-iconnav > * > a:focus {
    color: #fff
}

.uk-light .uk-iconnav > .uk-active > a {
    color: #fff
}

.uk-light .uk-text-lead {
    color: #fff
}

.uk-light .uk-text-meta {
    color: rgba(255, 255, 255, 0.8)
}

.uk-light .uk-text-muted {
    color: rgba(255, 255, 255, 0.8) !important
}

.uk-light .uk-text-emphasis {
    color: #fff !important
}

.uk-light .uk-text-primary {
    color: #ffe64c !important
}

.uk-light .uk-column-divider {
    column-rule-color: rgba(255, 255, 255, 0.2)
}

.uk-light .uk-logo {
    color: #fff
}

.uk-light .uk-logo:hover, .uk-light .uk-logo:focus {
    color: #fff
}

.uk-light .uk-logo > :not(.uk-logo-inverse):not(:only-of-type) {
    display: none
}

.uk-light .uk-logo-inverse {
    display: inline
}

@media print {
    *, *::before, *::after {
        background: transparent !important;
        color: black !important;
        box-shadow: none !important;
        text-shadow: none !important
    }

    a, a:visited {
        text-decoration: underline
    }

    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    tr, img {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    @page {
        margin: 0.5cm
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3
    }

    h2, h3 {
        page-break-after: avoid
    }
}

body {
    line-height: 1.4
}

@media (orientation: portrait) and (max-width: 374px) {
    body {
        font-size: .8em
    }
}

@media (orientation: portrait) and (min-width: 375px) and (max-width: 539px) {
    body {
        font-size: .9em
    }
}

@media (orientation: portrait) and (min-width: 540px) and (max-width: 1023px) {
    body {
        font-size: 1.1em
    }
}

@media (orientation: portrait) and (min-width: 1024px) {
    body {
        font-size: 1.3em
    }
}

@media (orientation: landscape) and (max-width: 767px) {
    body {
        font-size: .7em
    }
}

@media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
    body {
        font-size: .9em
    }
}

@media (orientation: landscape) and (min-width: 1024px) and (max-width: 1439px) {
    body {
        font-size: 1em
    }
}

@media (orientation: landscape) and (min-width: 1440px) {
    body {
        font-size: 1.1em
    }
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-width: 1366px) {
    body {
        font-size: 1.2em
    }
}

::-moz-selection {
    background: #ffe64c;
    color: #212022;
    text-shadow: none
}

::selection {
    background: #ffe64c;
    color: #212022;
    text-shadow: none
}

a {
    -webkit-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1)
}

hr {
    border-color: rgba(33, 32, 34, 0.2)
}

#container {
    position: relative;
    width: 100%;
    z-index: 10;
    overflow-x: hidden;
    background-color: white
}

.uk-input, .uk-select, .uk-textarea, .uk-button, .uk-alert, .uk-search-input, .uk-pagination > * > *, .uk-tooltip, .top-sub .uk-navbar-dropdown, .btn-whatsapp, .btn-client-store, .recipe-tooltip {
    border-radius: .35em
}

.top-main, .item-product-highlight:hover, .item-product:hover, .item-recipe:hover, .recipe-tooltip {
    -webkit-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2)
}

.uk-accordion-title:before, #main-footer .uk-h5 a:before, .sidebar:not(.uk-offcanvas-bar) .uk-nav > li:before, .sidebar .uk-list li a:before, .zoom-image:before, .cont-sirena-cash .uk-card:not(.uk-card-default) ul:not(.uk-list) li:before {
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900
}

.uk-text-tertiary {
    color: #0066b3
}

.uk-background-tertiary {
    background-color: #0066b3
}

.uk-background-quaternary {
    background-color: #00c4b3
}

.slider-arrow {
    color: #0066b3;
    padding: .25em .5em;
    font-size: 1.25em
}

.slider-arrow.uk-position-center-left-out {
    right: calc(100% - 0.65em)
}

.slider-arrow.uk-position-center-right-out {
    left: calc(100% - 0.65em)
}

.title-border {
    position: relative;
    border-bottom: 2px solid rgba(33, 32, 34, 0.2);
    padding-bottom: .75em;
    margin-bottom: 1em
}

.title-border > * {
    margin: 0
}

.title-border .uk-button-text {
    position: absolute;
    bottom: 1em;
    right: 0;
    font-size: .65em
}

@media (max-width: 539px) {
    .title-border .uk-button-text {
        font-size: 1.5em;
        bottom: 0;
        line-height: .8;
        padding-left: 1em
    }

    .title-border .uk-button-text span {
        display: none
    }
}

.title-border .uk-button:not(.uk-button-text) {
    padding-left: 1em;
    padding-right: 1em
}

.uk-light .title-border {
    border-color: rgba(255, 255, 255, 0.4)
}

.uk-tab {
    margin-left: 0
}

.uk-tab > * {
    padding-left: 0 !important
}

.uk-tab:not(.uk-tab-default) {
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.uk-tab:not(.uk-tab-default) > * > a {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.35em;
    opacity: .5;
    padding: 0 1em .35em 1em;
    border-bottom: .2em solid transparent
}

.uk-tab:not(.uk-tab-default) > * > a:hover {
    opacity: 1;
    color: #212022
}

.uk-tab:not(.uk-tab-default) > .uk-active > a {
    opacity: 1;
    color: #00b2e3;
    border-color: #00b2e3
}

#main-header {
    position: relative;
    z-index: 1000;
    width: 100%;
    line-height: 1;
    padding-top: 5.5em
}

#main-header .uk-navbar-container a i:first-child {
    margin-right: .75em
}

#main-header .uk-navbar-container a i:last-child {
    margin-left: .75em
}

#main-header .uk-navbar-item.uk-width-expand {
    justify-content: flex-start
}

.logo {
    position: relative;
    display: inline-block;
    width: auto;
    padding: .75em 1em
}

.logo svg, .logo img {
    width: auto;
    display: inline-block;
    position: relative;
    z-index: 0
}

.logo-main img {
    height: 4em;
    -webkit-transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1)
}

.logo-main img:nth-of-type(2) {
    height: 2.4em;
    opacity: 0;
    position: absolute;
    top: .7em;
    left: 0
}

#main-header.uk-sticky-fixed.uk-active .logo-main img:nth-of-type(1) {
    opacity: 0;
    pointer-events: none
}

#main-header.uk-sticky-fixed.uk-active .logo-main img:nth-of-type(2) {
    opacity: 1
}

.logo-sub img {
    height: 2.25em
}

@media (max-width: 539px) {
    .logo-sub {
        padding: .5em
    }
}

.logo-go {
    padding: 0 2em 0 0
}

.logo-go svg, .logo-go img {
    height: 5em
}

.logo-go svg path, .logo-go svg polygon {
    fill: #0066b3
}

@media (max-width: 959px) {
    .logo-go {
        padding-bottom: 2em
    }
}

.top-main {
    background: #ffe64c;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100
}

.top-main .uk-navbar-item:first-child {
    padding-left: 0 !important
}

.top-main .uk-navbar-item:last-child {
    padding-right: 0 !important
}

@media (max-width: 539px) {
    .top-main .logo-main {
        padding-left: 0
    }
}

.top-sub {
    background: #00b2e3;
    z-index: 0;
    top: 5.5em !important
}

.top-sub .uk-navbar-nav > li > a {
    color: white
}

.top-sub .uk-navbar-nav > li > a.uk-open {
    color: #212022;
    background: white
}

.top-sub .uk-navbar-dropdown {
    width: 100%;
    padding: 1em 2em;
    border-top-left-radius: 0
}

.top-sub .uk-navbar-dropdown-nav {
    font-size: .75em
}

.btn-cart-main {
    display: inline-block;
    position: relative
}

.btn-cart-main i {
    position: relative;
    z-index: 0;
    font-size: 1.5em;
    margin: 0 !important;
    color: #ff671d
}

.btn-cart-main small {
    font-size: .65em;
    font-weight: 600;
    display: block
}

.btn-cart-main span {
    display: inline-block;
    position: absolute;
    top: -.25em;
    right: 0;
    z-index: 10;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    line-height: 1.5em;
    text-align: center;
    color: #212022;
    background-color: white
}

.btn-cart-main.btn-cart-valid i {
    color: #0066b3
}

.btn-cart-main.btn-cart-valid span {
    color: white;
    background-color: #00b2e3
}

.btn-user-menu i {
    color: #0066b3
}

.btn-user-menu ~ .uk-navbar-dropdown {
    width: 22em
}

.btn-user-menu ~ .uk-navbar-dropdown .uk-flex {
    border-bottom: 1px solid rgba(33, 32, 34, 0.1);
    padding-bottom: 1em;
    margin-bottom: 1em
}

.btn-user-menu ~ .uk-navbar-dropdown .uk-navbar-dropdown-nav {
    font-size: .8em
}

.btn-user-menu ~ .uk-navbar-dropdown .uk-navbar-dropdown-nav i {
    width: 2em;
    text-align: center
}

.btn-user-menu ~ .uk-navbar-dropdown a {
    white-space: nowrap
}

.btn-user-menu ~ .uk-navbar-dropdown i {
    margin: 0 !important
}

@media (max-width: 959px) {
    .btn-user-menu i:first-child {
        font-size: 1.5em;
        margin: 0 !important
    }
}

.box-user-login form, .box-user-login form p, .box-user-recover form, .box-user-recover form p {
    margin: .65em 0
}

.box-user-login hr, .box-user-recover hr {
    border-color: rgba(33, 32, 34, 0.1)
}

.box-user-login > .uk-text-center:first-child, .box-user-recover > .uk-text-center:first-child {
    padding-top: .25em
}

.box-user-login > .uk-text-center:last-child, .box-user-recover > .uk-text-center:last-child {
    padding-bottom: .25em
}

.box-user-login > .uk-text-center:last-child p, .box-user-recover > .uk-text-center:last-child p {
    margin: .5em 0
}

.btn-whatsapp {
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    padding-left: .75em;
    padding-right: .75em
}

.btn-whatsapp i {
    margin: 0 !important;
    font-size: 1.5em
}

.btn-whatsapp:hover {
    color: #00b2e3;
    background-color: white
}

.btn-client-store {
    text-transform: uppercase;
    color: white;
    background-color: #0066b3;
    margin-left: .5em
}

.btn-client-store i, .btn-client-store span, .btn-client-store small {
    display: block
}

.btn-client-store span {
    font-size: .8em;
    font-weight: 500
}

.btn-client-store small {
    color: #ffe64c
}

.btn-client-store:hover {
    color: #212022;
    background-color: #ffc844
}

.btn-client-store:hover small {
    color: #0066b3
}

#menu-main .uk-flex {
    padding: 0 2rem
}

#menu-main .logo {
    padding-left: 0;
    padding-right: 0
}

#menu-main .link-gruporamos {
    padding-right: 1em !important
}

#menu-main .link-gruporamos svg path {
    fill: #212022 !important
}

#menu-main a {
    color: #212022;
    display: block
}

#menu-main .uk-nav a:not(.link-gruporamos):hover, #menu-main .uk-nav a.uk-open {
    background-color: #ffc844
}

#menu-main .uk-nav > .uk-active > a {
    background-color: #ffc844
}

#menu-main .nav-main > li > a {
    padding: .65em 2rem;
    font-weight: 600
}

#menu-main .nav-sub {
    font-size: .85em;
    border-top: 1px solid rgba(33, 32, 34, 0.2);
    padding-top: 2em
}

#menu-main .nav-sub > li > a {
    padding: .5em 2rem
}

#menu-main li > a > i {
    float: right;
    /*margin-top: .25em*/
}

#menu-main .uk-position-cover {
    z-index: 0
}

@media (max-width: 539px) {
    #menu-main .uk-flex {
        padding: 0 1rem
    }

    #menu-main .nav-main > li > a {
        padding: .65em 1rem
    }

    #menu-main .nav-sub > li > a {
        padding: .5em 1rem
    }
}

@media (max-width: 767px) {
    #menu-main .menu-level-1 {
        width: 80vw
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    #menu-main .menu-level-1 {
        width: 40vw
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    #menu-main .menu-level-1 {
        width: 33vw
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    #menu-main .menu-level-1 {
        width: 24vw
    }
}

@media (min-width: 1600px) {
    #menu-main .menu-level-1 {
        width: 20vw
    }
}

#menu-main .uk-offcanvas-bar {
    width: 100%;
    left: 0;
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    z-index: -1
}

#menu-main.uk-open > .uk-offcanvas-bar {
    opacity: 1;
    pointer-events: auto;
    z-index: 1000
}

#menu-main .menu-level-1 {
    position: relative;
    z-index: 10;
    padding-bottom: 25vh
}

#menu-main li > .uk-dropdown {
    width: 100%;
    margin: 0;
    padding: 1em 0;
    z-index: 0
}

#menu-main li > .uk-dropdown.uk-open {
    height: 100%;
    top: 0 !important
}

#menu-main li > .uk-dropdown > .uk-nav {
    padding-bottom: 2em
}

#menu-main li > .uk-dropdown .uk-nav > li > a {
    padding: .5em 1.5rem;
    font-size: .9em
}

#menu-main li > .uk-dropdown .uk-nav > li > a img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 12em
}

#menu-main li > .uk-dropdown .uk-nav > li > a p {
    font-size: .9em
}

#menu-main li > .uk-dropdown .uk-nav > li > a p span, #menu-main li > .uk-dropdown .uk-nav > li > a p small {
    display: block
}

#menu-main li > .uk-dropdown .uk-nav > li > a p span {
    font-weight: 600
}

#menu-main li > .uk-dropdown .uk-nav > li.nav-item-highlight > a {
    font-weight: 600;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

#menu-main li > .uk-dropdown .uk-nav > li.nav-item-catalog > a {
    padding-top: 1em;
    padding-bottom: 1em
}

#menu-main li > .uk-dropdown .uk-nav > li.nav-item-catalog > a:hover {
    background-color: rgba(33, 32, 34, 0.1)
}

#menu-main li > .uk-dropdown .uk-nav-header {
    padding: .75em 1.5rem 1.5em 1.5rem;
    font-size: 1em;
    font-weight: 600;
    border-bottom: 1px solid rgba(33, 32, 34, 0.2);
    margin-bottom: 1em
}

#menu-main li > .uk-dropdown .uk-nav-header i {
    font-size: 1.25em;
    margin-right: .5em
}

#menu-main li > .uk-dropdown .uk-nav-header:hover {
    cursor: pointer
}

@media (max-width: 1199px) {
    #menu-main li > .uk-dropdown.uk-open {
        left: 0 !important
    }
}

@media (min-width: 1200px) {
    #menu-main .uk-nav-header {
        display: none !important
    }

    #menu-main li > .uk-dropdown.uk-open {
        left: 100% !important
    }
}

@media (min-width: 1440px) {
    #menu-main {
        font-size: .9em
    }
}

#main-footer {
    padding: 3em 0
}

#main-footer .uk-h5 {
    text-transform: uppercase
}

#main-footer .uk-h5 a {
    display: block
}

#main-footer .uk-h5 a:hover {
    color: white
}

#main-footer .uk-h5 a:before {
    content: '\f0d7';
    position: absolute;
    top: 50%;
    right: .5em;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

#main-footer .uk-nav a i {
    font-size: 1.15em;
    width: 1.5em;
    text-align: center
}

#main-footer .uk-nav-divider {
    width: 50%;
    margin: 1em 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4)
}

#main-footer .nav-social {
    margin-top: 1em
}

#main-footer .nav-social li {
    display: inline-block;
    margin-right: .25em
}

#main-footer .nav-social a {
    font-size: .8em;
    width: 3em;
    height: 3em;
    line-height: 2.9em;
    text-align: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1)
}

#main-footer .nav-social a:hover {
    color: #00b2e3;
    background-color: white
}

#main-footer .uk-button.uk-button-text {
    padding-top: 0 !important
}

@media (max-width: 639px) {
    #main-footer .uk-grid, #main-footer .uk-flex {
        font-size: 1.1em
    }

    #main-footer .uk-h5 a {
        position: relative
    }

    #main-footer .uk-nav {
        display: none;
        padding-bottom: 1em
    }

    #main-footer .show .uk-nav {
        display: block
    }

    #main-footer .show .uk-h5 a:before {
        content: '\f0d8'
    }

    #main-footer .subnav-footer:not(.subnav-footer-4) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4)
    }

    #main-footer .uk-nav-divider {
        border-top-color: rgba(255, 255, 255, 0.2)
    }
}

@media (min-width: 640px) {
    #main-footer .uk-h5 a {
        pointer-events: none
    }

    #main-footer .uk-h5 a:before {
        opacity: 0
    }
}

@media (min-width: 1024px) {
    #main-footer .uk-grid, #main-footer .uk-flex {
        font-size: .9em
    }
}

.link-gruporamos {
    display: flex !important;
    align-items: flex-end;
    margin-top: 1em
}

.link-gruporamos svg {
    width: 3em;
    height: auto
}

.link-gruporamos span {
    display: inline-block;
    padding: .5em 1em
}

.btns-download {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center
}

.btns-download > p {
    margin: 0
}

.btns-download a {
    display: inline-block
}

.btns-download a img {
    width: auto;
    height: 2.5em
}

@media (max-width: 959px) {
    .btns-download {
        margin-top: 1em
    }

    .btns-download > p {
        padding-bottom: .5em
    }
}

@media (min-width: 960px) {
    .btns-download > p {
        padding-right: 1em;
        text-align: right
    }
}

.hidden-desktop {
    display: none
}

.hidden-desktop a {
    font-weight: 700
}

.mobile.apple a.btn-playstore, .mobile.android a.btn-appstore {
    display: none !important
}

.mobile.apple a.btn-appstore + .hidden-desktop.googleplay {
    display: block !important
}

.mobile.android a.btn-playstore + .hidden-desktop.appstore {
    display: block !important
}

.btn-search-minimal {
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
    margin-right: .5em;
    background-color: #0066b3;
    text-align: center;
    font-size: 1.25em !important;
    display: inline-block
}

.btn-search-minimal i {
    margin: 0 !important;
    color: white !important
}

.btn-search-minimal:hover {
    background-color: #0066b3
}

.btn-search-minimal:hover i {
    color: white !important
}

.uk-search {
    width: 100%
}

.uk-search .uk-search-icon {
    background-color: transparent;
    border: 0;
    border-radius: 0 .35em .35em 0
}

.uk-search .uk-search-icon:hover {
    color: white;
    background-color: #00b2e3;
    cursor: pointer
}

@media (max-width: 959px) {
    #search-main:not(.search-mobile-open) .uk-search-navbar {
        opacity: 0;
        pointer-events: none
    }

    #search-main.search-mobile-open .btn-search-minimal .fas:before {
        content: '\f00d'
    }

    #search-main .uk-search-navbar {
        width: 100vw;
        max-width: none;
        position: absolute;
        top: 100%;
        left: 50%;
        font-size: 1.3em;
        background-color: white;
        -webkit-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.2);
        -webkit-transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
        transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    #search-main .uk-search-navbar .uk-search-input {
        width: 100%;
        border-radius: 0;
        height: 3.275em
    }

    #search-main .uk-search-navbar .uk-search-input:hover, #search-main .uk-search-navbar .uk-search-input:active, #search-main .uk-search-navbar .uk-search-input:focus {
        border-color: transparent
    }

    #search-main .uk-search-navbar .uk-search-icon {
        width: 3em;
        border-radius: 0
    }

    #search-main #search-main-close {
        width: 3em;
        right: 3.1em
    }

    #search-main #search-main-drop {
        width: 100vw;
        max-width: none;
        font-size: 1.3em;
        margin-top: 1em;
        border-top: 1px solid rgba(33, 32, 34, 0.1)
    }

    #search-main #search-main-wrap {
        border-radius: 0
    }
}

@media (max-width: 539px) {
    #search-main .uk-search-navbar, #search-main #search-main-drop {
        font-size: 1.125em
    }
}

@media (min-width: 960px) {
    #search-main {
        position: relative;
        padding-right: 1em
    }

    #search-main .uk-search-navbar {
        max-width: 30em
    }
}

#search-main .item-product i, #search-main .item-recipe i {
    margin: 0 !important
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
    #search-main .uk-search-input {
        font-size: 16px;
        height: 2.75em !important
    }
}

#search-main-close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2.25em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #212022;
    border: solid rgba(33, 32, 34, 0.1);
    border-width: 0 1px
}

#search-main-close:hover {
    color: #00b2e3
}

#search-main-wrap {
    padding: 1.5em;
    max-width: 100%;
    max-height: calc(100vh - 9em);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.uk-drop {
    width: 100%;
    max-width: 70em
}

@media (min-width: 960px) and (max-width: 1199px) {
    .uk-drop {
        width: 160%
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .uk-drop {
        width: 120%
    }
}

@media (min-width: 1440px) and (max-width: 1599px) {
    .uk-drop {
        width: 110%
    }
}

.uk-drop p.uk-text-uppercase .uk-button {
    padding: 0 !important
}

.uk-drop .uk-overflow-auto {
    height: 100%
}

.uk-drop .uk-list {
    padding-left: 1.25em
}

.uk-drop .uk-list > ::before {
    content: '';
    position: relative;
    left: -1.25em;
    width: 1.25em;
    display: list-item;
    text-align: right;
    padding-top: 2px
}

.uk-drop .uk-list p {
    margin-bottom: .5em
}

.uk-drop .uk-list a, .uk-drop .uk-list p {
    display: block;
    color: #212022;
    font-size: .85em;
    padding: .25em 0;
    line-height: 1.3
}

.uk-drop .uk-list a small, .uk-drop .uk-list p small {
    display: block;
    font-size: .85em
}

.uk-drop .uk-list a:hover, .uk-drop .uk-list p:hover {
    color: #0066b3
}

.uk-drop .uk-tab {
    margin-bottom: 2em
}

.uk-drop .uk-tab > * > a {
    padding: 0 .5em .35em .5em
}

@media (min-width: 540px) and (max-width: 959px) {
    .uk-drop .uk-switcher {
        font-size: 1.2em
    }
}

@media (max-width: 539px) {
    .uk-drop .uk-modal-body {
        padding: 1em
    }

    .uk-drop .item-product {
        font-size: .9em
    }

    .uk-drop .uk-tab > * > a {
        font-size: .8em
    }
}

@media (min-width: 540px) {
    .uk-drop .uk-tab > * > a {
        font-size: 1em
    }
}

.block-banners {
    justify-content: center
}

.block-banners a {
    display: block;
    position: relative
}

.block-banners img {
    width: 100%;
    height: auto
}

.block-banners .uk-overlay {
    padding: 1em 6em 1em 1.5em
}

.block-banners .block-banners-main {
    width: 800px
}

@media (max-width: 1339px) {
    .block-banners .block-banners-main {
        padding-bottom: 10px
    }

    .block-banners .block-banners-sub {
        width: 840px;
        justify-content: center
    }

    .block-banners .block-banners-sub > div {
        width: 420px;
        padding: 10px
    }
}

@media (max-width: 959px) {
    .block-banners .block-banners-sub {
        width: 420px
    }
}

@media (max-width: 959px) {
    .block-banners .uk-dotnav {
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
        margin: 0 0 1em 0 !important
    }
}

@media (min-width: 1340px) {
    .block-banners .block-banners-sub {
        width: 420px;
        padding-left: 20px
    }

    .block-banners .block-banners-sub > div:nth-of-type(2n-1) {
        padding-bottom: 20px
    }
}

.block-banners .uk-dotnav {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 1em;
    padding: .5em .75em .5em 0
}

@media (max-width: 959px) {
    .block-banners-main {
        width: 350px !important
    }
}

.block-tabs {
    border-radius: 2em
}

.block-tabs .uk-slider-items p {
    display: flex;
    align-items: flex-end
}

.block-tabs .uk-slider-items p > strong {
    font-size: 3em;
    color: #00b2e3;
    font-weight: 800;
    line-height: 1
}

.block-tabs .uk-slider-items p span {
    display: inline-block;
    font-size: .9em;
    padding-left: 1em
}

.block-tabs .uk-dotnav > * > * {
    background: rgba(0, 178, 227, 0.5)
}

.block-tabs .uk-dotnav > .uk-active > * {
    background-color: #00b2e3
}

.block-tabs img {
    width: 100%;
    height: auto
}

@media (max-width: 959px) {
    .block-tabs img {
        max-width: 30em;
        margin-top: 2em
    }
}

.slider-categories .uk-slider-items li {
    padding: .5em
}

.slider-categories .uk-slider-items li:not(.uk-active) {
    opacity: .5
}

@media (max-width: 539px) {
    .slider-categories .uk-slider-items li {
        width: 40%
    }
}

@media (min-width: 540px) and (max-width: 959px) {
    .slider-categories .uk-slider-items li {
        width: 30%
    }
}

@media (min-width: 960px) and (max-width: 1199px) {
    .slider-categories .uk-slider-items li {
        width: 20%
    }
}

@media (min-width: 1200px) {
    .slider-categories .uk-slider-items li {
        width: 14.2857%
    }
}

.slider-categories [class*='uk-position-center-left'], .slider-categories [class*='uk-position-center-right'] {
    margin-top: -1.5em
}

.item-category {
    position: relative;
    display: block;
    text-align: center;
    font-size: .9em
}

.item-category .item-category-image {
    height: 0;
    padding-bottom: 60%;
    position: relative;
    z-index: 0;
    background-size: auto 75%;
    background-position: center;
    background-repeat: no-repeat
}

.item-category .item-category-title {
    color: #0066b3;
    font-size: .9em;
    font-weight: 500;
    line-height: 1.3
}

.btn-favorite {
    position: absolute;
    top: .5em;
    right: .5em;
    z-index: 100;
    width: 2em !important;
    height: 2em;
    line-height: 2em;
    text-align: center;
    font-size: .9em;
    color: #00b2e3 !important;
    background-color: white;
    border-radius: 50%
}

.btn-favorite:hover {
    color: white !important;
    background-color: #00b2e3 !important
}

.slider-products .uk-slider-items li {
    padding: .5em
}

.slider-products .uk-slider-items li:not(.uk-active) {
    opacity: .5
}

@media (max-width: 539px) {
    .slider-products .uk-slider-items li {
        width: 65%
    }
}

@media (min-width: 540px) and (max-width: 767px) {
    .slider-products .uk-slider-items li {
        width: 40%
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .slider-products .uk-slider-items li {
        width: 30%
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .slider-products .uk-slider-items li {
        width: 20%
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .slider-products .uk-slider-items li {
        width: 16.66%
    }
}

@media (min-width: 1600px) {
    .slider-products .uk-slider-items li {
        width: 12.5%
    }
}

.sponsored-products {
    background-color: #ffe64c
}

.sponsored-products .uk-h5 {
    padding-left: .5em;
    margin-bottom: .25em
}

.slider-sponsored-products .uk-slider-items li, .slider-sponsored-products .uk-slider-items > div {
    padding: .5em
}

@media (max-width: 767px) {
    .slider-sponsored-products .uk-slider-items li, .slider-sponsored-products .uk-slider-items > div {
        width: 100%
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .slider-sponsored-products .uk-slider-items li, .slider-sponsored-products .uk-slider-items > div {
        width: 50%
    }
}

@media (min-width: 1200px) {
    .slider-sponsored-products .uk-slider-items li, .slider-sponsored-products .uk-slider-items > div {
        width: 33.33%
    }
}

.slider-sponsored-products .uk-slider-items li img, .slider-sponsored-products .uk-slider-items > div img {
    width: 100%
}

.slider-sponsored-products .item-product .item-product-info, .slider-sponsored-products .item-product .item-product-add, .slider-sponsored-products .item-product .item-product-store, .slider-sponsored-products .item-product .item-product-location {
    width: 60%
}

.slider-sponsored-products .item-product .item-product-info {
    height: 100%
}

.slider-sponsored-products .item-product .item-product-image {
    width: 40%;
    height: 100%;
    border-bottom: 0;
    background-size: 6.5em;
    border-right: 1px solid rgba(33, 32, 34, 0.1)
}

.slider-sponsored-products .btn-favorite {
    right: calc(60% + 0.5em)
}

.slider-sponsored-banners .uk-slider-items li, .slider-sponsored-banners .uk-slider-items > div {
    padding: .5em;
    display: flex;
    justify-content: center
}

@media (max-width: 1199px) {
    .slider-sponsored-banners .uk-slider-items li, .slider-sponsored-banners .uk-slider-items > div {
        width: 100%
    }
}

@media (min-width: 1200px) {
    .slider-sponsored-banners .uk-slider-items li, .slider-sponsored-banners .uk-slider-items > div {
        width: 50%
    }
}

.slider-sponsored-banners .uk-slider-items li img, .slider-sponsored-banners .uk-slider-items > div img {
    width: 100%
}

@media (max-width: 539px) {
    .grid-products > div {
        width: 50%
    }
}

@media (min-width: 540px) and (max-width: 959px) {
    .grid-products > div {
        width: 33.33%
    }
}

@media (min-width: 960px) and (max-width: 1199px) {
    .grid-products > div {
        width: 25%
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .grid-products > div {
        width: 20%
    }
}

@media (min-width: 1600px) {
    .grid-products > div {
        width: 16.66%
    }
}

.grid-offers .item-product .item-product-cat, .grid-offers .item-product .item-product-date {
    display: block
}

.item-product.item-image-fill .item-product-image {
    background-size: cover
}

.item-product-highlight {
    position: relative;
    height: 100%;
    display: flex
}

.item-product-highlight .item-product-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.item-empty {
    position: relative;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center
}

.item-product, .item-product-detail, .item-product-modal {
    position: relative
}

.item-product .item-product-image, .item-product .item-product-info, .item-product-detail .item-product-image, .item-product-detail .item-product-info, .item-product-modal .item-product-image, .item-product-modal .item-product-info {
    position: relative;
    z-index: 0
}

.item-product .item-product-image, .item-product .item-product-info, .item-product .item-product-add, .item-product .item-product-store, .item-product .item-product-location, .item-product-detail .item-product-image, .item-product-detail .item-product-info, .item-product-detail .item-product-add, .item-product-detail .item-product-store, .item-product-detail .item-product-location, .item-product-modal .item-product-image, .item-product-modal .item-product-info, .item-product-modal .item-product-add, .item-product-modal .item-product-store, .item-product-modal .item-product-location {
    width: 100%
}

.item-product .item-product-image, .item-product-detail .item-product-image, .item-product-modal .item-product-image {
    background-position: center;
    background-repeat: no-repeat
}

.item-product .item-product-info, .item-product-detail .item-product-info, .item-product-modal .item-product-info {
    line-height: 1.3
}

.item-product .item-product-info .item-product-title, .item-product-detail .item-product-info .item-product-title, .item-product-modal .item-product-info .item-product-title {
    text-transform: uppercase;
    font-size: .65em;
    margin-bottom: .5em;
    line-height: 1.1
}

.item-product .item-product-info .item-product-title a, .item-product-detail .item-product-info .item-product-title a, .item-product-modal .item-product-info .item-product-title a {
    color: #212022
}

.item-product .item-product-info .item-product-title a:hover, .item-product-detail .item-product-info .item-product-title a:hover, .item-product-modal .item-product-info .item-product-title a:hover {
    color: #00b2e3
}

.item-product .item-product-info .item-product-title a, .item-product .item-product-info .item-product-title strong, .item-product-detail .item-product-info .item-product-title a, .item-product-detail .item-product-info .item-product-title strong, .item-product-modal .item-product-info .item-product-title a, .item-product-modal .item-product-info .item-product-title strong {
    display: block
}

.item-product .item-product-info .item-product-price, .item-product-detail .item-product-info .item-product-price, .item-product-modal .item-product-info .item-product-price {
    margin: 0
}

.item-product .item-product-info .item-product-price small, .item-product-detail .item-product-info .item-product-price small, .item-product-modal .item-product-info .item-product-price small {
    display: block;
    text-decoration: line-through
}

.item-product .item-product-info .item-product-price strong, .item-product-detail .item-product-info .item-product-price strong, .item-product-modal .item-product-info .item-product-price strong {
    font-size: 1.25em;
    font-weight: 800
}

.item-product .item-product-info .item-product-discount, .item-product .item-product-info .item-product-special, .item-product-detail .item-product-info .item-product-discount, .item-product-detail .item-product-info .item-product-special, .item-product-modal .item-product-info .item-product-discount, .item-product-modal .item-product-info .item-product-special {
    line-height: 1;
    margin: 0
}

.item-product .item-product-info .item-product-discount strong, .item-product .item-product-info .item-product-special strong, .item-product-detail .item-product-info .item-product-discount strong, .item-product-detail .item-product-info .item-product-special strong, .item-product-modal .item-product-info .item-product-discount strong, .item-product-modal .item-product-info .item-product-special strong {
    font-size: 2em;
    font-weight: 800
}

.item-product .item-product-info .item-product-discount small, .item-product .item-product-info .item-product-special small, .item-product-detail .item-product-info .item-product-discount small, .item-product-detail .item-product-info .item-product-special small, .item-product-modal .item-product-info .item-product-discount small, .item-product-modal .item-product-info .item-product-special small {
    display: block;
    text-transform: uppercase;
    font-size: .6em;
    font-weight: 600
}

.item-product .item-product-info .item-product-cat, .item-product-detail .item-product-info .item-product-cat, .item-product-modal .item-product-info .item-product-cat {
    font-size: .65em
}

.item-product .item-product-info .item-product-cat a, .item-product-detail .item-product-info .item-product-cat a, .item-product-modal .item-product-info .item-product-cat a {
    display: flex;
    align-items: center;
    color: #212022
}

.item-product .item-product-info .item-product-cat i, .item-product-detail .item-product-info .item-product-cat i, .item-product-modal .item-product-info .item-product-cat i {
    color: #00b2e3
}

.item-product .item-product-info .item-product-date, .item-product-detail .item-product-info .item-product-date, .item-product-modal .item-product-info .item-product-date {
    font-size: .65em;
    border-top: 1px solid rgba(33, 32, 34, 0.2);
    padding-top: .75em;
    margin-top: 1.25em;
    color: rgba(33, 32, 34, 0.65)
}

.item-product .item-product-cat, .item-product .item-product-date, .item-product-detail .item-product-cat, .item-product-detail .item-product-date, .item-product-modal .item-product-cat, .item-product-modal .item-product-date {
    display: none
}

.item-product .item-product-add, .item-product-detail .item-product-add, .item-product-modal .item-product-add {
    color: white;
    background-color: #00b2e3;
    line-height: 1
}

.item-product .item-product-add .item-product-quantity, .item-product-detail .item-product-add .item-product-quantity, .item-product-modal .item-product-add .item-product-quantity {
    height: 100%
}

.item-product .item-product-add .item-product-quantity span, .item-product-detail .item-product-add .item-product-quantity span, .item-product-modal .item-product-add .item-product-quantity span {
    display: inline-block;
    padding: 0 .5em;
    flex: 1;
    min-width: 1px;
    text-align: center
}

.item-product .item-product-add .item-product-quantity i, .item-product-detail .item-product-add .item-product-quantity i, .item-product-modal .item-product-add .item-product-quantity i {
    font-size: .8em
}

.item-product .item-product-add .item-product-quantity .uk-button, .item-product-detail .item-product-add .item-product-quantity .uk-button, .item-product-modal .item-product-add .item-product-quantity .uk-button {
    padding: 0 1.5em !important;
    width: auto;
    height: 100%;
    border-radius: 0
}

.item-product .item-product-add .item-product-quantity-select, .item-product .item-product-add .item-product-quantity-buttons, .item-product-detail .item-product-add .item-product-quantity-select, .item-product-detail .item-product-add .item-product-quantity-buttons, .item-product-modal .item-product-add .item-product-quantity-select, .item-product-modal .item-product-add .item-product-quantity-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%
}

.item-product .item-product-add .uk-select, .item-product-detail .item-product-add .uk-select, .item-product-modal .item-product-add .uk-select {
    color: white;
    background-color: #00b2e3;
    height: 100%;
    line-height: 1;
    border-radius: 0;
    border-color: transparent;
    background-image: none !important
}

.item-product .item-product-add .item-product-btn, .item-product-detail .item-product-add .item-product-btn, .item-product-modal .item-product-add .item-product-btn {
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    position: relative
}

.item-product .item-product-add .item-product-btn .uk-button, .item-product-detail .item-product-add .item-product-btn .uk-button, .item-product-modal .item-product-add .item-product-btn .uk-button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0
}

.item-product:not(.item-in-cart) .item-product-quantity-buttons, .item-product-detail:not(.item-in-cart) .item-product-quantity-buttons, .item-product-modal:not(.item-in-cart) .item-product-quantity-buttons {
    display: none
}

.item-product.item-in-cart .item-product-quantity, .item-product-detail.item-in-cart .item-product-quantity, .item-product-modal.item-in-cart .item-product-quantity {
    width: 100% !important
}

.item-product.item-in-cart .item-product-quantity-select, .item-product-detail.item-in-cart .item-product-quantity-select, .item-product-modal.item-in-cart .item-product-quantity-select {
    display: none
}

.item-product.item-in-cart .item-product-btn, .item-product-detail.item-in-cart .item-product-btn, .item-product-modal.item-in-cart .item-product-btn {
    display: none
}

.item-product.item-store-only .item-product-add, .item-product-detail.item-store-only .item-product-add, .item-product-modal.item-store-only .item-product-add {
    display: none
}

.item-product:not(.item-store-only) .item-product-store, .item-product-detail:not(.item-store-only) .item-product-store, .item-product-modal:not(.item-store-only) .item-product-store {
    display: none
}

.item-product.item-not-in-location .item-product-add, .item-product-detail.item-not-in-location .item-product-add, .item-product-modal.item-not-in-location .item-product-add {
    display: none
}

.item-product:not(.item-not-in-location) .item-product-location, .item-product-detail:not(.item-not-in-location) .item-product-location, .item-product-modal:not(.item-not-in-location) .item-product-location {
    display: none
}

.item-product:not(.item-offer) .item-product-price small, .item-product-detail:not(.item-offer) .item-product-price small, .item-product-modal:not(.item-offer) .item-product-price small {
    display: none
}

.item-product:not(.item-offer-discount) .item-product-discount, .item-product-detail:not(.item-offer-discount) .item-product-discount, .item-product-modal:not(.item-offer-discount) .item-product-discount {
    display: none
}

.item-product.item-offer-discount .item-product-price, .item-product-detail.item-offer-discount .item-product-price, .item-product-modal.item-offer-discount .item-product-price {
    display: none
}

.item-product:not(.item-offer-special) .item-product-special, .item-product-detail:not(.item-offer-special) .item-product-special, .item-product-modal:not(.item-offer-special) .item-product-special {
    display: none
}

.item-product.item-offer-special .item-product-price, .item-product-detail.item-offer-special .item-product-price, .item-product-modal.item-offer-special .item-product-price {
    display: none
}

.item-product.item-unavailable .item-product-add, .item-unavailable #btn-det-add-to-cart, .item-product-detail.item-unavailable .item-product-add, .item-product-modal.item-unavailable .item-product-add {
    pointer-events: none;
    background-color: #c7c5c8
}



.item-product.item-unavailable .item-product-add .uk-button, .item-product.item-unavailable .item-product-add .uk-select, .item-product-detail.item-unavailable .item-product-add .uk-button, .item-product-detail.item-unavailable .item-product-add .uk-select, .item-product-modal.item-unavailable .item-product-add .uk-button, .item-product-modal.item-unavailable .item-product-add .uk-select {
    background-color: #c7c5c8
}

.item-product.item-offer:before, .item-product.item-unavailable:before, .item-product-detail.item-offer:before, .item-product-detail.item-unavailable:before, .item-product-modal.item-offer:before, .item-product-modal.item-unavailable:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 600;
    padding: .25em .75em;
    border-radius: 0 0 .25em 0
}

.item-product.item-offer:before, .item-product-detail.item-offer:before, .item-product-modal.item-offer:before {
    content: 'Oferta';
    color: #ffe64c;
    background-color: #0066b3
}

.item-product.item-unavailable:before, .item-product-detail.item-unavailable:before, .item-product-modal.item-unavailable:before {
    content:'Agotado';
    color: #212022;
    background-color: rgba(33, 32, 34, 0.2)
}

@media (max-width: 539px) {
    .item-product, .item-product-detail, .item-product-modal {
        font-size: 1.15em
    }
}

.uk-background-tertiary .slider-arrow {
    color: white !important
}

.uk-background-tertiary .item-product {
    border-color: #0066b3
}

.uk-background-tertiary .item-product:hover {
    border-color: #ffe64c
}

.item-product {
    background-color: white;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    border: 1px solid rgba(33, 32, 34, 0.1);
    -webkit-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1)
}

.item-product:hover {
    border-color: #00b2e3
}

.item-product .item-product-image {
    height: 9em;
    border-bottom: 1px solid rgba(33, 32, 34, 0.1);
    background-size: 7.5em
}

.item-product .item-product-info {
    padding: .75em .8em 2.75em .8em;
    height: calc(100% - 9em);
    display: flex;
    flex-flow: row wrap;
    align-content: space-between
}

.item-product .item-product-info > * {
    width: 100%
}

.item-product .item-product-add .item-product-quantity, .item-product .item-product-add .item-product-btn {
    width: 50%
}

.item-product .item-product-add .btn-add-cart span {
    display: none
}

.item-product .item-product-add, .item-product .item-product-store, .item-product .item-product-location {
    display: flex;
    align-items: center;
    font-size: .9em;
    height: 2.15em
}

.item-product .item-product-store, .item-product .item-product-location {
    padding: 0 .5em;
    background-color: #ffc844
}

.item-product .item-product-store p, .item-product .item-product-location p {
    font-size: .8em;
    margin: 0
}

.item-product .item-product-add, .item-product .item-product-store, .item-product .item-product-location {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100
}

.item-product-detail .btn-favorite {
    color: #212022 !important;
    background-color: transparent
}

.item-product-detail .btn-favorite:hover {
    color: white !important;
    background-color: #00b2e3
}

.item-product-detail .title-border {
    padding-right: 3em
}

.item-product-detail .item-product-image {
    background-size: 90%;
    height: 0;
    padding-bottom: 80%
}

.item-product-detail .item-product-price, .item-product-detail .item-product-discount, .item-product-detail .item-product-special {
    font-size: 1.5em
}

.item-product-detail .item-product-add {
    margin-top: 2em;
    max-width: 15em;
    display: flex;
    align-items: center;
    height: 2.5em
}

.item-product-detail .item-product-add .item-product-quantity {
    width: 35%
}

.item-product-detail .item-product-add .item-product-btn {
    width: 65%
}

.item-product-detail .item-product-store, .item-product-detail .item-product-location {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.item-product-detail .item-product-store p:first-child, .item-product-detail .item-product-location p:first-child {
    font-size: 1.15em;
    font-weight: 700
}

.item-product-detail .item-product-store p:first-child i, .item-product-detail .item-product-location p:first-child i {
    margin-right: .5em
}

.item-product-detail.item-offer:before, .item-product-detail.item-unavailable:before {
    font-size: .8em;
    border-radius: .25em
}

@media (max-width: 959px) {
    .item-product-detail.item-offer:before, .item-product-detail.item-unavailable:before {
        left: 3em
    }
}

@media (min-width: 960px) {
    .item-product-detail .btn-favorite {
        top: -.25em
    }

    .item-product-detail.item-offer .title-border, .item-product-detail.item-unavailable .title-border {
        padding-top: 3em
    }
}

@media (min-width: 960px) and (max-width: 1199px) {
    .item-product-detail.item-offer:before, .item-product-detail.item-unavailable:before {
        left: calc(40% + 3em)
    }
}

@media (min-width: 1200px) {
    .item-product-detail.item-offer:before, .item-product-detail.item-unavailable:before {
        left: calc(40% + 5.5em)
    }
}

.item-product-modal .item-product-image {
    height: 12em;
    background-size: contain
}

.item-product-modal .item-product-info {
    height: calc(100% - 12em)
}

.item-product-modal .item-product-info .item-product-title {
    font-size: 1.25em;
    font-weight: 800;
    text-transform: none;
    margin-top: .5em
}

.item-product-modal .item-product-add {
    margin-top: 1em;
    height: 2.5em;
    max-width: 10em;
    margin-left: auto;
    margin-right: auto
}

.item-product-modal .item-product-quantity-buttons {
    display: flex !important
}

.nav-filters .uk-button {
    padding-left: 1em;
    padding-right: 1em
}

.nav-filters .uk-dropdown {
    width: calc(100% - 2em)
}

.nav-filters .uk-dropdown .uk-button:hover {
    color: white;
    background-color: #00b2e3
}

@media (min-width: 600px) {
    .nav-filters > .uk-width-1-1:first-child {
        flex: 1;
        min-width: 1px
    }
}

.subcat-nav {
    padding: 0 2em
}

.subcat-nav a {
    display: inline-block;
    padding: .5em 1em;
    font-size: .9em
}

.subcat-nav a:not(:hover) {
    color: #212022
}

.subcat-nav a.uk-active {
    color: #212022;
    background-color: rgba(33, 32, 34, 0.1)
}

.subcat-nav .slider-arrow {
    color: #212022;
    padding: .25em .5em;
    font-size: 1em
}

.subcat-nav .slider-arrow.uk-position-center-left-out {
    right: calc(100% + 0.5em)
}

.subcat-nav .slider-arrow.uk-position-center-right-out {
    left: calc(100% + 0.5em)
}

.subcat-nav .slider-arrow.uk-invisible {
    visibility: visible !important;
    opacity: .25;
    pointer-events: none
}

.slider-recipes .uk-slider-items li {
    padding: .5em
}

.slider-recipes .uk-slider-items li:not(.uk-active) {
    opacity: .5
}

@media (max-width: 539px) {
    .slider-recipes .uk-slider-items li {
        width: 80%
    }
}

@media (min-width: 540px) and (max-width: 767px) {
    .slider-recipes .uk-slider-items li {
        width: 40%
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .slider-recipes .uk-slider-items li {
        width: 30%
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .slider-recipes .uk-slider-items li {
        width: 25%
    }
}

@media (min-width: 1600px) {
    .slider-recipes .uk-slider-items li {
        width: 20%
    }
}

@media (max-width: 539px) {
    .grid-recipes > div {
        width: 100%
    }
}

@media (min-width: 540px) and (max-width: 767px) {
    .grid-recipes > div {
        width: 50%
    }
}

@media (min-width: 768px) and (max-width: 1439px) {
    .grid-recipes > div {
        width: 33.33%
    }
}

@media (min-width: 1440px) {
    .grid-recipes > div {
        width: 25%
    }
}

.item-recipe {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1)
}

.item-recipe:hover .item-recipe-info {
    background-color: #00b2e3
}

.item-recipe a {
    display: block
}

.item-recipe .btn-favorite {
    color: white !important;
    background-color: #0066b3
}

.item-recipe .item-recipe-image {
    height: 0;
    padding-bottom: 60%;
    position: relative;
    z-index: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.item-recipe .item-recipe-info {
    line-height: 1.3;
    padding: .75em 1em;
    background-color: #0066b3
}

.item-recipe .item-recipe-info .item-recipe-title {
    font-size: .9em;
    font-weight: 500
}

.item-recipe .item-recipe-info .item-recipe-title a {
    color: white
}

.recipe-detail {
    position: relative;
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.recipe-detail .uk-width-auto {
    font-size: .8em
}

.recipe-detail .uk-width-auto i {
    margin-right: .5em
}

.recipe-detail .btn-favorite {
    top: -.25em;
    color: #212022 !important;
    background-color: transparent
}

.recipe-detail .btn-favorite:hover {
    color: white !important;
    background-color: #00b2e3
}

.recipe-content li > a {
    font-weight: 600;
    color: #212022
}

.recipe-content li > a:hover {
    text-decoration: underline
}

.recipe-content hr {
    margin: 2em 0
}

.recipe-content .uk-list-decimal li {
    margin: 1em 0
}

.recipe-tooltip {
    width: 16em;
    padding: 0;
    overflow: hidden
}

.recipe-tooltip .item-product:hover {
    -webkit-box-shadow: 0 0 0.5em 0 transparent;
    -moz-box-shadow: 0 0 0.5em 0 transparent;
    -ms-box-shadow: 0 0 0.5em 0 transparent;
    -o-box-shadow: 0 0 0.5em 0 transparent;
    box-shadow: 0 0 0.5em 0 transparent
}

.recipe-tooltip .item-product .item-product-info, .recipe-tooltip .item-product .item-product-add, .recipe-tooltip .item-product .item-product-store {
    width: 60%
}

.recipe-tooltip .item-product .item-product-image {
    width: 40%;
    display: block;
    padding-bottom: 0;
    height: 100%;
    border-bottom: 0;
    border-right: 1px solid rgba(33, 32, 34, 0.1)
}

.recipe-tooltip .btn-favorite {
    right: calc(60% + 0.5em)
}

.gmap {
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 0;
    position: absolute !important;
    top: 0;
    left: 0
}

.gmap p {
    line-height: 1.2;
    margin: 5px;
    max-width: 20em
}

.gmap p strong {
    font-size: 1.1em;
    display: block;
    padding-bottom: .2em;
    text-transform: uppercase
}

.gmap p span {
    display: flex;
    margin: .2em 0
}

.gmap p span > i {
    padding: .1em .5em 0 0;
    color: #00b2e3
}

.gmap .uk-button {
    margin: 0 .5em .5em 0
}

.gmap .uk-button i {
    margin-left: .5em
}

.store-info {
    font-size: .9em;
    background-color: rgba(33, 32, 34, 0.1)
}

.store-info p i {
    color: #0066b3;
    margin-right: .5em;
    padding-top: .2em
}

.item-list-wrap .uk-card {
    padding: 1em
}

.item-list-wrap .uk-card:not(:last-child) {
    margin-bottom: 2em
}

@media (min-width: 960px) {
    .item-list-wrap .uk-card {
        padding: 2em
    }
}

.item-list-wrap .uk-alert {
    margin-bottom: 2em
}

.item-list-wrap table td {
    font-size: .85em
}

@media (max-width: 539px) {
    .item-list-wrap table .item-list .item-list-info {
        padding: .5em 0
    }
}

.item-list {
    position: relative;
    background-color: white;
    display: flex;
    flex-flow: row wrap
}

.item-list .item-list-image {
    width: 5em;
    height: 5em;
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat
}

.item-list .item-list-info {
    padding: .5em 1em
}

.item-list .item-list-info .uk-h5 {
    font-weight: 500
}

.item-list .item-list-info .uk-h5 a {
    color: #212022
}

.item-list .item-list-detail p {
    margin-bottom: .5em
}

.item-list .item-list-btns {
    padding-left: 1em
}

.item-list .item-list-btns .uk-button {
    padding-left: 1em;
    padding-right: 1em
}

.item-list .uk-select {
    max-width: 6em
}

@media (max-width: 539px) {
    .item-list .item-list-image {
        width: 3em;
        height: 3em
    }
}

.item-list-footer {
    position: -webkit-sticky;
    position: sticky;
    top: 0
}

.item-list-footer .item-list-btns {
    border-top: 1px solid rgba(33, 32, 34, 0.2);
    padding-top: 1em;
    margin-top: 1.5em
}

.item-list-footer .item-list-total {
    font-size: .8em
}

.item-list-footer .item-list-total .uk-text-bolder {
    font-size: 1.1em
}

.item-list-footer .item-list-total .uk-text-secondary {
    color: #0066b3 !important
}

@media (max-width: 959px) {
    .item-list-footer {
        font-size: 1.2em
    }
}

.item-list-block {
    font-size: .8em
}

.item-list-block p {
    margin: .25em 0
}

.item-list-cupon .uk-flex {
    max-width: 20em
}

.item-list-cupon .uk-flex .uk-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0
}

.item-list-cupon .uk-flex .uk-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

#modal-cart, .modal-cart {
    display: none
}

#modal-cart .uk-offcanvas-close, .modal-cart .uk-offcanvas-close {
    top: 1em;
    right: 1em
}

#modal-cart .uk-offcanvas-bar, .modal-cart .uk-offcanvas-bar {
    width: 100%
}

@media (orientation: landscape) and (min-width: 568px) {
    #modal-cart .uk-offcanvas-bar, .modal-cart .uk-offcanvas-bar {
        max-width: 24em !important;
    }
}

.cart-list-header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.cart-list-items {
    position: relative;
    z-index: 0;
    padding-top: 3.6em
}

.cart-list-item {
    position: relative;
    padding: .5em 1em
}

.cart-list-item:not(:first-child) {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.cart-list-item:hover .cart-list-info {
    color: #00b2e3
}

.cart-list-close {
    padding: 1em;
    height: 100%;
    color: #00b2e3
}

.cart-list-close:hover {
    color: #ff671d
}

.cart-list-image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat
}

.cart-list-info {
    padding: .5em 2em .5em 1em;
    color: #212022;
    line-height: 1.2
}

.cart-list-info p {
    margin: 0
}

.cart-list-info p:not(.uk-text-small) {
    font-size: .75em;
    font-weight: 500
}

.cart-list-footer {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

.cart-list-footer .uk-alert {
    margin-bottom: 0
}

.cart-list-footer .uk-flex {
    padding: .1em 1.25em;
    font-size: .9em
}

.cart-list-footer .uk-width-1-3 {
    font-size: .9em
}

.cart-list-footer .uk-width-1-1:last-child {
    padding-top: .5em
}

.cart-list-footer .uk-button {
    border-radius: 0
}

.cart-list-footer .uk-button i {
    margin-left: 1em
}

.sidebar {
    padding-bottom: 2em
}

.sidebar.uk-offcanvas-bar {
    padding: .5em 1.5em 2em 1.5em !important;
    width: 100%;
    font-size: 1.35em
}

@media (orientation: landscape) and (min-width: 568px) {
    .sidebar.uk-offcanvas-bar {
        max-width: 24em
    }
}

.sidebar.uk-offcanvas-bar .uk-nav {
    font-size: .95em
}

.sidebar.uk-offcanvas-bar .uk-nav > li {
    border-bottom: 1px solid rgba(33, 32, 34, 0.1)
}

.sidebar.uk-offcanvas-bar .uk-nav > li > a {
    padding: .5em 0
}

.sidebar.uk-offcanvas-bar .uk-nav > li.uk-open > a {
    font-weight: 600
}

.sidebar.uk-offcanvas-bar .uk-nav a {
    color: #212022;
    letter-spacing: -.02em
}

.sidebar.uk-offcanvas-bar .uk-nav .uk-nav-sub {
    padding: 0 0 .5em 1em
}

.sidebar.uk-offcanvas-bar .uk-nav .uk-nav-sub a {
    padding: .25em 0;
    position: relative
}

.sidebar.uk-offcanvas-bar .uk-nav .uk-nav-sub a:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -1em;
    width: .4em;
    height: 1px;
    background-color: rgba(33, 32, 34, 0.5)
}

.sidebar .title-border {
    margin-top: 1.75em;
    margin-bottom: 0;
    border-bottom: 0;
    padding-left: .75em
}

.sidebar .uk-h5 {
    text-transform: uppercase
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav {
    font-size: .85em
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li {
    position: relative
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li:before {
    content: '\f105';
    position: absolute;
    top: 50%;
    right: .5em;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li:hover {
    background-color: white
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li:hover:before {
    opacity: 1
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li > a {
    color: #212022;
    letter-spacing: -.02em;
    padding: .5em 1.25em .5em 1em
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li.uk-active:before {
    opacity: 1
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li.uk-active > a {
    font-weight: 600;
    cursor: default
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li .uk-dropdown {
    top: 0 !important;
    left: 100% !important;
    margin: 0 !important;
    padding: 0;
    min-width: 100%
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li .uk-dropdown .uk-nav {
    font-size: 1em
}

.sidebar:not(.uk-offcanvas-bar) .uk-nav > li .uk-dropdown .uk-nav > li:hover {
    background-color: #ffc844
}

.sidebar .uk-list {
    font-size: .85em
}

.sidebar .uk-list label {
    display: block
}

.sidebar .uk-list label:hover {
    cursor: pointer
}

.sidebar .uk-list li.uk-active a {
    font-weight: 600;
    cursor: default
}

.sidebar .uk-list li.uk-active a:before {
    opacity: 1
}

.sidebar .uk-list li a {
    display: block;
    color: #212022;
    position: relative;
    letter-spacing: -.02em;
    padding-right: .75em
}

.sidebar .uk-list li a:before {
    content: '\f105';
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0
}

.sidebar .uk-list li a:hover {
    font-weight: 600
}

.sidebar .uk-list li a:hover:before {
    opacity: 1
}

.sidebar .uk-list li i {
    width: 1.75em;
    text-align: center
}

.sidebar .uk-form-icon {
    width: 2em;
    text-align: cemter
}

.sidebar .uk-form-icon ~ .uk-input {
    padding-left: 2em !important
}

.sidebar .uk-input {
    background-color: rgba(255, 255, 255, 0.4)
}

@media (min-width: 960px) and (max-width: 1364px) {
    .sidebar .uk-list {
        font-size: .8em
    }
}

@media (max-width: 959px) {
    .sidebar.uk-visible\@m {
        display: none !important
    }
}

.slider-catalog .uk-slider-items {
    height: 70vh
}

.slider-catalog .uk-slider-items img {
    width: auto;
    max-height: 70vh;
    z-index: 0
}

.slider-catalog .uk-slider-items li {
    max-width: 100%;
    position: relative
}

.slider-catalog .uk-slider-items li:not(.uk-active) {
    opacity: .5
}

.modal-catalog .uk-modal-dialog, .modal-catalog .uk-modal-header, .modal-catalog .uk-modal-footer, .modal-zoom .uk-modal-dialog, .modal-zoom .uk-modal-header, .modal-zoom .uk-modal-footer {
    background: #ffe64c
}

.modal-catalog .uk-modal-header, .modal-zoom .uk-modal-header {
    border-bottom: 1px solid rgba(33, 32, 34, 0.2)
}

.modal-catalog .uk-modal-header .uk-button, .modal-zoom .uk-modal-header .uk-button {
    padding-left: 1em;
    padding-right: 1em
}

.modal-catalog .uk-modal-footer, .modal-zoom .uk-modal-footer {
    border-top: 1px solid rgba(33, 32, 34, 0.2)
}

@media (max-width: 539px) {
    .grid-catalog > div {
        width: 80%
    }
}

@media (min-width: 540px) and (max-width: 959px) {
    .grid-catalog > div {
        width: 50%
    }
}

@media (min-width: 960px) and (max-width: 1199px) {
    .grid-catalog > div {
        width: 33.33%
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .grid-catalog > div {
        width: 25%
    }
}

@media (min-width: 1440px) {
    .grid-catalog > div {
        width: 20%
    }
}

.modal-zoom .zoom-parent {
    height: 80vh
}

.zoom-image {
    display: block;
    position: relative
}

.zoom-image:before {
    content: '\f065';
    display: inline-block;
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 100;
    width: 2em;
    height: 2em;
    color: white;
    background-color: rgba(33, 32, 34, 0.8);
    text-align: center;
    line-height: 2em;
    opacity: 0;
    -webkit-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1)
}

.zoom-image:hover:before {
    opacity: 1
}

#modal-hours label {
    display: block
}

#modal-hours .unavailable label, #modal-hours .unavailable input {
    pointer-events: none
}

#modal-hours .unavailable input {
    opacity: 0
}

#modal-hours .unavailable span {
    opacity: .5
}

#modal-hours .unavailable label:after {
    display: inline-block;
    font-size: .8em;
    font-weight: 700;
    padding-left: 1em
}

.card-block {
    padding: 1.75em
}

.card-block .uk-card-badge {
    color: white;
    background-color: #00b2e3;
    font-weight: 500;
    font-size: .7em;
    border-radius: 0 .35em;
    top: 0;
    right: 0
}

.credit_cards img {
    padding: .15em
}

.credit_cards img:not(.uk-active) {
    opacity: .1
}

.slider-products-fav .uk-slider-items li {
    padding: .5em
}

.slider-products-fav .uk-slider-items li:not(.uk-active) {
    opacity: .5
}

@media (max-width: 539px) {
    .slider-products-fav .uk-slider-items li {
        width: 65%
    }
}

@media (min-width: 540px) and (max-width: 767px) {
    .slider-products-fav .uk-slider-items li {
        width: 40%
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .slider-products-fav .uk-slider-items li {
        width: 30%
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .slider-products-fav .uk-slider-items li {
        width: 25%
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .slider-products-fav .uk-slider-items li {
        width: 20%
    }
}

@media (min-width: 1600px) {
    .slider-products-fav .uk-slider-items li {
        width: 16.66%
    }
}

.slider-recipes-fav .uk-slider-items li {
    padding: .5em
}

.slider-recipes-fav .uk-slider-items li:not(.uk-active) {
    opacity: .5
}

@media (max-width: 539px) {
    .slider-recipes-fav .uk-slider-items li {
        width: 80%
    }
}

@media (min-width: 540px) and (max-width: 1023px) {
    .slider-recipes-fav .uk-slider-items li {
        width: 40%
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .slider-recipes-fav .uk-slider-items li {
        width: 33.33%
    }
}

@media (min-width: 1440px) {
    .slider-recipes-fav .uk-slider-items li {
        width: 25%
    }
}

.hover-siremas {
    outline: none
}

.hover-siremas img {
    width: 4em;
    height: auto
}

@media (max-width: 959px) {
    .image-height {
        height: 40vh
    }
}

#main-header .logo-sirena-cash {
    display: inline-block;
    width: auto;
    height: 2em
}

@media (max-width: 440px) {
    #main-header > div:first-child > nav:has(> div:last-child ul > li:nth-child(3):last-child) {
        flex-direction: column;
        padding-bottom: 7px;
    }

    .top-sub-logged {
        position: fixed;
        width: 100%;
        top: 8.1em !important;
    }

    main#container {
        padding-top: 95px;
    }
}

@media screen and (max-width: 380px) {
    #main-header > div:first-child > nav {
        flex-direction: column;
        padding-bottom: 7px;
    }

    .top-sub {
        position: fixed;
        width: 100%;
        top: 8.1em !important;
    }
}

#int-header {
    position: relative;
    z-index: 1000;
    width: 100% !important;
    padding: .5em 0;
    line-height: 1
}

#int-header.uk-active.uk-sticky-fixed {
    top: 9.25em !important
}

@media (max-width: 539px) {
    #int-header.uk-active.uk-sticky-fixed {
        top: 8.7em !important
    }
}

#int-header .uk-icon {
    color: #0066b3
}

#int-header .uk-navbar-nav > li > a {
    color: #0066b3;
    text-transform: uppercase;
    font-weight: 800
}

#int-header .uk-navbar-nav > li.uk-active > a {
    color: white;
    background-color: #0066b3;
    border-radius: .25em
}

@media (max-width: 959px) {
    #int-header {
        position: absolute
    }

    #int-header .uk-navbar-right {
        justify-content: flex-end
    }
}

#int-mobile-nav .uk-offcanvas-bar {
    padding: 12em 1.5em 2em 1.5em !important
}

#int-mobile-nav .uk-close {
    top: 10em !important
}

#int-mobile-nav .uk-close svg {
    width: 1em;
    height: 1em;
    font-size: 1.25em;
    color: #212022
}

#int-mobile-nav .uk-nav > li > a {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.25em;
    color: #0066b3;
    padding: .5em 0
}

#int-mobile-nav .uk-nav > li.uk-active > a {
    color: #00b2e3 !important
}

.cont-sirena-cash {
    border-top: .5em solid white;
    position: relative;
    overflow: hidden
}

.cont-sirena-cash .logo-sirena-cash {
    display: inline-block;
    max-width: 18em
}

.cont-sirena-cash .uk-section {
    position: relative;
    z-index: 10
}

.cont-sirena-cash .uk-position-cover > div {
    height: 100%
}

.cont-sirena-cash .title-border {
    border-color: #ffe64c
}

.cont-sirena-cash .uk-text-bold, .cont-sirena-cash strong {
    font-weight: 800
}

.cont-sirena-cash .uk-border-rounded {
    border-radius: 1em;
    overflow: hidden
}

.cont-sirena-cash .icon-sirena-cash {
    display: block;
    width: 8em;
    height: 6em;
    line-height: 6em;
    text-align: center;
    margin: 0 auto 1em auto
}

.cont-sirena-cash .icon-sirena-cash img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%
}

.cont-sirena-cash .uk-card:not(.uk-card-default) a {
    color: #ffe64c
}

.cont-sirena-cash .uk-card:not(.uk-card-default) a:hover {
    text-decoration: underline
}

.cont-sirena-cash .uk-card:not(.uk-card-default) .uk-h4 {
    color: #0066b3;
    background-color: #ffe64c;
    text-transform: none;
    padding: .75em 1.75em;
    margin: 0
}

.cont-sirena-cash .uk-card:not(.uk-card-default) .uk-h5 {
    text-transform: uppercase;
    text-align: center
}

.cont-sirena-cash .uk-card:not(.uk-card-default) .uk-h5 span {
    display: inline-block;
    padding: .25em 1em;
    color: #0066b3;
    background-color: #ffe64c;
    border-radius: 1em
}

.cont-sirena-cash .uk-card:not(.uk-card-default) ul:not(.uk-list) {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: .8em
}

.cont-sirena-cash .uk-card:not(.uk-card-default) ul:not(.uk-list) li {
    text-transform: uppercase;
    font-weight: 700;
    color: #ffe64c;
    padding: .5em 0 .5em 2em;
    line-height: 1.2;
    position: relative
}

.cont-sirena-cash .uk-card:not(.uk-card-default) ul:not(.uk-list) li:before {
    content: '\f058';
    position: absolute;
    top: .6em;
    left: 0;
    color: white
}

.cont-sirena-cash .uk-card:not(.uk-card-default) .uk-list {
    font-weight: 500
}

.cont-sirena-cash .uk-card:not(.uk-card-default) .uk-card-title {
    color: #0066b3;
    background-color: #ffe64c;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.5em;
    padding: .5em 1em
}

@media (min-width: 1200px) {
    .cont-sirena-cash .uk-card:not(.uk-card-default) .uk-card-body {
        padding: 2em
    }
}

.cont-sirena-cash .uk-card-default {
    background-color: #0066b3
}

.cont-sirena-cash .error {
    color: #ffe64c !important;
    font-size: .75em;
    font-weight: 700;
    margin: .5em 0
}

.cont-sirena-cash .item-list-footer .item-list-btns {
    border-color: rgba(255, 255, 255, 0.4)
}

.cont-sirena-cash .uk-alert {
    background-color: white
}

.cont-sirena-cash .uk-alert i {
    color: #e1251b
}

.cont-sirena-cash .uk-progress::-webkit-progress-value {
    background-color: #ffe64c
}

.cont-sirena-cash .uk-progress::-moz-progress-bar {
    background-color: #ffe64c
}

.cont-sirena-cash .uk-progress::-ms-fill {
    background-color: #ffe64c
}

.cont-sirena-cash .nubes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.cont-sirena-cash .nube {
    position: absolute;
    width: 16.125em;
    height: 8em;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat
}

.cont-sirena-cash .nube:nth-of-type(1) {
    top: 1%;
    left: 55%
}

.cont-sirena-cash .nube:nth-of-type(2) {
    top: 6%;
    left: -10%
}

.cont-sirena-cash .nube:nth-of-type(3) {
    top: 13%;
    left: 40%
}

.cont-sirena-cash .nube:nth-of-type(4) {
    top: 11%;
    left: 90%
}

.cont-sirena-cash .nube:nth-of-type(5) {
    top: 23%;
    left: 86%
}

.cont-sirena-cash .nube:nth-of-type(6) {
    top: 28%;
    left: -5%
}

.cont-sirena-cash .nube:nth-of-type(7) {
    top: 44%;
    left: 5%
}

.cont-sirena-cash .nube:nth-of-type(8) {
    top: 51%;
    left: 53%
}

.cont-sirena-cash .nube:nth-of-type(9) {
    top: 60%;
    left: 68%
}

.cont-sirena-cash .nube:nth-of-type(10) {
    top: 64%;
    left: 18%
}

.cont-sirena-cash .nube:nth-of-type(11) {
    top: 70%;
    left: -2%
}

.cont-sirena-cash .nube:nth-of-type(12) {
    top: 78%;
    left: 90%
}

.cont-sirena-cash .nube:nth-of-type(13) {
    top: 88%;
    left: 5%
}

.cont-sirena-cash .size1 {
    font-size: .75em
}

.cont-sirena-cash .size2 {
    font-size: .5em
}

.cont-sirena-cash .opacity1 {
    opacity: .7
}

.cont-sirena-cash .opacity2 {
    opacity: .3
}

.modal-sirena-cash .uk-modal-header, .modal-sirena-cash .uk-modal-footer {
    background-color: #0066b3;
    border-color: rgba(255, 255, 255, 0.4)
}

.modal-sirena-cash .uk-modal-body {
    background-color: #0066b3
}

.modal-sirena-cash .uk-modal-body .uk-text-secondary {
    color: #ffe64c !important
}

.modal-sirena-cash .uk-modal-body .uk-button-small:hover i {
    color: #00b2e3
}

.modal-sirena-cash .uk-modal-body .uk-button-small i {
    color: #ffe64c
}

.cont-sirena-cash .uk-close, .modal-sirena-cash .uk-close {
    color: white
}

.cont-sirena-cash .uk-close:hover, .modal-sirena-cash .uk-close:hover {
    color: #ffe64c
}

.cont-sirena-cash .uk-button, .modal-sirena-cash .uk-button {
    font-weight: 800
}

.cont-sirena-cash .uk-button:not(.uk-button-default):not(.uk-button-primary):not(.uk-button-secondary):not(.uk-button-text):not(.uk-button-link), .modal-sirena-cash .uk-button:not(.uk-button-default):not(.uk-button-primary):not(.uk-button-secondary):not(.uk-button-text):not(.uk-button-link) {
    color: white;
    background-color: rgba(255, 255, 255, 0.2)
}

.cont-sirena-cash .uk-button:not(.uk-button-default):not(.uk-button-primary):not(.uk-button-secondary):not(.uk-button-text):not(.uk-button-link):hover, .modal-sirena-cash .uk-button:not(.uk-button-default):not(.uk-button-primary):not(.uk-button-secondary):not(.uk-button-text):not(.uk-button-link):hover {
    color: #212022;
    background-color: rgba(255, 255, 255, 0.9)
}

.cont-sirena-cash .uk-button-default, .modal-sirena-cash .uk-button-default {
    color: #0066b3;
    background-color: #ffe64c
}

.cont-sirena-cash .uk-button-default:hover, .modal-sirena-cash .uk-button-default:hover {
    color: white;
    background-color: #00b2e3
}

.cont-sirena-cash .uk-button-primary, .modal-sirena-cash .uk-button-primary {
    color: white;
    background-color: #0066b3
}

.cont-sirena-cash .uk-button-primary:hover, .modal-sirena-cash .uk-button-primary:hover {
    color: #0066b3;
    background-color: #ffe64c
}

.cont-sirena-cash .uk-button-secondary, .modal-sirena-cash .uk-button-secondary {
    color: white;
    background-color: #00b2e3
}

.cont-sirena-cash .uk-button-secondary:hover, .modal-sirena-cash .uk-button-secondary:hover {
    color: #0066b3;
    background-color: #ffe64c
}

.cont-sirena-cash .uk-button-small, .modal-sirena-cash .uk-button-small {
    margin: .25em
}

.cont-sirena-cash .uk-button-default:disabled, .cont-sirena-cash .uk-button-primary:disabled, .cont-sirena-cash .uk-button-secondary:disabled, .cont-sirena-cash .uk-button-danger:disabled, .modal-sirena-cash .uk-button-default:disabled, .modal-sirena-cash .uk-button-primary:disabled, .modal-sirena-cash .uk-button-secondary:disabled, .modal-sirena-cash .uk-button-danger:disabled {
    color: #0066b3;
    background-color: #ffe64c;
    opacity: .75
}

.cont-sirena-cash table tr, .modal-sirena-cash table tr {
    border-color: rgba(255, 255, 255, 0.4) !important
}

.cont-sirena-cash table th, .cont-sirena-cash table td, .modal-sirena-cash table th, .modal-sirena-cash table td {
    color: white
}

.cont-sirena-cash table th, .modal-sirena-cash table th {
    text-transform: uppercase
}

.cont-sirena-cash table td, .modal-sirena-cash table td {
    font-size: .9em
}

.required {
    font-weight: 600;
    color: #00b2e3
}

label {
    outline: none
}

.uk-form-controls.uk-grid, .uk-form-controls.uk-grid-small, .uk-form-controls.uk-grid-large {
    margin-left: 0
}

.uk-form-controls.uk-grid > *:first-child {
    padding-left: 0 !important
}

.uk-form-controls.uk-grid > *:last-child {
    padding-right: 0 !important
}

@media (min-width: 60rem) {
    .uk-form-horizontal .uk-form-label {
        width: 15em
    }

    .uk-form-horizontal .uk-form-controls {
        margin-left: 17em
    }
}

.uk-card .uk-progress {
    height: 8px;
    margin: 0
}

