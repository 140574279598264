@import "~react-image-gallery/styles/css/image-gallery.css";

* {
    margin: 0;
    padding: 0;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.mobile-container{
    width: 100vw;
    height: 100vh;
}

body {
    font-display: auto;
    color: #212022;
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
}

body > img {
    position: absolute;
    left: 0;
    bottom: 0;
}

.error {
    font-size: 10px;
    color: red
}

.link-gruporamos > img {
    width: 3em;
}


div > div.item-product-image {
    width: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
}

.btn-view {
    position: absolute;
    top: 2.5em;
    right: .5em;
    z-index: 100;
    width: 2em !important;
    height: 2em;
    line-height: 2em;
    text-align: center;
    font-size: .9em;
    color: #00b2e3 !important;
    background-color: white;
    border: none;
}

.btn-favorite {
    border: none !important;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}


.swal2-styled.swal2-confirm {
    background-color: #00b2e3 !important;

}

.address-allow {
    margin-top: 0.5em !important;
}

.uk-badge-danger {
    background-color: #d32c46 !important;;
    background-image: -webkit-linear-gradient(top, #ee465a, #c11a39) !important;;
    background-image: linear-gradient(to bottom, #ee465a, #c11a39) !important;
}

.empty-cart {

}

.uk-button:disabled {
    cursor: not-allowed;
}

.item-list .item-list-image {
    background-size: contain !important;
}

.uk-button-custom {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.item-product-image {
    background-size: contain !important;
}

.cart-list-footer > .uk-padding-small {
    padding: .5em;
}

.cart-list-footer > .uk-button {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.uk-form-label-full {
    width: 100% !important;
}

.uk-radio, .uk-checkbox {
    margin-top: 0.25em !important;
}

.item-product-image-full-content {
    padding: 0 !important;
    margin: 0 !important;
}

.item-product-image-full {
    background-size: 100% !important;
    padding-bottom: 100% !important;
}

.rccs, .rccs__card {
    max-width: 100%;
}

input[name=radio-hours] {
    margin-top: -5px !important;
}

.uk-search-modify {
    border: 1px solid rgba(33, 32, 34, 0.2) !important;
}

.current-category {
    border: 2px solid #00b2e3;
}

.view-all:before {
    content: '' !important;
}

.disabled {
    cursor: not-allowed !important;
}

.gpt_desktop_home_slider {
    min-width: 800px;
    min-height: 380px;
}

.desktop_home_standard {
    min-width: 400px;
    min-height: 180px;
}

.mobile_home_slider {
    min-width: 350px;
    min-height: 250px;
}

.desktop_banner_horizontal {
    min-width: 440px;
    min-height: 127px;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.footer-version
{
    text-align: right;
    padding-right: 10px;
}


